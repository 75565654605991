
import 'react-native-gesture-handler';

// Import React and Component
import React, {useState,useContext,}  from 'react';
import { AuthContext } from "../contexts/AuthContext";
// Import Navigators from React Navigation

import {createStackNavigator} from '@react-navigation/stack';

// Import Screens
import SplashScreen from '../SplashScreen';
import LoginScreen from '../LoginScreen';
import HomeScreen from '../DrawerScreens/HomeScreen';
import LcmsScreen from '../DrawerScreens/LcmsScreen';
import RegisterScreen from '../RegisterScreen';
import  Home from '../Home';




import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import TabBar from 'react-native-custom-navigation-tabs';
import Icon from 'react-native-vector-icons/FontAwesome'
// import NavigationDrawerHeader from './Screen/Components/NavigationDrawerHeader';
// import CustomSidebarMenu from './Screen/Components/CustomSidebarMenu';
// import TotalCaseScreen from './Screen/DrawerScreens/TotalCaseScreen';


const Tab = createMaterialBottomTabNavigator();


const Stack = createStackNavigator();
 
const Auth = () => {
  // Stack Navigator for Login and Sign up Screen
  return (
    <Stack.Navigator initialRouteName="LoginScreen">
      <Stack.Screen
        name="LoginScreen"
        component={LoginScreen}
        options={{headerShown: false}}  // Hide Header
        // options={{
        //   title: 'LCMSBD.COM', //Set Header Title
        //   headerTitleAlign: 'center',
        //   headerStyle: {
        //     backgroundColor: '#FFF', //Set Header color
        //   },
        //   headerTintColor: '#f00', //Set Header text color
        //   headerTitleStyle: {
        //     fontWeight: 'bold', //Set Header text style
        //   },
        // }}
      />
      <Stack.Screen
        name="RegisterScreen"
        component={RegisterScreen}
        options={{
          title: 'Register', //Set Header Title
          headerStyle: {
            backgroundColor: '#307ecc', //Set Header color
          },
          headerTintColor: '#fff', //Set Header text color
          headerTitleStyle: {
            fontWeight: 'bold', //Set Header text style
          },
        }}
      />
    </Stack.Navigator>
  );
};



const Navigator = () => {
 
  
  const { authUser } = useContext(AuthContext);

  //user login status
  const isUserLoggedIn = authUser.chamberId ? true : false;
  return (

 
   <Stack.Navigator initialRouteName="SplashScreen">
       
        <Stack.Screen
          name="SplashScreen"
          component={SplashScreen}
          // Hiding header for Splash Screen
          options={{headerShown: false}}
        /> 
      
        <Stack.Screen
          name="Auth"
          component={Auth}
          options={{headerShown: false}}
        />
        <Stack.Screen
          name="Home"
          component={Home}
          // Hiding header for Navigation Drawer
          options={{headerShown: false}}
        />   
          
      </Stack.Navigator>
  )
 

  
};
 
export default Navigator;
