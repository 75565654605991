// Import React and Component
import React from 'react';
import {View, Image, TouchableOpacity} from 'react-native';
import { NavigationContainer, DrawerActions } from '@react-navigation/native';
 
const NavigationDrawerHeader = (props) => {
  const toggleDrawer = () => {
    props.navigationProps.toggleDrawer();
   // props.navigationProps.dispatch(DrawerActions.toggleDrawer())
  };
 
  return (
    <View style={{flexDirection: 'row'}}>
      <TouchableOpacity onPress={toggleDrawer}>
        <Image
           source={require('../../assets/drawerWhite.png')}
          style={{width: 35, height: 30, marginRight: 45}}
        />
      </TouchableOpacity>
    </View>
  );
};
export default NavigationDrawerHeader;
