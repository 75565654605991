import React, { Component,useState  } from "react";
import { StyleSheet, View, Text,TouchableOpacity } from "react-native";
import MaterialFixedLabelTextbox1 from "../Components/MaterialFixedLabelTextbox1";
import MaterialRadio from "../Components/MaterialRadio";
import MaterialButtonDanger1 from "../Components/MaterialButtonDanger1";
import MaterialButtonPink from "../Components/MaterialButtonPink";
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";
import Icon from "react-native-vector-icons/Entypo";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import moment from "moment";

import { Formik } from "formik";
import * as yup from "yup";

function UpdateDiary(props) {
  const [current, setCurrent] = useState("1");

    //date picker strat
    const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
    const [isVisible, setVisibility] = useState(false);
    const [select_date, setselectdate] = useState(null);
    const [select_next_date, setselectdNxteDate] = useState(null);
    
        const showDatePicker = () => {
          setDatePickerVisibility(true);
        };

        const hideDatePicker = () => {
          setDatePickerVisibility(false);
        };

        const showDatePicker2 = () => {
          setVisibility(true);
        };

        const hideDatePicker2 = () => {
          setVisibility(false);
        };


        const handleConfirm = (val) => {
        // console.warn("A date has been picked: ", date);
      //console.log(val);
          setselectdate(  val );
          //console.log(select_date);
          hideDatePicker();
        };

        const handlenextDate = (val) => {
          // console.warn("A date has been picked: ", date);
        //console.log(val);
        setselectdNxteDate(  val );
            //console.log(select_date);
            hideDatePicker2();
          };
    // date picker end

  return (
    <View style={styles.container}>
      <View style={styles.rect}>
        <View style={styles.rect2}>
          <Text style={styles.updateDiary}>Update Diary {props.item.court_division_name}</Text>
          <TouchableOpacity onPress={props.closeModal} ><Icon name="circle-with-cross" style={styles.icon}></Icon></TouchableOpacity>
        </View>
        <Text style={styles.courtNameNo}>Court Name/No.</Text>
        <MaterialFixedLabelTextbox1
          style={styles.materialFixedLabelTextbox1}
         // value={''}
          onTouchStart={null}
           onChangeText={null}
           onBlur={null}
           placeholder={"Court Name/No."}
        />
        <Text style={styles.result}>Result</Text>
        <MaterialFixedLabelTextbox1
          style={styles.materialFixedLabelTextbox2}
          onTouchStart={null}
           onChangeText={null}
           onBlur={null}
           placeholder={"Case Result"}
        />
        <View style={styles.materialFixedLabelTextbox3Stack}>
          <MaterialFixedLabelTextbox1
            style={styles.materialFixedLabelTextbox3}
            value={select_date !==null ? moment(select_date).format("DD-MM-YYYY") : ""}
            showSoftInputOnFocus={false}
            onChangeText={null}
            onBlur={null}
            onTouchStart={showDatePicker}
            placeholder={"Order Date"}
          />
            <DateTimePickerModal
                        isVisible={isDatePickerVisible}
                        mode="date"
                       // date={ values?.application_deadline? moment(values?.application_deadline).format("DD-MM-YYYY") : ""}
                        onConfirm={handleConfirm}
                        onCancel={hideDatePicker}
                        minimumDate={new Date()}
                       
                       // minimumDate={("YYYY, MM, DD")}
                       // maximumDate={moment('08-06-2022').add(30, 'days').format("DD-MM-YYYY")}
                      />
                    
          <Text style={styles.orderDate}>Order date</Text>
        </View>
        <Text style={styles.updateDescription}>Update Description</Text>
        <MaterialFixedLabelTextbox1
          style={styles.materialFixedLabelTextbox4}
          onTouchStart={null}
          onChangeText={null}
          onBlur={null}
          placeholder={"Update Description"}
        />
        <View style={styles.materialRadioStackStackRow}>
          <View style={styles.materialRadioStackStack}>
            
            <View style={styles.nextDateStack}>
              {/* <Text style={styles.nextDate}>Next date</Text>
              <MaterialRadio style={styles.materialRadio1}></MaterialRadio>
              <MaterialRadio style={styles.materialRadio2}></MaterialRadio> */}
              <RadioButtonGroup
        containerStyle={{ marginBottom: 10 }}
        selected={current}
        onSelected={(value) => setCurrent(value)}
        radioBackground="green"
      >
        <RadioButtonItem value="1" label="Next date" />
        <RadioButtonItem value="2" label="No Next date" />
        <RadioButtonItem
          value="3"
          label={
            <Text style={{ color: "red" }}>Disposed</Text>
          }
        /> 
      </RadioButtonGroup>
            </View>
            {/* <Text style={styles.disposed}>Disposed</Text> */}
          </View>
          {/* <Text style={styles.noNextDate}>No Next date</Text> */}
        </View>
       {current == 1?( 
        <View style={styles.materialFixedLabelTextbox5Stack }>
         <Text style={styles.nextDate2}>Next Date</Text>
        <MaterialFixedLabelTextbox1
          style={styles.materialFixedLabelTextbox6}
           showSoftInputOnFocus={false}
            onChangeText={null}
          value={select_next_date !==null ? moment(select_next_date).format("DD-MM-YYYY") : ""}
          onTouchStart={showDatePicker2}
          
          onBlur={null}
          placeholder={"Next Date"}
        />
 <DateTimePickerModal
                        isVisible={isVisible}
                        mode="date"
                       // date={ values?.application_deadline? moment(values?.application_deadline).format("DD-MM-YYYY") : ""}
                        onConfirm={handlenextDate}
                        onCancel={hideDatePicker}
                        minimumDate={new Date()}
                       
                       // minimumDate={("YYYY, MM, DD")}
                       // maximumDate={moment('08-06-2022').add(30, 'days').format("DD-MM-YYYY")}
                      />

          <MaterialFixedLabelTextbox1
            style={styles.materialFixedLabelTextbox5}
           
            onTouchStart={null}
            onChangeText={null}
            onBlur={null}
            placeholder={"Next Dater For"}
          />
          <Text style={styles.nextDaterFor}>Next Dater For</Text>         
         
        </View>):null}
        
        <View style={styles.materialButtonDanger2Row}>
          <MaterialButtonDanger1
            style={styles.materialButtonDanger2}

          ></MaterialButtonDanger1>
          <MaterialButtonPink
            style={styles.materialButtonPink}
            closeModal={props.closeModal}
          ></MaterialButtonPink>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent:"center",
    justifyContent:"center",
  },
  rect: {
    width: '92%',
    height: 'auto',
    backgroundColor: "rgba(251,249,249,1)",
    marginTop: -25,
    marginLeft: 10,
    borderRadius: 13,
  },
  rect2: {
    width: '100%',
    height: 42,
    backgroundColor: "rgba(92,184,92,1)",
    borderTopLeftRadius: 13,
    borderTopRightRadius: 13,
  },
  updateDiary: {
   
    color: "#121212",
    fontSize: 20,
    marginTop: 9,
    marginLeft: 20
  },
  courtNameNo: {
    
    color: "#121212",
    width: 150,
    height: 17,
    marginLeft: 26
  },
  materialFixedLabelTextbox1: {
    height: 38,
    width: '90%',
    borderWidth: 1,
    borderColor: "#000000",
    borderRadius: 5,
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 45,
    shadowOpacity: 0.25,
    shadowRadius: 15,
    marginTop: 5,
    marginLeft: 15
  },
  result: {
    
    color: "#121212",
    width: 86,
    height: 17,
    marginTop: 3,
    marginLeft: 25
  },
  materialFixedLabelTextbox2: {
    height: 38,
    width: '90%',
    borderWidth: 1,
    borderColor: "#000000",
    borderRadius: 5,
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 45,
    shadowOpacity: 0.25,
    shadowRadius: 15,
    marginLeft: 15
  },
  materialFixedLabelTextbox3: {
    height: 38,
    width: '90%',
    position: "absolute",
    left: 0,
    top: 16,
    borderWidth: 1,
    borderColor: "#000000",
    borderRadius: 5,
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 45,
    shadowOpacity: 0.25,
    shadowRadius: 15
  },
  orderDate: {
    top: 0,
    
    left: 11,
    position: "absolute",
   
    color: "#121212",
    width: 100,
    height: 17
   
  },
  materialFixedLabelTextbox3Stack: {
    width: '100%',
    height: 54,
    marginTop: 7,
    marginLeft: 15
  },
  updateDescription: {
  
    color: "#121212",
    width: 121,
    height: 17,
    marginTop: 5,
    marginLeft: 26
  },
  materialFixedLabelTextbox4: {
    height: 38,
    width: '90%',
    borderWidth: 1,
    borderColor: "#000000",
    borderRadius: 5,
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 45,
    shadowOpacity: 0.25,
    shadowRadius: 15,
    marginTop: 1,
    marginLeft: 15
  },
  materialRadio: {
    height: 40,
    width: 29,
    position: "absolute",
    left: 2,
    top: 0
  },
  materialRadio2: {
    height: 40,
    width: 29,
    position: "absolute",
    left: 82,
    top: 32
  },
  nextDate2: {
    top: -25,
    left: 19,
    position: "absolute",
   
    color: "#121212",
    width: 80,
    height: 17
  },
  materialRadioStack: {
    top: 0,
    left: 0,
    width: 99,
    height: 79,
    position: "absolute"
  },
  nextDate: {
    top: 11,
    left: 0,
    position: "absolute",
    
    color: "#121212",
    width: 87,
    height: 16
  },
  materialRadio1: {
    height: 40,
    width: 29,
    position: "absolute",
    left: 82,
    top: 0
  },
  nextDateStack: {
    top: 3,
    left: 32,
    width: 111,
    height: 40,
    position: "absolute"
  },
  disposed: {
    top: 45,
    left: 144,
    position: "absolute",
    
    color: "#121212",
    width: 59,
    height: 16
  },
  materialRadioStackStack: {
    width: 143,
    height: 79
  },
  noNextDate: {
    
    color: "#121212",
    width: 85,
    height: 16,
    marginLeft: 3,
    marginTop: 16
  },
  materialRadioStackStackRow: {
    height: 115,
    flexDirection: "row",
    marginTop: 5,
    marginLeft: 7,
    marginRight: 15
  },
  materialFixedLabelTextbox6: {
    height: 38,
    width: '90%',
    borderWidth: 1,
    borderColor: "#000000",
    borderRadius: 5,
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 45,
    top:-7,
    shadowOpacity: 0.25,
    shadowRadius: 15,
    marginLeft: 0
  },
  materialFixedLabelTextbox5: {
    height: 38,
    width: '90%',
    position: "absolute",
    left: 0,
    top: 52,
    borderWidth: 1,
    borderColor: "#000000",
    borderRadius: 5,
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 45,
    shadowOpacity: 0.25,
    shadowRadius: 15
  },
  nextDaterFor: {
    top: 32,
    left: 11,
    position: "absolute",
   
    color: "#121212",
    width: 117,
    height: 17
  },
  materialFixedLabelTextbox5Stack: {
    width: '100%',
    height: 100,
    marginTop: 0,
    marginLeft: 15
  },
  materialButtonDanger2: {
    height: 36,
    width: 78
  },
  materialButtonPink: {
    height: 36,
    width: 48,
    marginLeft: 8
  },
  materialButtonDanger2Row: {
    height: 36,
    flexDirection: "row",
    marginTop: 7,
    width:'100%',
   // marginLeft: 35,
    alignContent:"center",
    justifyContent:"center",
   
    marginBottom:10
  },
  icon: {
    color: "rgba(244,74,74,1)",
    fontSize: 25,
    opacity: 0.79,
    height: 27,
    width: 25,
    top:-25,
left:"88%",
    alignContent:"flex-end",
    justifyContent:"flex-end",
  },
});

export default UpdateDiary;
