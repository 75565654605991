import React, { useContext } from "react";
import { Text } from "react-native";
import { ThemeContext } from "../contexts/ThemeContext";
import AppText from "./appText";
import {
  responsiveHeight,
  responsiveWidth,
  responsiveFontSize
} from "react-native-responsive-dimensions";

export default function InputLevel({ children }) {
  return (
    <Text
      style={{
       // marginBottom: 5,
        fontWeight: 'bold',
        justifyContent: 'flex-end',
        alignItems:'flex-end',
        textAlign:'right',
        width: responsiveWidth(31),
        marginTop:5
      }}
    >
      <AppText size={13} family='bold' color='black' >{children}</AppText>
    </Text>
  );
}

