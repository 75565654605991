import { useFormikContext, useField } from "formik";
import React, { useState } from "react";
import DropDownPicker from "react-native-dropdown-picker";
import { ScrollView, } from 'react-native';
import {
  responsiveHeight,
  responsiveWidth,
  responsiveFontSize
} from "react-native-responsive-dimensions";

const DropDownFormik = ({ ...props }) => {
  const [openProvider, setOpenProvider] = useState(false);
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
   
    <DropDownPicker
      {...field}
      {...props}
      style={{
        ...props.style,
        position: 'relative', top: 0 ,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight:responsiveHeight(4.35),
        width:responsiveWidth(62),
        //minHeight: 22,
        borderRadius: 2,
        borderWidth: 0,      
        paddingHorizontal: 10,
        paddingVertical: 0,       
        backgroundColor: field.value === ""? "#fff":"#fced1a"
      }}
      
      placeholderStyle={{   color: "#999",}}
      textStyle={{
        fontSize: 13
      }}
      
      
      value={field.value}
     // open={openProvider}
     // setOpen={setOpenProvider}
      setValue={(val) => {
        setFieldValue(field.name, val());
      }}
    />
  );
};

export default DropDownFormik;