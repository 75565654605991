import React, { useContext } from "react";
import { Text, TouchableOpacity } from "react-native";
import { DefaultThemeColors, DarkThemeColors } from "../utils/constants/Colors";
import { ThemeContext } from "../contexts/ThemeContext";

export default function AppBtn(props) {
  const { isThemeDark } = useContext(ThemeContext);
  const Colors = isThemeDark ? DarkThemeColors : DefaultThemeColors;
   const p = props.paddingRight ? props.paddingRight : 12;
   const h = props.paddingLeft ? props.paddingLeft : 12;
   const t = props.paddingTop ? props.paddingTop : 5;
   const b = props.paddingBottom ? props.paddingBottom : 6;
  const w = props.width ? props.width : "100%";
  const BGcolor = props.BGcolor ? props.BGcolor : Colors.btnPrimaryBg;
  const Tcolor = props.Tcolor ? props.Tcolor : Colors.btnPrimaryText;
  return (
    <TouchableOpacity
      style={{
        paddingLeft: h,
        paddingRight: p,
        paddingTop:t,
        paddingBottom:b,
       
        borderRadius:6,
        backgroundColor: BGcolor,
        width: w,
        //margin:10,
      }}
      onPress={props.onPress}
     {...props}
    >
      <Text
        style={{
          color: Tcolor,
          fontSize: 18,
          textTransform: "capitalize",
          textAlign: "center",
        }}
      >
        {props.title}
      </Text>
    </TouchableOpacity>
  );
}
