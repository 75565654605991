import React, { useContext } from 'react'
import { StyleSheet, TextInput } from "react-native";
import { ThemeContext } from '../contexts/ThemeContext';
import {
  responsiveHeight,
  responsiveWidth,
  responsiveFontSize
} from "react-native-responsive-dimensions";

export default function BasicFormInput(props) {
 const {isThemeDark} = useContext(ThemeContext);

 const color = isThemeDark && props.isEditing ? '#fff' : '#000'
  return (
    <TextInput
      style={{
        ...styles.input,
        ...(!props.isEditing && styles.inputNotEditable),
        color,
        backgroundColor: props.value === ""? "#fff":"#fced1a"
      }}
      editable={props.isEditing ? true : false}
      {...props}
    />
  );
}

const styles = StyleSheet.create({
  input: {
    borderColor: "#ccc",
    borderWidth: 1,
    padding: 1,
    borderRadius: 2,
    fontSize: 13,
    marginLeft:0,
    paddingHorizontal:10,
   height:responsiveHeight(4.35),
    width:responsiveWidth(62),
   
    
    
  },
  inputNotEditable: {
   // backgroundColor: "#ccc",
    borderWidth: 0,
  },
});
