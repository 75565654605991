// Import React and Component
import React, {useState,useContext, createRef, useEffect, useRef} from 'react';
import {View, Text, Alert, StyleSheet, Image, TouchableOpacity} from 'react-native';
import { AuthContext } from "../contexts/AuthContext";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from '@react-navigation/drawer';


import AsyncStorage from '@react-native-async-storage/async-storage';
import ExpandableViewSeparate from './ExpandableViewSeparate';
import LoginScreen from '../LoginScreen';
import {useNavigation} from '@react-navigation/native';
import {StackActions} from '@react-navigation/native';
import axios from 'axios';
const Drawer = createDrawerNavigator();
  
    
function getExpandableView(props){
    return (
        <ExpandableViewSeparate navObj={props.navigation}/>
      );
};


 
const CustomSidebarMenu = (props) => {

  const [chamberName, setChamberName] = useState('');
  const [userName, setUserName] = useState('');
  const [chamberImg, setChamberImg] = useState('');
  const { authUser, setUnauthStatus, updateAuthUserProfile } = useContext(
    AuthContext
  );

  const navigation = useNavigation();
  useEffect(() => {

AsyncStorage.getItem('user_id').then((value) =>
{value === null ?
  null:setUserName(value)}
);
    //console.log("=====chamberName: "+chamberName);
    //console.log("=====chamberImg: "+chamberImg);
   
    getChamberName().then(usrType => setChamberName(usrType));
    getChamberImg().then(usrType => setChamberImg(usrType));

  }, []);

  const handleSubmitPress = () => {

  // alert('robeen'+userName);

    let dataToSend = {is_mobile:1, username: userName, device_id:3};
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    formBody = formBody.join('&');

      try {

        let url = "https://reactnative.bdlawservice.com/public/api/appsAndWebLogOut";
 
         axios.post(url, formBody).then(response => {     }).catch((error) => {
          //Hide Loader
          
          console.error(error);
        });
        
       } 
       catch (err) 
       {
         console.error(err);
       }

  };


 

  return (
    <View style={stylesSidebar.sideMenuContainer}>
      <View style={stylesSidebar.profileHeader}>
        <View style={stylesSidebar.profileHeaderPicCircle}>
		   <TouchableOpacity>
				<Image
				  source={{
					uri:
					  ((chamberImg=="default_logo")?"https://www.lcmsbd.com/uploads/default_chamber_logo.png":"https://www.lcmsbd.com/uploads/Chamber_logo_img/"+chamberImg),
				  }}
				  style={{width: 50, height: 45}}
				/>
		  </TouchableOpacity>
        </View>
        <Text style={stylesSidebar.profileHeaderText}>
          {chamberName}
        </Text>
      </View>
	  
      <View style={stylesSidebar.profileHeaderLine} />
 
      <DrawerContentScrollView {...props}>
      {getExpandableView(props)}
        {/* <DrawerItemList {...props} />  */}
        <DrawerItem
          label={({color}) => 
            <Text style={{color: '#f999aa',fontSize: 18,  fontWeight: 'bold' }}>
              Logout
            </Text>
          }
          onPress={() => {
            props.navigation.toggleDrawer();
            Alert.alert(
              'Logout',
              'Are you sure? You want to logout?',
              [
                {
                  text: 'Cancel',
                  onPress: () => {
                    return null;
                  },
                },
                {
                  text: 'Confirm',
                  onPress: () => {
                    //AsyncStorage.clear();
                    handleSubmitPress;
                    setUnauthStatus();
                    AsyncStorage.removeItem('chamber_id');
                    AsyncStorage.removeItem('user_id');
                   // AsyncStorage.removeItem('user_name');
                    AsyncStorage.removeItem('chamber_name');
                    AsyncStorage.removeItem('chamber_img');
                   props.navigation.replace('Auth');
                  // props.navigation.navigate("LoginScreen");
                 // navigation.popToTop();
                 // navigation.dispatch(StackActions.popToTop());
                  },
                },
              ],
              {cancelable: false},
            );
          }}
        />
      </DrawerContentScrollView>
    </View>
  );
};
 
export default CustomSidebarMenu;

async function getChamberName() {
  try {
    const jsonValue = await AsyncStorage.getItem("chamber_name")
    if(jsonValue !== null){
      //console.log("user_type: "+JSON.parse(jsonValue));
      return JSON.parse(jsonValue); 
    }
  } catch(err) {
    console.error(err);
  }
};


async function getChamberImg() {
  try {
    const jsonValue = await AsyncStorage.getItem("chamber_img")
    if(jsonValue !== null){
      //console.log("user_type: "+JSON.parse(jsonValue));
      return JSON.parse(jsonValue); 
    }
  } catch(err) {
    console.error(err);
  }
};

 
const stylesSidebar = StyleSheet.create({
  sideMenuContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: '#337ab7',
    paddingTop: 30,
    color: 'white',
  },
  profileHeader: {
    flexDirection: 'row',
    backgroundColor: '#337ab7',
    padding: 5,
    textAlign: 'center',
  },
  profileHeaderPicCircle: {
    width: 60,
    height: 60,
    borderRadius: 60 / 2,
    color: 'white',
   // backgroundColor: '#ffffff',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileHeaderText: {
    color: 'white',
    alignSelf: 'center',
    paddingHorizontal: 5,
    fontWeight: 'bold',
  },
  profileHeaderLine: {
    height: 1,
    marginHorizontal: 10,
    backgroundColor: '#e2e2e2',
    marginTop: 5,
  },
});
