import React from "react";
import { View } from "react-native";
import {
  responsiveHeight,
  responsiveWidth,
  responsiveFontSize
} from "react-native-responsive-dimensions";


export default function FormGroup({ children }) {
  return (
    <View
      style={{
        paddingHorizontal: 10,
        zIndex:-1,
       // marginLeft: 10,
       marginBottom: responsiveHeight(1),
flexDirection:'row',
      }}
    >
      {children}
    </View>
  );
}
