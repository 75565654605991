export const DefaultThemeColors = {
         tabIconSelected: "#1275d8",
         tabIconDefault: "#333",
         tabBarBorderDefault: "#d20e0e",
         tabBg: "#fff",
         statusBarBg: "#065cb1",
         headerBg: "#1275d8",
         headerTint: "#fff",
         primaryBg: "#fff",
         secondaryBg: "#eee",
         infoBg: "#1771c4",
         primaryText: "#333111",
         secondayText: "#666",
         infoText: "#1771c4",
         primaryBorder: "#d20e0e",
         secondaryBorder: "#eee",
         btnPrimaryBg: "#1771c4",
         btnPrimaryText: "#fff",
       };

export const DarkThemeColors = {
         tabIconSelected: "#1275d8",
         tabIconDefault: "#ffffff",
         tabBarBorderDefault: "#d20e0e",
         tabBg: "#181818",
         statusBarBg: "#000000",
         headerBg: "#181818",
         headerTint: "#ffffff",
         primaryBg: "#000",
         secondaryBg: "#222",
         infoBg: "#666",
         primaryText: "#fff",
         secondayText: "#fff",
         infoText: "#fff",
         primaryBorder: "#d20e0e",
         btnPrimaryBg: "#333",
         btnPrimaryText: "#fff",
       };
