// Import React and Component
import React, {useState, createRef, useEffect, useRef, useCallback} from 'react';
import {
  TouchableWithoutFeedback,
  StyleSheet,
  TextInput,
  View,
  Text,
  ScrollView,
  Image,
  Keyboard,
 // TouchableOpacity,
  KeyboardAvoidingView,
  Dimensions,
  Linking,
  Button,
  SafeAreaView,
  ActivityIndicator,
  Animated,
  FlatList,
  LogBox,
  Pressable 
} from 'react-native';

import {
  responsiveHeight,
  responsiveWidth,
  responsiveFontSize
} from "react-native-responsive-dimensions";

import { useFormikContext, useField, Formik } from "formik";
import * as yup from "yup";
import InputLevel from "../shared/inputLevel";
import BasicFormInput from "../shared/basicFormInput";
import BasicFormInputref from "../shared/basicFormInputref";
import ErrorText from "../shared/errorText";
import ContainerFluid from "../shared/containerFluid";
import FormGroup from "../shared/formGroup";


import { BASE_URL } from '../Components/BaseUrl';
import { apiPathLocal } from "../utils/constants/Consts";
import Loader from '../Components/Loader';
import DiaryBox from "../shared/updateDiary";
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { TouchableOpacity } from "react-native-gesture-handler";
import SelectDropdown from 'react-native-select-dropdown';
import { useFocusEffect,CommonActions ,StackActions,useNavigation  } from "@react-navigation/native";
import Modal from "react-native-modal";
import AppBtn from "../shared/appBtn";
import DropDownFormik from "../shared/DropDownFormik";
import DropDownFormikBook from "../shared/DropDownFormikBook";
import SearchInput, { createFilter } from 'react-native-search-filter';
import useErrorHandler from "../utils/custom-hooks/ErrorHandler";
import ModalFilterPicker from 'react-native-modal-filter-picker';
import HTML from "react-native-render-html";
import {decode} from 'html-entities';
//import {Icon} from '../shared/icon'
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import HighlightText from '@sanar/react-native-highlight-text';
//import HTMLView from 'react-native-htmlview';

const HomeScreen = ({ navigation }) => {
  let listViewRef;
 // const { setFieldValue } = useFormikContext();
 // const [field] = useField(props);
  const [totalrow, setTotalJudgement] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [moreloading, setMoreLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(true); 
  const [nullbody, setNullbody] = useState(true);
  const [caseNumber, setCaseNumber] = useState('');
  const [case_year, setCase_year] = useState('');
  const [statuses, setStatuses] = useState(0);
  const [chamber_id, setChamber_id] = useState('');
  const [endLoader, setEndLoader] = useState(true);
  const [errortext, setErrortext] = useState('');
  const [Sotrtotal_case, setTotal_case] = useState([]);
  const [topsCrollShow, setTopsCrollShow] = useState(false);
  const [CaseNmaeList, setTotalCaseName] = useState([]);
 // const [isModalVisible, setModalVisible] = useState(false);
  const [focusLength, setFocusLength] = useState(true);
  const [statusCname, setStatusCname] = useState(0);
  const { error, showError } = useErrorHandler(null);
 
  const [total_case, setFilteredDataSource] = useState([]);
  const [searchEntity, setSearchEntity] = useState([]); 
  const windowHeight = Dimensions.get('window').height;
  //const [refreshing, setRefreshing] = React.useState(false);

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 2000);
  }, []);


  var searchEntites = [];


  const [courtDivision, setCourtDivision] = useState([]);
  const [JusticesName, setJusticesName] = useState([]);
  const [CaseTypeName, setCaseTypeName] = useState([]);
  const [VolumeLists, setVolumeLists] = useState([]);
  const [PagesLists, setPagesLists] = useState([]);
  
  const [isSubmitting, setIsSubmitting] = useState(false);
 // State to store count value
 const [count, setCount] = useState(0);
 const KEYS_TO_FILTERS = ["full_judgment","divisio_id","division_name","case_type_id","caseno","jud_no","jud_year","lawyer","section","act","article","jud_rule","jud_order"];
 //const formikElement = useRef(); 
 const formikElement = createRef(null); 
 const CaseStatus = ["All", "Pending", "Disposed"];
 const ParaItems = [
  //name key is must.It is to show the text in front
  { value: 1, label: 'Without Important Para' },
  { value: 2, label: 'With More Important Para' },
 
];

// const RefBookList = [
//   //name key is must.It is to show the text in front
//   { value: 'BLR', label: 'BLR' },
//   { value: 'ADC', label: 'ADC' },
//   { value: 'ALR', label: 'ALR' },
//   { value: 'BLC', label: 'BLC' },
//   { value: 'BLD', label: 'BLD' },
//   { value: 'BLT', label: 'BLT' },
//   { value: 'CLR', label: 'CLR' },
//   { value: 'DLR', label: 'DLR' },
//   { value: 'MLR', label: 'MLR' },
//   { value: 'SCOB', label: 'SCOB' },
 
// ];

const RefBookList = [
  //name key is must.It is to show the text in front
  { value: 'B', label: 'BLR' },
  { value: 1, label: 'ADC' },
  { value: 8, label: 'ALR' },
  { value: 2, label: 'BLC' },
  { value: 3, label: 'BLD' },
  { value: 4, label: 'BLT' },
  { value: 9, label: 'CLR' },
  { value: 5, label: 'DLR' },
  { value: 6, label: 'MLR' },
  { value: 7, label: 'SCOB' },
 
];
const [DV_no, setDVNo] = useState([]);
const DVList = [
  //name key is must.It is to show the text in front
  { value: 2, label: 'AD' },
  { value: 1, label: 'HD' },
 
];

const [Volume_no, setVolumeNo] = useState([]);
const Volume_no_list = [];

for (let i = 2023; i >= 1970; i--) {
  Volume_no_list.push({ value: i.toString(), label: i.toString()});
}

 const onCancel = () => {  setIsVisiblePage(false); setIsVisibleDV(false); setIsVisibleVolume(false);  setIsVisibleBook(false);  setIsVisibleCourtDivisionName(false); setIsVisibleJusticesName(false); setIsVisibleType(false); setIsVisiblePara(false); };
 const [visibleDivisionName, setIsVisibleCourtDivisionName] = useState(false);
 const onShowCourtDivisionName = () => {   setIsVisibleCourtDivisionName(true); Keyboard.dismiss();};
 const [visibleJusticesName, setIsVisibleJusticesName] = useState(false);
 const onShowJusticesName = () => {   setIsVisibleJusticesName(true); };
 const [visibleType, setIsVisibleType] = useState(false);
 const onShowType = () => {   setIsVisibleType(true); };
 const [visiblePara, setIsVisiblePara] = useState(false);
 const onShowPara = () => {   setIsVisiblePara(true); };

 const [visibleVolume, setIsVisibleVolume] = useState(false);
 const onShowVolume = () => {   setIsVisibleVolume(true); };
 const [visibleBook, setIsVisibleBook] = useState(false);
 const onShowBook = () => {   setIsVisibleBook(true); };
 const [visibleDV, setIsVisibleDV] = useState(false);
 const onShowDV = () => {   setIsVisibleDV(true); };

 const [visiblePage, setIsVisiblePage] = useState(false);
 const onShowPage = () => {   setIsVisiblePage(true); };
 const [searchWordArray, setSearchWordArray] = useState([]);
 const [currentPage, setCurrentPage] = useState(1);
 const [pickedBookValue, setPickedBookValue] = useState(null);
 const [isBack, setIsBack] = useState(false);

 const [pickedDivisionName, setIsPickedCourtDivisionName] = useState({
  key: "",  
  label: "",   

});
const [pickedJusticesName, setIsPickedJusticesName] = useState({
  key: "",  
  label: "",   

});

 const [pickedType, setIsPickedType] = useState({
  key: "",  
  label: "",   

});
const [pickedPara, setIsPickedPara] = useState({
  key: 1,  
  label: 'Without Important Para',   

});
const [picked, setIsPicked] = useState({
  key: "",  
  label: "",   

});
const [pickedBook, setIsPickedBook] = useState({
  key: "",  
  label: "",   

});
const [pickedVolume, setIsPickedVolume] = useState({
  key: "",  
  label: "",   

});

const [pickedDV, setIsPickedDV] = useState({
  key: "",  
  label: "",   

});
const [pickedPage, setIsPickedPage] = useState({
  key: "",  
  label: "",   

});


const  onSelectVolume = (data) => {
   // console.log(pickedBook.key);
  // const {key,label} = data;
  if(pickedBookValue===1){ setIsPickedDV({
    value:1,
    label:"AD",
  });  }
  else{setDVNo(DVList);}
  // handleChange(data.label);
  //     setIsVisibleVolume(false);
  slectVolume(data);
    };
const   onSelectBook = (data) => {
    //console.log(data.key);
  // const {key,label} = data;
  //setVolumeNo([]);
 // setDVNo([]);
 // setPagesLists([]);

 // setIsPickedVolume([]); 
 // setIsPickedDV([]);
 // setIsPickedPage([]);
  const VData = {   
    id:data
  };
  if(data==='B'){    setVolumeNo(Volume_no_list); }
else{fetchVolumeData(VData);}
//  handleChange(data.label);
     // setIsVisibleBook(false);
     setPickedBookValue(data)
     
    };
  
    const   onSelectDV = (handleChange,data) => {
      //  console.log(data);
      const {key,label} = data;
      const pageData = {
        jud_no:pickedVolume.key,
        cd_id:data.key
      };
      fetchPageData(pageData);
      handleChange(data.label);
          setIsVisibleDV(false);
          setIsPickedDV({
            key:data.key,
            label:data.label,
          });
        };
    const   onClearPage = (handleChange,data) => {
        alert('hand');
          handleChange("");
             
            };
        const   onSelectPage = (handleChange,data) => {
         // console.log(data);
          //const {key,label} = data;
          setIsVisiblePage(false);
          handleChange(data.label.toString());
              
              setIsPickedPage({
                key: data.key.toString(),
                label: data.label.toString(),
              });
            };

    const   onSelectCourtDivisionName = (handleChange,data) => {
      //  console.log(data);
      // const {key,label} = data;
      Keyboard.dismiss();
      setIsVisibleCourtDivisionName(false);
      handleChange(data.label);
         
          setIsPickedCourtDivisionName({
            key:data.key,
            label:data.label,
          });
        };
    const   onSelectJusticesName = (handleChange,data) => {
      //  console.log(data);
       // const {key,label} = data;
       handleChange(data.label);
          setIsVisibleJusticesName(false);
          setIsPickedJusticesName({
            key:data.key,
            label:data.label,
          });
        };

    const  onSelectType  = (handleChange,data) => {
      //  console.log(data);
      // const {key,label} = data;
      handleChange(data.label);
          setIsVisibleType (false);
          setIsPickedType ({
            key:data.key,
            label:data.label,
          });
         
        };
        const  onSelectPara  = (handleChange,data) => {
          //  console.log(data);
          // const {key,label} = data;
          handleChange(data.label);
              setIsVisiblePara (false);
              setIsPickedPara ({
                key:data.key,
                label:data.label,
              });
             
            };
            const [ParaValue, setParaValue] = useState(null);
     

 const [user, setUser] = useState({
  chamber_id: "",   
  user_id: "",
  justice_name_sel:"",
  Id:"",
  justice_id:"",
  case_type:"",
  section:"",
  jud_no:"",
  act:"",
  article:"",
  book_id:"",
  Volume_id:"",
  division_id:"",
  page_id:"",

  jud_rule: "",    
  jud_order: "",    
  order_rule: "", 
  caseno: "",   
  blrno: "",  
  book_ref:"",
  anykey: "",    
  lawyer: "",    
  jud_year: "", 
  parties: "",
  judgment_date: "",
  division_name:"",
  case_type_id:"",
  full_judgment: "",    
  countData: "",    
  court_division_id: "", 
  MaxPage: "",    
  page: "",    
  offset: "",   
  PageSize: "", 
  summery: "", 
  summeryBookChecked: "", 
  getFullData: "", 
  para_id: "", 
  MoreTwo: "",
  MoreOne: "",
 
});
const [caseEntryallData, setCaseEntryallData] = useState({
  
  chamber_id: "",   
  user_id: "",
  justice_name_sel:"",
  Id:"",
  justice_id:"",
  case_type:"",
  section:"",
  jud_no:"",
  act:"",
  article:"",

  jud_rule: "",    
  jud_order: "",    
  order_rule: "", 
  caseno: "",   
  blrno: "",  
  book_ref:"",
  anykey: "",    
  lawyer: "",    
  jud_year: "", 
  parties: "",
  judgment_date: "",
  division_name:"",
  case_type_id:"",
  full_judgment: "",    
  countData: "",    
  court_division_id: "", 
  MaxPage: "",    
  page: "",    
  offset: "",   
  PageSize: "", 
  summery: "", 
  summeryBookChecked: "", 
  getFullData: "", 
  para_id: "", 
  MoreTwo: "",
  MoreOne: "",
  
 
});

const upButtonHandler = () => {
  //OnCLick of Up button we scrolled the list to top
  listViewRef.scrollToOffset({ offset: 0, animated: true });
};

//const { reset } = useNavigation();
//  useFocusEffect(
//   React.useCallback(() => {
//     //async fetch user data
   
   

//   }, [])
// );
const onPopstate=()=> {
  setIsBack(true);
 alert("back!!!");
  //this.props.navigation.navigate("Login");
  navigation.goBack(null);
}


  useEffect(() => {
    window.history.pushState(null, null, document.URL);
   // window.addEventListener("popstate", onPopstate(), alert('test back'));
   window.addEventListener('popstate', (event) => {navigation.goBack(null); });
   
    setTotal_case([]);
    setFocusLength(false);
    setStatuses(0);
    setCase_year('');
    setCaseNumber('');
    setFilteredDataSource([]);
    setIsEditing(true);
    //getChamberId().then(chamberId => setChamber_id(chamberId));
    //LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
   // console.log("Total Case");
  // handleSubmitPress();  
  fetchData();
  }, []);


  const fetchVolumeData=(values)=>{ 
    // console.log('hh '+chamber_id);    
    _fetchVolumeData(values)
       .then((data) => {
         if (!data) return;
         setVolumeNo( data.map(marker =>{ 
          marker.value = marker.key.toString() 
           marker.label = marker.label.toString() 
            return marker
        }));
       })
       .catch((err) => console.log(err));   
    
    }

  const _fetchVolumeData = async (Pdata) => {
    try {
      let dataToSend =Pdata;
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + '=' + encodedValue);
      }
      formBody = formBody.join('&');
      let url = BASE_URL + "/Getvolume?";       
     let response = await   axios.post(url, formBody).then((res) => res.data);
        return response;      
    } catch (err) {
      console.log("Error", err);
    }
  }; 


  const fetchPageData=(values)=>{ 
    // console.log('hh '+chamber_id);    
    _fetchPagesData(values)
    .then((data) => { 
      if (!data) return; 
      setPagesLists( data.map(marker =>{ 
      marker.value = marker.key.toString() 
       marker.label = marker.label.toString() 
        return marker
        }));
  }) .catch((err) => console.log(err));     
    
    }

  const _fetchPagesData = async (Pdata) => {
    try {
      let dataToSend =Pdata;
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + '=' + encodedValue);
      }
      formBody = formBody.join('&');
      let url = BASE_URL + "/GetYears?";
     // let response = await axios.get(url).then((res) => res.data);      
     let response = await   axios.post(url, formBody).then((res) => res.data);
      
     // if (response.resp == 1)
       return response;
      //if (response.country) return response.country;
     // console.log(response.user); 
    } catch (err) {
      console.log("Error", err);
    }
  }; 

  const _fetchUserData = async () => {


    try {
     
      let url = BASE_URL + "/JudgmentHome";
     // let response = await axios.get(url).then((res) => res.data);      
     let response = await  axios({
      method: 'post',
      url: url,
      headers: {
       // 'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization':'Basic YnJva2VyOmJyb2tlcl8xMjM='
      },
      data: null
    }).then((res) => res.data);
      
     // if (response.resp == 1)
       return response;
      //if (response.country) return response.country;
     // console.log(response.user); 
    } catch (err) {
      console.log("Error", err);
    }
  };   


  const fetchData=()=>{ 
 

    // console.log('hh '+chamber_id);
    
     _fetchUserData()
       .then((data) => {
         if (!data) return;
      //  console.log(data.file_location);
     // console.log(data.court_division_name);
    //  var newStateArray = data.court_division_name.slice();

    // let allcourt_division_name = newStateArray.push({
    //    id:0,
    //    name: "All"
    //    });

         setCourtDivision( data.court_division_name.map(marker =>{ 
           marker.value = marker.id
            marker.label = marker.name 
             return marker
         }));
          setCourtDivision(oldArray => [{
            value:0,
          label: "All"
          },...oldArray] ) ;

         setJusticesName( data.JusticesName.map(marker =>{ 
            marker.value = marker.id
            marker.label = marker.name            
             return marker
         }));
         setJusticesName(oldArray => [{
          value:0,
          label: "All"
          },...oldArray] ) ;
         setCaseTypeName( data.caseTypeName.map(marker =>{ 
           marker.value = marker.id
            marker.label = marker.name 
             return marker
         }));
         setCaseTypeName(oldArray => [{
          value:0,
          label: "All"
          },...oldArray] ) ;
        //  setVolumeLists( data.VolumeLists.map(marker =>{ 
        //    marker.value = marker.id
        //     marker.label = marker.name 
        //      return marker
        //  })); 
        //  setVolumeLists(oldArray => [{
        //   value:0,
        //   label: "All"
        //   },...oldArray] ) ;
       })
       .catch((err) => console.log(err)); 
    
    }

    // const fetchJudgeNameData=(values)=>{ 
    //   // console.log('hh '+chamber_id);    
    //   _fetchJudgeNameData(values)
    //      .then((data) => {
    //        if (!data) return;
    //      //  console.log(data);
    //     // console.log(data);
    //           return data;
         
    //      })
    //      .catch((err) => console.log(err));    
      
    //   }
  
    // const _fetchJudgeNameData = async (Pdata) => {
    //   try {
    //     let dataToSend =Pdata;
    //     let formBody = [];
    //     for (let keys in dataToSend) {
    //       let encodedKey = encodeURIComponent(keys);
    //       let encodedValue = encodeURIComponent(dataToSend[keys]);
    //       formBody.push(encodedKey + '=' + encodedValue);
    //     }
    //     formBody = formBody.join('&');
    //     let url = BASE_URL + "/get_justice?";
    //    // let response = await axios.get(url).then((res) => res.data); 

    //    let response = await axios.post(url, formBody).then((res) => res.data);
        
    //    // if (response.resp == 1)
    //    //console.log(response);
    //      return response;
    //     //if (response.country) return response.country;
    //    // console.log(response.user); 
    //   } catch (err) {
    //     console.log("Error", err);
    //   }
    // }; 






    const _handleSubmitPress = async (caseData) => {


      try {
       
        let dataToSend =caseData;
        let formBody = [];
        for (let key in dataToSend) {
          let encodedKey = encodeURIComponent(key);
          let encodedValue = encodeURIComponent(dataToSend[key]);
          formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');
        let url = BASE_URL + "/LoadJudgements?";
       // let response = await axios.get(url).then((res) => res.data);      
       let response = await axios.post(url, formBody).then((res) => res.data);        
       // if (response.resp == 1)
         return response;
        //if (response.country) return response.country;
       // console.log(response.user); 
      } catch (err) {
        console.log("Error", err);
      }
    };  
    const bookref = (value3,key) => {
     // l++;
    // var i=0;
    // i++
      //debugger;
     // console.log(res);
    // console.log(value3);
      var ref_str = value3.toString().replace(/^\s+|\s+$/g,' ').trim();
     // console.log(ref_str);
      var res = ref_str.split(" ");
    // console.log(res);
     // console.log(res[1]);
      var final_ref = res[1];

      // 08-02-2022
      var final_reference = value3;
      if (res.length == 4) {
          final_reference = res[1] + " " + res[0] + " " + res[2] + " " + res[3];
      }
      var refColor="#0000FF";
      if(res[1]=='ADC'){refColor="#d94f05";} if(res[1]=='BLC'){refColor="#800080";}
      if(res[1]=='BLD'){refColor="#00008B";} if(res[1]=='BLT'){refColor="#FF00FF";}
      if(res[1]=='DLR'){refColor="#8000ff";} if(res[1]=='MLR'){refColor="#FF0000";}
      if(res[1]=='SCOB'){refColor="#513B1C";} if(res[1]=='ALR'){refColor="#3114b6";}
      if(res[1]=='CLR'){refColor="#7B68EE";} 
      //if(key==10){refColor="#00f0D3";}

      // if(key==1){refColor="#d94f05";} if(key==2){refColor="#800080";}
      // if(key==3){refColor="#00008B";} if(key==4){refColor="#FF00FF";}
      // if(key==5){refColor="#808000";} if(key==6){refColor="#FF0000";}
      // if(key==7){refColor="#513B1C";} if(key==8){refColor="#F4cccc";}
      // if(key==9){refColor="#7B68EE";} if(key==10){refColor="#00f0D3";}

     return(  <View   key={key} style={{   marginTop: 5, paddingHorizontal: 3, }}><Text     style={{    padding:3,    borderRadius:6,   backgroundColor:refColor, maxWidth:175, color:"#fff",  fontSize: 12, fontWeight:'bold', textAlign: "center",   }} >{final_reference}</Text>
        </View>                 
    );

    // return final_reference;

    }

    const removeUselessWords =  (searchtext) => {
      var uselessWordsArray =
         [
             "a", "an", "as", "at", "be", "and", "act", "article", "can", "cant", "could", "couldnt",
             "do", "does", "how", "i", "if", "in", "it", "is", "not", "many", "much", "of",
             "on", "order", "or", "rule", "should", "shouldnt", "so", "such", "the", "The",
             "them", "they", "to", "us", "we", "what", "who", "why",
             "with", "wont", "would", "wouldnt", "you", "this", "was", "were", "by", "a", "an", "at", "be", "and", "act", "article",
             "can", "can’t", "could", "couldn’t", "do", "does", "how", "i", "in", "it", "is", "j", "many", "much", "of", "on", "order",
             "or", "rule", "should", "shouldn’t", "so", "such", "the", "The", "them", "they", "to", "us", "we", "what", "who", "why",
             "with", "wont", "would", "wouldn’t", "you", "did", "they", "them", "themselves", "yourself", "could not", "Shouldn’t", "may", "wasn’t",
             "won’t", "can’t", "weren’t", "wouldn’t", "between", "am", "are", "amongst", "been", "aboard", "about", "above", "across", "after", "against", "along",
             "amid", "among", "anti", "around", "as", "at", "before", "behind", "below", "beneath", "beside", "besides", "between", "beyond", "but", "by",
             "concerning", "considering", "despite", "down", "during", "except", "excepting", "excluding", "following", "for", "from", "in", "inside", "into",
             "like", "minus", "near", "no", "of", "off", "on", "onto", "opposite", "outside", "over", "past", "per", "plus", "regarding", "round", "around", "save",
             "since", "th", "than", "that", "through", "to", "toward", "towards", "under", "underneath", "unlike", "until", "up", "upon", "versus", "via", "with", "within", "without",
             "although", "though", "you’re", "I’m", "its", "it’s", "your’s", "yours", "he", "she", "might", "shall", "section", "s", ",", " ", "."
         ];
    //  var uselessWordsArray =[Html.Raw("'" + string.Join("', '", (string[])ViewBag.StopWordList) + "'")];
      var mystring = searchtext.replace(/\./g, ' ');
      var expStrrss = mystring.replace(/[^0-9a-z-A-Z ]/g, " ").replace(/ +/, " ");
      var expStr = uselessWordsArray.join("|");
      var expStrr = expStrrss.replace(new RegExp('\\b(' + expStr + ')\\b', 'gi'), ' ').replace(/[^A-Z\d\s]+/gi, ' ').replace(/\s{2,}/g, ' ');
      var expStrrs = expStrr.replace(/[^A-Z\d\s]/gi, '').replace(/\s{2,}/g, ' ');
      var string = expStrrs.replace(/\s{2,}/g, ' ').split(' ').filter(i => i.length > 2).join(' ');
      return string;
  }
  const addSearchItem = (newElement) => {
    setSearchWordArray(searchWordArray => [...searchWordArray, newElement]);
    //setSearchWordArray([...searchWordArray, newElement]);
  }

  const _searchString = (caseData) =>{
    var justice_id = caseData.justice_id;
    var justice_name_sel = caseData.justice_name_sel;               
    var case_type_text = caseData.case_type_text;
    var division_text = caseData.division_text;
    var section = caseData.section;
    var act = caseData.act;
    var article = caseData.article;
    var case_type_id = caseData.case_type_id;
    var article = caseData.article;
    var jud_rule = caseData.jud_rule;
    var lawyer = caseData.lawyer;
    var jud_order = caseData.jud_order;
    var caseno = caseData.caseno;
    var blrno = caseData.blrno;
    var book_ref= caseData.book_ref;
    var book_ref2 = caseData.book_ref;
    var divisio_id = caseData.court_division_id;
    var MoreTwo = caseData.MoreTwo;
    var MoreOne =caseData.MoreOne;
    var anykey = caseData.anykey;
    //var anykey = obj.anykey;
    anykey = decodeURIComponent(anykey);
    anykey = anykey.replace(/[!'()*&"<>]/g, escape);
    var searchtextm = removeUselessWords(anykey).trim();
    anykey=searchtextm.toString();
   
    if ( searchtextm != ""){
      var words = searchtextm.split(" ");
      words.map(word => (
        setSearchWordArray(searchWordArray => [...searchWordArray, word])
       
      ));
    }
    MoreOne=MoreOne.toString();
   
    if ( MoreOne != ""){
      var words = MoreOne.split(" ");
      words.map(word => (
        setSearchWordArray(searchWordArray => [...searchWordArray, word])
       
      ));
    }
    MoreTwo=MoreTwo.toString();
   
    if ( MoreTwo != ""){
      var words = MoreTwo.split(" ");
      words.map(word => (
        setSearchWordArray(searchWordArray => [...searchWordArray, word])
       
      ));
    }
     //Rule highlight
     if (jud_rule != "") {
    let  newElement= "rule" + (jud_rule?jud_rule:"");
    setSearchWordArray(searchWordArray => [...searchWordArray, newElement]);
  }
  //Order highlight
  if (jud_order != "") {
    let  newElement=  "order "+(jud_order?jud_order:"");
    setSearchWordArray(searchWordArray => [...searchWordArray, newElement]);
  }
  //Section highlight
  if (section != "") {
    let  newElement=  "section "+(section?section:"");
    setSearchWordArray(searchWordArray => [...searchWordArray, newElement]);
  }
  //Act highlight
  if (act != "") {
    let  newElement=  "act " + (act?act:"");
    setSearchWordArray(searchWordArray => [...searchWordArray, newElement]);
  }
  //Article highlight
  if (article != "") {
    let  newElement=  "Article " + (article?article:"");
    setSearchWordArray(searchWordArray => [...searchWordArray, newElement]);
  }


  }

    const judgmentarray = (value,key) => {
      var justice_id = searchEntity.justice_id;
      var justice_name_sel = searchEntity.justice_name_sel;               
      var case_type_text = searchEntity.case_type_text;
      var division_text = searchEntity.division_text;
      var section = searchEntity.section;
      var act = searchEntity.act;
      var article = searchEntity.article;
      var case_type_id = searchEntity.case_type_id;
      var article = searchEntity.article;
      var jud_rule = searchEntity.jud_rule;
      var lawyer = searchEntity.lawyer;
      var jud_order = searchEntity.jud_order;
      var caseno = searchEntity.caseno;
      var blrno = searchEntity.blrno;
      var book_ref= searchEntity.book_ref;
      var book_ref2 = searchEntity.book_ref;
      var divisio_id = searchEntity.court_division_id;
      var anykey = searchEntity.anykey;
      //var anykey = obj.anykey;
      anykey = decodeURIComponent(anykey);
      anykey = anykey.replace(/[!'()*&"<>]/g, escape);
      var searchtextm = removeUselessWords(anykey).trim();
      anykey=searchtextm.toString();
    

      var jvalue = value.toString();

      var section_cnt = jvalue.search(new RegExp("section " + section, "gi"));
      var act_cnt = jvalue.search(new RegExp("act " + act, "gi"));
      var article_cnt = jvalue.search(new RegExp("article " + article, "gi"));
      var case_type_id_cnt = jvalue.search(new RegExp(case_type_id, "gi"));
      var jud_rule_cnt = jvalue.search(new RegExp("rule " + jud_rule, "gi"));
      var jud_order_cnt = jvalue.search(new RegExp("order " + jud_order, "gi"));
      var caseno_cnt = jvalue.search(new RegExp(caseno, "gi"));
      var blrno_cnt = jvalue.search(new RegExp(blrno, "gi"));
      if (pickedBookNmae== "BLR") {
          blrno = blrno.replace(/(\s+)/, "(<[^>]+>)*$1(<[^>]+>)*");
          var blrno_pattern = new RegExp("(" + blrno + ")", "gi");
      }
      if (article != "") {
          var article_pattern = new RegExp('\\S*' + "article " + article + '\\S*', 'gi');
      }
      // if (case_type_id > 0) {
      //     case_type_id = case_type_id.replace(/(\s+)/, "(<[^>]+>)*$1(<[^>]+>)*");
      //     var case_type_id_pattern = new RegExp("(" + case_type_id + ")", "gi");
      // }
      if (jud_rule > 0 && jud_rule != "" ) {
          jud_rule = jud_rule.replace(/(\s+)/, "(<[^>]+>)*$1(<[^>]+>)*");
          var jud_rule_pattern = new RegExp('\\S*' + "rule " + jud_rule + '\\S*', 'gi');
      }
      if (jud_order != "") {
          var jud_order_pattern = new RegExp('\\S*' + "order " + jud_order + '\\S*', 'gi');
      }
      if (caseno != "") {
          caseno = caseno.replace(/(\s+)/, "(<[^>]+>)*$1(<[^>]+>)*");
          var caseno_pattern = new RegExp("(" + caseno + ")", "gi");
      }
      if (section != "") {
          section = section.replace(/(\s+)/, "(<[^>]+>)*$1(<[^>]+>)*");
          var section_pattern = new RegExp("(section " + section + ")", "gi");
      }
      if (act != "") {
          act = act.replace(/(\s+)/, "(<[^>]+>)*$1(<[^>]+>)*");
          var act_pattern = new RegExp("(act " + act + ")", "gi");
      }
      //var str_array = searchtextm.split(' ');
      var regexStr = searchtextm.split(/\,|\s/).join('|');
      var searchRegEx = new RegExp(regexStr, 'gi');
      var regexExactMatch = searchRegEx.test(jvalue);
      if ((regexExactMatch && searchtextm != "") || (section_cnt > 0 && section != '') || (act_cnt > 0 && act != '') || (article_cnt > 0 && article != '') || (jud_order_cnt > 0 && jud_order != '') || (jud_rule_cnt > 0 && jud_rule != '')) {
          if (regexExactMatch && searchtextm != "") {
              if (regexExactMatch) {
                  var words = searchtextm.split(" ");
                  var reg = new RegExp("(?![^<]+>)(" + words.join("|") + ")", "ig");
                  var jvalue = jvalue.replace(reg, "<b style='background-color:#FFFF00; color:#000 ;'>$1</b>");
              }
              // $(".any_words").each(function () {
              //     var anywords = $(this).val();
              //     if (anywords != '') {
              //         var anywords_cnt = jvalue.search(new RegExp(anywords, "gi"));
              //         if (anywords_cnt >= 0) {
              //             var anywords_pattern = new RegExp("(" + anywords + ")", "gi");
              //             //var anywords_pattern = new RegExp('\\S*' + anywords + '\\S*', 'gi');   //17_08_2022 By Rafique
              //             //jvalue = jvalue.replace(anywords_pattern, "<b style='background-color:yellow; color:#000 !important;'>" + anywords + "</b>");  //17_08_2022 By Rafique
              //             var jvalues = jvalue.replace(anywords_pattern, "<b style='background-color:yellow; color:#000 !important;'>" + anywords + "</b>");  //17_08_2022 By Rafique
              //             jvalue = jvalues.replace(/<b style='background-color:yellow; color:#000 <b style='background-color:yellow; color:#000 !important;'>/g, "<b style='background-color:yellow; color:#000 !important;'>");  //17_08_2022 By Rafique
              //         }
              //     }
              // });
          }
          //Rule highlight
          if (jud_rule > 0 && jud_rule != "") {
              jvalue = jvalue.replace(jud_rule_pattern, "<b style='background-color:#FFFF00; color:#000000; '>rule" + jud_rule + "</b>");
          }
          //Order highlight
          if (jud_order > 0 && jud_order != "") {
              jvalue = jvalue.replace(jud_order_pattern, "<b style='background-color:#FFFF00; color:#000000;'>order" + jud_order + "</b>");
          }
          //Section highlight
          if (section_cnt > 0 && section != "") {
              jvalue = jvalue.replace(section_pattern, "<b style='background-color:#FFFF00; color:#000000; '>$1</b>");
          }
          //Act highlight
          if (act_cnt > 0 && act != "") {
              jvalue = jvalue.replace(act_pattern, "<b style='background-color:#FFFF00; color:#000000; '>$1</b>");
          }
          //Article highlight
          if (article_cnt > 0 && article != "") {
              jvalue = jvalue.replace(article_pattern, "<b style='background-color:#FFFF00; color:#000000; '>Article" + article + "</b>");
          }
         // $("#full_jud_" + key).append(jvalue + "<br><br>");
       //  console.log(jvalue);
       if(jvalue ==','){
       
         }
         else{
          return ("<p>"+jvalue.replace(/,+(?=,|$)/g, '') + "</p>");
         }

      }
      else {
       
      }


    };
    function convertHTML(str) {
      // Use Object Lookup to declare as many HTML entities as needed.
      const htmlEntities = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&apos;"
      };
      // Using a regex, replace characters with it's corresponding html entity
      return str.replace(/([&<>\"'])/g, match => htmlEntities[match]);
    }

    const Newjudgmentarray = (value,key) => {

     // console.log(searchEntites);

      var justice_id = searchEntites.justice_id;
      var justice_name_sel = searchEntites.justice_name_sel;               
      var case_type_text = searchEntites.case_type_text;
      var division_text = searchEntites.division_text;
      var section = searchEntites.section;
      var act = searchEntites.act;
      var article = searchEntites.article;
      var case_type_id = searchEntites.case_type_id;
      var article = searchEntites.article;
      var jud_rule = searchEntites.jud_rule;
      var lawyer = searchEntites.lawyer;
      var jud_order = searchEntites.jud_order;
      var caseno = searchEntites.caseno;
      var blrno = searchEntites.blrno;
      var book_ref= searchEntites.book_ref;
      var book_ref2 = searchEntites.book_ref;
      var divisio_id = searchEntites.court_division_id;
      var anykey = searchEntites.anykey;
      //var anykey = obj.anykey;
      anykey = decodeURIComponent(anykey);
      anykey = anykey.replace(/[!'()*&"<>]/g, escape);
      var searchtextm = removeUselessWords(anykey).trim();
      anykey=searchtextm.toString();
    

      var jvalue = value.toString();

      var section_cnt = jvalue.search(new RegExp("section " + section, "gi"));
      var act_cnt = jvalue.search(new RegExp("act " + act, "gi"));
      var article_cnt = jvalue.search(new RegExp("article " + article, "gi"));
      var case_type_id_cnt = jvalue.search(new RegExp(case_type_id, "gi"));
      var jud_rule_cnt = jvalue.search(new RegExp("rule " + jud_rule, "gi"));
      var jud_order_cnt = jvalue.search(new RegExp("order " + jud_order, "gi"));
      var caseno_cnt = jvalue.search(new RegExp(caseno, "gi"));
      var blrno_cnt = jvalue.search(new RegExp(blrno, "gi"));
     
      var regexStr = searchtextm.split(/\,|\s/).join('|');
      var searchRegEx = new RegExp(regexStr, 'gi');
      var regexExactMatch = searchRegEx.test(jvalue);
      
      //console.log(regexExactMatch);
      if ((regexExactMatch && searchtextm != "") || (section_cnt > 0 && section != '') || (act_cnt > 0 && act != '') || (article_cnt > 0 && article != '') || (jud_order_cnt > 0 && jud_order != '') || (jud_rule_cnt > 0 && jud_rule != '')) {
       // let result =jvalue.replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/ig, "");
       let result =jvalue.replace(/<([\w\-/]+)( +[\w\-]+(=(('[^']*')|("[^"]*")))?)* *>/g, "").replace('&amp;', '&').replace('&nbsp;', " ");
        if(result !="")
          return (decode(result));
         }else {
         
      }


    };


    const lawyerarray = (value) => {
    
     // var justice_name_sel = searchEntity.justice_name_sel; 
      var lawyer = searchEntity.lawyer;
      //var jvalue = value.toString();
      var lawyersm = "";
     
      var judg_lawyer = value?.toString();
      var j_lawyer = lawyer;
      if (lawyer != "") {
          var oa_lawyer_cnt = judg_lawyer?.search(new RegExp(lawyer, "gi"));

          var jud_lawyer_pattern = new RegExp("(" + j_lawyer + ")", "gi");

          if (oa_lawyer_cnt >= 0) {
              lawyersm = judg_lawyer.replace(jud_lawyer_pattern, "<b style='background-color:#FFFF00; color:#000000; '>$1</b>");
            
          }
      }
     
   return ('<span>'+lawyersm+'</span>');

    };

    const judgenamearray = (value,judge_id) => {
    
      var justice_name_sel = searchEntity.justice_name_sel; 
     
      //var jvalue = value.toString();
      var lawyersm = "";
     
      var judg_lawyer = value?.toString();
      var j_lawyer = lawyer;
      if (lawyer != "") {
          var oa_lawyer_cnt = judg_lawyer?.search(new RegExp(lawyer, "gi"));

          var jud_lawyer_pattern = new RegExp("(" + j_lawyer + ")", "gi");

          if (oa_lawyer_cnt >= 0) {
              lawyersm = judg_lawyer.replace(jud_lawyer_pattern, "<b style='background-color:#FFFF00; color:#000000; '>$1</b>");
            
          }
      }
     
   return ('<span>'+lawyersm+'</span>');

    };

    const judgmentImPara= (value,key) => {

      var jvalue = value.toString();

     // return ("<p>"+jvalue.replace(/\n/g, "<br>") + "</p>");
      return ("<p>"+jvalue.replace(/\n/g, "<br>") + "</p>");
    }
    // const judgNameView= (value,key) => {


    //   const allname  =  fetchJudgeNameData({id: value});

    //  //console.log(allname);
    //  return allname?.map(alljudgNameView);

    // }


    const alljudgNameView= (value,key) => {


  
     // var jvalue = value.toString();
      var judgwcheifjudg = "";
      var ChiefJustice = "Chief Justice";
      if (value.justice_type == 1) {
          judgwcheifjudg = value.name + ", " + ChiefJustice;
      }
      else {
          judgwcheifjudg = value.name;
      }
     const returnText="";
      if(searchEntity.justice_id==value.justice_id){
        returnText = "<b style='background-color:#FFFF00; color:#000000; '>" + judgwcheifjudg + "</b><br>"
      }else{
        returnText = "<b color:#ffffff; '>" + judgwcheifjudg + "</b><br>"
      }


      return (returnText);
    
    }



    const customSE = (obj) => {
       let  newObj = {};
       newObj.justice_id = obj.justice_id;
       newObj.justice_name_sel = pickedJusticesName.label.toString() ;               
       newObj.case_type_text = pickedType.label.toString();
       newObj.division_text = pickedDivisionName.label.toString();
       newObj.section = obj.section;
       newObj.act = obj.act;
       newObj.article = obj.article;
       newObj.case_type_id = obj.case_type_id;
       newObj.article = obj.article;
       newObj.jud_rule = obj.jud_rule;
       newObj.lawyer = obj.lawyer;
       newObj.jud_order = obj.jud_order;
       newObj.caseno = obj.caseno;
       newObj.blrno = obj.blrno;
       newObj.book_ref = obj.book_ref;
       newObj.book_ref2 = obj.book_ref;
       newObj.divisio_id = obj.court_division_id;
       var anykey = obj.anykey;
       anykey = decodeURIComponent(anykey);
       anykey = anykey.replace(/[!'()*&"<>]/g, escape)
       var searchtextm = removeUselessWords(searchtext).trim();
       newObj.anykey=searchtextm.toString();
       return newObj;

    };

    const customJ = (obj) => {
    let  newObj = {};
    newObj.divisio_id = obj.court_division_id;
    newObj.case_type_id = obj.case_type_id;
      newObj.division_name = obj.division_name;
      newObj.caseno = obj.caseno;
      newObj.jud_no = obj.jud_no;
      newObj.Id = obj.Id;
      newObj.section = obj.section;
      newObj.act = obj.act;
      newObj.article = obj.article;
      newObj.jud_rule = obj.jud_rule;
      newObj.jud_order = obj.jud_order;
      newObj.judgment_date = obj.judgment_date;
      newObj.jud_year = obj.jud_year;
      var judgment_s = obj.summery?.toString();
      judgment_s = judgment_s?.replace(/\n/g, "<br>");     
      judgment_s = judgment_s?.replace(/<(?!br\s*\/?)[^>]+>/g, '');     
       var judgsummery = judgment_s?.split('<br>');
       newObj.summery = judgsummery;
     // newObj.full_judgment = obj.full_judgment;
    // var full_judgment = obj.full_judgment.toString();
     var full_judgment = obj.full_judgment.toString();
  //var Clearnewline = str => str.split(/\r?\n/).filter(line => line.trim() !== '').join('\n');
    
 // var full_judgment=Clearnewline(judgment);
    //  full_judgment = full_judgment.replace(/\n/g, "<br>");
     
   // full_judgment = full_judgment.replace(/<(?!br\s*\/?)[^>]+>/g, '');
   
    // var judgarr = full_judgment.split('<br>');
    var judgarr = full_judgment.split(/\r?\n/);
    let judgarrs = judgarr.map(Newjudgmentarray).filter(x => typeof x === 'string' && x.length > 0).join('\n\n');
    // console.log(judgarrs);
    newObj.full_judgment = judgarrs;
     //newObj.full_judgment = judgarr;
     // newObj.book_ref = obj.book_ref;
    // let alljudgeNme =  _fetchJudgeNameData({id: obj.Id});
    // newObj.alljudgeNme = alljudgeNme; 

    //  newObj.lawyer = judgenamearray(alljudgeNme,obj.justice_id);
     let alllawyer =  lawyerarray(obj.lawyer);
     newObj.lawyer = alllawyer;    
     let book_ref= obj.book_ref;
     //console.log(book_ref);
     if (book_ref != null) {
     // console.log(book_ref);
      var book_ref_ar = book_ref.split(',');
     
      book_ref = book_ref_ar;
     // book_ref = book_ref;  
     

  }
  else {
      book_ref = "";
  }
  newObj.book_ref = book_ref;
      
      return newObj;
  };
 

  const handleSubmitPress = async (values, actions) => {
   // setFilteredDataSource([]);
    setIsSubmitting(true);
    setSearchWordArray([]);
  // const caseData = {
  //   book_ref: values.Volume_id + pickedBookNmae + values.page_id  ,
  // }
 
  const allData = {  justice_id:values.justice_id,
    case_type:values.case_type,
    section:values.section,
    jud_no:values.jud_no,
    act:values.act,
    article:values.article,    
    jud_rule:values.jud_rule,    
    jud_order:values.jud_order,    
    order_rule:values.order_rule, 
    caseno:values.caseno,
    //book_ref:pickedBookValue==="BLR"?"":( pickedBookValue==="ADC"? pickedVolumeValue + pickedBookValue + values.page_id:pickedVolumeValue + pickedBookValue + pickedDvValue+ values.page_id ) ,
    //blrno: pickedBookValue==="BLR"?pickedBookValue + "/" +pickedVolumeValue:"",
    book_ref:pickedBookValue===""||pickedBookValue===null?"":(pickedBookValue==="B"?"":( pickedBookValue===1? (pickedVolumeValue + pickedBookNmae + values.page_id):(pickedVolumeValue + pickedBookNmae + pickedDvlabel + values.page_id ))) ,
    blrno: pickedBookValue==="B"?pickedPageValue + "/" +pickedVolumeValue:"",
    anykey:values.anykey,    
   lawyer:values.lawyer,  
   case_type_id:values.case_type_id,   
  
   MoreOne:values.MoreOne,
   MoreTwo:values.MoreTwo, }
//console.log(allData);

//alert(allData);
if(pickedBookValue){
  if(pickedBookValue==="B"){
    if(pickedPageValue===""||pickedVolumeValue===""){ alert('Please select all book referance'); return;}
  }
  
    else if(pickedBookNmae === "ADC"){ 
      if(values.page_id==="" || pickedVolumeValue==="")
      {alert('Please select all book referance'); return;}
      
      }
      else{  //alert(values.page_id +", "+pickedVolumeValue+", "+pickedDvlabel ); 
        if(values.page_id==="" || pickedVolumeValue===""|| pickedDvlabel===null) 
        {alert('Please select all book referance '); return;}
      }

}
 if(values.court_division_id ==="" && allData.justice_id ==="" && allData.MoreTwo === "" &&  allData.MoreOne === "" &&  allData.case_type_id === "" &&  allData.lawyer === "" &&  allData.anykey === "" && allData.book_ref===""&&  allData.blrno === "" &&  allData.caseno === "" &&  allData.order_rule === "" &&  allData.jud_order === "" &&  allData.jud_rule === "" &&  allData.article === "" &&  allData.act === "" &&  allData.jud_no === "" &&  allData.section === "" &&  allData.case_type === ""   ){
  alert('Please select  field');
     }
   else if(values.court_division_id && allData.justice_id ==="" && allData.MoreTwo === "" &&  allData.MoreOne === "" &&  allData.case_type_id === "" &&  allData.lawyer === "" &&  allData.anykey === "" &&  allData.blrno === "" &&  allData.caseno === "" &&  allData.order_rule === "" &&  allData.jud_order === "" &&  allData.jud_rule === "" &&  allData.article === "" &&  allData.act === "" &&  allData.jud_no === "" &&  allData.section === "" &&  allData.case_type === ""   ){
alert('Please select another field');
   }
   else if((allData.MoreOne ||allData.MoreOne) && allData.justice_id ===""  &&  allData.case_type_id === "" &&  allData.lawyer === "" &&  allData.anykey === "" &&  allData.blrno === "" &&  allData.caseno === "" &&  allData.order_rule === "" &&  allData.jud_order === "" &&  allData.jud_rule === "" &&  allData.article === "" &&  allData.act === "" &&  allData.jud_no === "" &&  allData.section === "" &&  allData.case_type === ""   ){
    alert('Please fill Free Text');
       } else {
 
    const caseData = {        
      getFullData:1,
    
      justice_id:values.justice_id,
      case_type:values.case_type,
      section:values.section,
      jud_no:values.jud_no,
      act:values.act,
      article:values.article,    
      jud_rule:values.jud_rule,    
      jud_order:values.jud_order,    
      order_rule:values.order_rule, 
      caseno:values.caseno,
     // book_ref:pickedBook.key=="B"?"":( pickedBook.key=="1"? values.Volume_id + pickedBookNmae + values.page_id:values.Volume_id + pickedBookNmae + values.division_id+ values.page_id ) ,
     // blrno: pickedBook.key=="B"?values.page_id + "/" +values.Volume_id:"",
     book_ref:pickedBookValue===""||pickedBookValue===null?"":(pickedBookValue==="B"?"":( pickedBookValue===1? (pickedVolumeValue + pickedBookNmae + values.page_id):(pickedVolumeValue + pickedBookNmae + pickedDvlabel + values.page_id ))) ,
     blrno: pickedBookValue==="B"?pickedPageValue + "/" +pickedVolumeValue:"",
      anykey:values.anykey,    
     lawyer:values.lawyer,  
     case_type_id:values.case_type_id,     
     court_division_id:values.court_division_id,     
     summeryBookChecked:values.summeryBookChecked, 
     para_id:values.summeryBookChecked,
     MoreOne:values.MoreOne,
     MoreTwo:values.MoreTwo,
    
    };
    //console.log(caseData);
    setSearchEntity(caseData);
    searchEntites = caseData;
    
    _searchString(caseData); 

    setErrortext(''); 
    setLoading(true);
    setNullbody(true);
// if(total_case.length>0){
//   searchFilterFunction(caseData);
// }else{
          _handleSubmitPress(caseData)
            .then((data) => {
              if (!data) {setEndLoader(false); return;}
              // console.log(data.result.fulldatas);               

              let newArr = data.result.fulldatas.map(customJ);
            // console.log(newArr);
              setFilteredDataSource(newArr);
              setTotalJudgement(data.result.totalrow);
            //  let newSArr = searchEntity.map(customSE);
             // console.log(searchWordArray);
             // setSearchEntity(newSArr);
            if(data.result.totalrow===newArr.length){setEndLoader(false);}
              setLoading(false);
              setNullbody(false);
              
              
              // console.log(total_case.length);
            })
            .catch((err) => {console.log(err); setEndLoader(false);});    
          //} 
      }
  };


  const fetchMore  = async (values, actions) => {
     if (moreloading) return;
    // alert(totalrow, total_case.length);
     if(totalrow===total_case.length){ setEndLoader(false); return;}
    // alert('moreload');
   //  console.log(searchEntity)
    // setEndLoader(true)
     setMoreLoading(true);
    const nextPage = currentPage + 1;
     const caseData = {        
       getFullData:1,
       page_no:nextPage,
       justice_id:searchEntity.justice_id,
       case_type:searchEntity.case_type,
       section:searchEntity.section,
       jud_no:searchEntity.jud_no,
       act:searchEntity.act,
       article:searchEntity.article,    
       jud_rule:searchEntity.jud_rule,    
       jud_order:searchEntity.jud_order,    
       order_rule:searchEntity.order_rule, 
       caseno:searchEntity.caseno,
       book_ref:searchEntity.book_ref ,
       blrno: searchEntity.blrno,
       anykey:searchEntity.anykey,    
      lawyer:searchEntity.lawyer,  
      case_type_id:searchEntity.case_type_id,     
      court_division_id:searchEntity.court_division_id,     
      summeryBookChecked:searchEntity.summeryBookChecked, 
      para_id:searchEntity.summeryBookChecked,
      MoreOne:searchEntity.MoreOne,
      MoreTwo:searchEntity.MoreTwo,
     
     };
  
           _handleSubmitPress(caseData)
             .then((data) => {
               if (!data){ setEndLoader(false); return; }
               
               let newArr = data.result.fulldatas.map(customJ);
             // console.log(newArr);
              // setFilteredDataSource(newArr);
               setFilteredDataSource(prevData => [...prevData, ...newArr]);
             
             setCurrentPage(nextPage);
             setMoreLoading(false);
           // setEndLoader(false);
             
             })
             .catch((err) => {console.log(err);  setEndLoader(false);});    
           //} 
      // }
   };

 
  const [dataSource, setDataSource] = useState([]);
  const [scrollToIndex, setScrollToIndex] = useState(0);
  const [dataSourceCords, setDataSourceCords] = useState([]);
  const [ref, setRef] = useState(null);
  const scrollHandler = () => {
    //console.log(dataSourceCords.length, scrollToIndex);
    //if (dataSourceCords.length > scrollToIndex) {
      setTopsCrollShow(true);
      ref.scrollTo({
        x: 0,
        y: 1150,
        animated: true,
      });
    // } else {
    //   alert('Out of Max Index');
    // }
  };
  const scrollToTop = () => {
    //console.log(dataSourceCords.length, scrollToIndex);
    //if (dataSourceCords.length > scrollToIndex) {
    // setTopsCrollShow(false);
      ref.scrollTo({
        x: 0,
        y: 100,
        animated: true,
      });
    // } else {
    //   alert('Out of Max Index');
    // }
  };

  const incrementCount = () => {
    // Update state with incremented value
    setCount(count + 1);
  };

  const onEndReached = () => {
    //console.log('end reached');
    setEndLoader(false);
  }
  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };
  const [modalVisible, setModalVisible] = useState(false);

    const [modalEntry, setModalEntry] = useState();

  const ListFooterComponent = (
    
    <View style={styles.listFooter}>
      <ActivityIndicator animating={true} size="large" color="#1100ff" />
    </View>
   
  
  )
  const endFooterComponent = (
    <View style={styles.main_body_two}> 
    <Text style={{textAlign:"center", fontSize: 20}}>
        End.
    </Text>     
  </View>  
  )

  const searchFilterFunction = (caseData) => {
    // Check if searched text is not blank
    //alert('alternative search')
    setLoading(true);
    setNullbody(true);
    let filteredEmails = total_case;
    // if (caseData.anykey) {
    
    //    filteredEmails = filteredEmails.filter(createFilter(caseData.anykey,  ["full_judgment"], [{ fuzzy:true}]))

   
    // } 
    if (caseData.section) {
      let s= ("section "+caseData.section);
      filteredEmails = filteredEmails.filter(createFilter(s,  ["full_judgment"], [{ fuzzy:true}]))

      filteredEmails = filteredEmails.filter(createFilter(caseData.section,  ["section"], [{ fuzzy:true}]))

  
   } 

   setLoading(false);
   setNullbody(false);
      setFilteredDataSource(filteredEmails);
      setTotalJudgement(filteredEmails.length);     
    
  };
  const [showMoreOne, setShowMoreOne] = useState(false);
  const [showMoreTwo, setShowMoreTwo] = useState(false);
  const AddMoreSearch = () => {
     showMoreOne? setShowMoreTwo(true):setShowMoreOne(true)
   
   }
   const closeMoreSearch1 = () => {
   
   setShowMoreOne(false)
  
  }
  const closeMoreSearch1on = (handleChange) => {
    handleChange('');
   setShowMoreOne(false)
  
  }
  const closeMoreSearch2 = () => {
  
  setShowMoreTwo(false)
  
  }
  const closeMoreSearch2on = (handleChange) => {
    handleChange('');
  setShowMoreTwo(false)
  
  }
   
 const resetSearchData = () => {
  setSearchWordArray([]);
  setIsPickedDV({
    key:"",
    label:"",
  });
  
  setIsPickedVolume({
    key:"",
    label:"",
  });
  setIsPickedPara ({
    key:"",
    label:"",
  });
  setIsPickedType ({
    key:"",
    label:"",
  });
  setIsPickedJusticesName({
    key:"",
    label:"",
  });
  setIsPickedCourtDivisionName({
    key:"",
    label:"",
  });
  setIsPickedPage({
    key: "",
    label: "",
  });
  setIsPickedBook({
    key:"",
    label:"", 
  });
 

  setFilteredDataSource([]);
  setIsSubmitting(false);
  setTotalJudgement(false);
  setLoading(false);
  setNullbody(false);
  closeMoreSearch1();
  closeMoreSearch2();
  setTopsCrollShow(false);
  setPickedVolumeValue(null);
  setPickedDvValue(null);
  setPickedPageValue(null);
  setPickedBookValue(null);
  closeDropdown();
  //setCourtDivisionOpen(false);
 }
  const handleResetvalue = async (values,actions) => {
  alert("jjjj");
    values={
      section:''
    }
      
    
  };
  const CustomText = (props) => (
    <Text style={tagsStyles.p}>{props.children}</Text>
  );
  const CustomHighlight = (props) => (
    <Text style={{ fontWeight: 'bold', backgroundColor:'#ff0', color:'#000' }}>{props.children}</Text>
  );

  const [courtDivisionOpen, setCourtDivisionOpen] = useState(false);
  const [justicesNameOpen, setJusticesNameOpen] = useState(false);
  const [caseTypeNameOpen, setCaseTypeNameOpen] = useState(false);
  const [paraItemsOpen, setParaItemsOpen] = useState(false);
  const [pageItemsOpen, setPageItemsOpen] = useState(false);
  const [dvItemsOpen, setDvItemsOpen] = useState(false);
  const [voliumeItemsOpen, setVoliumeItemsOpen] = useState(false);
  const [bookItemsOpen, setBookItemsOpen] = useState(false);
 
  const [pickedVolumeValue, setPickedVolumeValue] = useState(null);
  const [pickedDvValue, setPickedDvValue] = useState(null);
  const [pickedPageValue, setPickedPageValue] = useState(null);
  const [pickedBookNmae, setPickedBookNmae] = useState(null);
  const [pickedDvlabel, setPickedDvlabel] = useState(null);

  const closeDropdown = useCallback(() => {
    setCourtDivisionOpen(false);
    setJusticesNameOpen(false);
    setCaseTypeNameOpen(false);
    setParaItemsOpen(false);
    setPageItemsOpen(false);
    setDvItemsOpen(false);
    setVoliumeItemsOpen(false);
    setBookItemsOpen(false)


  }, []);
  
  var BookValue="";
  var VolumeValue="";
  var  DivitionValue="";

  const selectBook = (value) =>{
    setPickedVolumeValue(null);
    setPickedDvValue(null);
    setPickedPageValue(null);
    //console.log(value);
    setPickedBookValue(value); 
    BookValue=value.toString();
    var bookname='';
    if(value ==='B'){bookname='BLR'}
    if(value ===1){bookname= 'ADC'}
    if(value ===8){bookname='ALR'}
    if(value ===2){bookname='BLC'}
    if(value ===3){bookname='BLD'}
    if(value ===4){bookname='BLT'}
    if(value ===9){bookname='CLR'}
    if(value ===5){bookname='DLR'}
    if(value ===6){bookname='MLR'}
    if(value ===7){bookname='SCOB'}
    setPickedBookNmae(bookname); 
   
    const VData = {   
      id:value
    };
    if(value === 'B'){     
      setVolumeNo(Volume_no_list); }
    else{fetchVolumeData(VData);} 
  };

  const selectVolume = (value) =>{  
    setPickedDvValue(null);
    setPickedPageValue(null);
    setPickedVolumeValue(value); VolumeValue=value;  
    if(pickedBookValue===1){ setDVNo([{ value: 2, label: 'AD' }]); setPickedPageValue(1); }
  else{setDVNo(DVList);}};

  const   selectDV = (value) => {
      //  console.log(data);
      var DVname=""
      if(value ===2){DVname='AD'}
      if(value ===1){DVname='HD'}
      setPickedDvValue(value);
      setPickedDvlabel(DVname);
    const pageData = {
      jud_no:pickedVolumeValue,
      cd_id:value
    };   
    fetchPageData(pageData);
   
      };
  const dropDownRef = React.useRef();
 // const closeDropdown = () => {setOpen(false)};
 

  return (<>
   
<View style={styles.container}>
<SafeAreaView style={styles.headerFixed }>
  <View style={{flex:2}}>
<View  style={{ flexDirection: 'row',  marginLeft:5, justifyContent:'flex-start',Left:0, paddingBottom:5, paddingTop:7, backgroundColor:"#fff", }} >
                <Text style={{fontSize:20, fontWeight:'bold', color:'red'}}>Total Found: {total_case.length >0? totalrow:isSubmitting?0:null }</Text></View>
                </View>
              <View style={{ flex:1, flexDirection: 'row', marginLeft:0,  justifyContent:'flex-end', paddingBottom:5, backgroundColor:"#fff" }}>
              
          
                
               <TouchableOpacity >
              {/* <Button type="reset"  title='Clear' onClick={ e => formikElement.current.handleReset()}></Button> */}
                  <View style={{ marginTop: 5,  paddingHorizontal: 5 }}>
                    <AppBtn
                      title="Clear"
                     // onPress={ resetForm }
                     onPress={() => { formikElement.current.resetForm(); resetSearchData();  }}
                     // disabled={isSubmitting ? true: false}
                      BGcolor="#fd397a"
                      
                    />
                  </View>
                  </TouchableOpacity>
                
                
                   
                  <TouchableOpacity   onPress={() => { formikElement.current.handleSubmit(); Keyboard.dismiss() }}>
                  <View style={{ marginTop: 5,  marginRight:8, paddingHorizontal: 5 }}>
                    <AppBtn
                      title="Search"
                      onPress={() => {formikElement.current.handleSubmit();  scrollHandler();}}
                     // disabled={isSubmitting ? true: false}
                      BGcolor="#5cb85c"
                      Tcolor="#00f"
                    />
                  </View>
                  </TouchableOpacity>
                
                 
                </View>
                </SafeAreaView>
       
     
<ScrollView onScrollBeginDrag={() => setTopsCrollShow(true) }  nestedScrollEnabled={true} keyboardShouldPersistTaps={"always"} ref={(ref) => {
            setRef(ref);
          }}>


    <View >   

      <ContainerFluid>
     
      <KeyboardAvoidingView enabled>
          <View style={styles.basicInfoWrapper}>

            {error && <ErrorMessage>{error}</ErrorMessage>}
            
            <Formik
              enableReinitialize={true}
               initialValues={{ ...user }}
             // validationSchema={basicInfoSchema}
              onSubmit={(values, actions) => {
                handleSubmitPress(values, actions);               
                
              }}
            //   onReset={(values, actions) => {
            //   alert('reset10');     
            // values={
            //   section:'',
            // }
            //  // resetForm();
            //   }}
             
          // onReset={(values, { resetForm }) =>{ resetForm(); values={};}}
              innerRef={formikElement}
            >
              {({actions, values, errors, touched, handleChange, setFieldValue,setValues,  handleBlur, handleSubmit,resetForm,handleReset  }) => (
                <>
             
              <View style={{ flexDirection: 'row', justifyContent:'flex-end', paddingBottom:10,  backgroundColor:"#fff" }}>
              
                  {isEditing ? (
                
               <TouchableOpacity >
              {/* <Button type="reset"  title='Clear' onClick={ e => formikElement.current.handleReset()}></Button> */}
                  <View style={{ marginTop: 0, paddingHorizontal: 5 }}>
                    <AppBtn
                      title="Clear"
                     // onPress={ resetForm }
                     onPress={() => { resetForm(); resetSearchData();  }}
                      //disabled={isSubmitting ? true: false}
                      BGcolor="#fd397a"
                      
                    />
                  </View>
                  </TouchableOpacity>
                
                  ):null }
                    {isEditing ? (
                  <TouchableOpacity   onPress={() => { handleSubmit; Keyboard.dismiss() }}>
                  <View style={{ marginTop: 0, paddingHorizontal: 5 }}>
                    <AppBtn
                      title="Search"
                      onPress={handleSubmit}
                      disabled={isSubmitting ? true: false}
                      BGcolor="#5cb85c"
                      Tcolor="#00f"
                    />
                  </View>
                  </TouchableOpacity>
                  ):null }
                 
                </View>
                
                <View  style={{ paddingVertical:12, width:responsiveWidth(99), backgroundColor:"#ccc" }}>
                  <FormGroup>
                    <InputLevel>Court Division :</InputLevel>
                    
                     
                     <View style={{flexDirection:'row', flex:1}}>                   
                      <DropDownFormik
                       zIndex={9999999}
                       zIndexInverse={9999987}
                        listMode="MODAL"
                        modalContentContainerStyle={{
                          // minHeight:responsiveHeight(50),
                           height:500, 
                           
                         }}
                        open={courtDivisionOpen}
                        setOpen={setCourtDivisionOpen}
                      // searchable={true}
                        name="court_division_id"
                        placeholder="Select Court Division"
                        items={courtDivision}
                       
                      />
                   </View>
                    {touched.court_division_id && errors.court_division_id ? (
                      <ErrorText>{errors.court_division_id}</ErrorText>
                    ) : null}
                  </FormGroup> 
                  <FormGroup>
                    <InputLevel>Hon'ble Justice:</InputLevel>
                    {isEditing ? (
                     <View style={{flexDirection:'row',  flex: 1,}}>
                      <DropDownFormik
                      dropDownContainerStyle={{
                       // minHeight:responsiveHeight(50),
                        height:500, overflow:'scroll'
                        
                      }}
                      modalContentContainerStyle={{
                        // minHeight:responsiveHeight(50),
                         height:500, 
                         
                       }}
                     
                       zIndex={9999998}
                       zIndexInverse={9999988}
                        listMode="MODAL"
                       
                        open={justicesNameOpen}
                        setOpen={setJusticesNameOpen}
                       searchable={true}
                       searchPlaceholder="Search Hon'ble Justice here..."
                        name="justice_id"
                        placeholder="Select Hon'ble Justice Name"
                        items={JusticesName}
                      />

                    
                   </View> ):(
                      <Text> </Text>
                    )}
                    {touched.justice_id && errors.justice_id ? (
                      <ErrorText>{errors.justice_id}</ErrorText>
                    ) : null}
                  </FormGroup> 
                  <FormGroup>
                    <InputLevel>Advocate Name :</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                      value={values.lawyer}
                      onChangeText={handleChange("lawyer")}
                      onBlur={handleBlur("lawyer")}
                      placeholder={"Learned Advocate Name"}
                      isEditing={isEditing}
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.lawyer && errors.lawyer ? (
                      <ErrorText>{errors.lawyer}</ErrorText>
                    ) : null}
                  </FormGroup> 
                 
                  <FormGroup>
                    <InputLevel>Case Type:</InputLevel>
                    {isEditing ? (
                     <View style={{flexDirection:'row',  flex: 1,}}>
                       <DropDownFormik
                       zIndex={9999997}
                      // zIndexInverse={9999999}
                      dropDownDirection="TOP"
                      dropDownContainerStyle={{
                        maxHeight:responsiveHeight(20)
                      }}
                      modalContentContainerStyle={{
                        // minHeight:responsiveHeight(50),
                         height:500, 
                         
                       }}
                        listMode="MODAL"
                        open={caseTypeNameOpen}
                        setOpen={setCaseTypeNameOpen}
                       searchable={true}
                       searchPlaceholder="Search Select Case Type here..."
                        name="case_type_id"
                        placeholder="Select Case Type"
                        items={CaseTypeName}
                      />
                    
                   </View> ):(
                      <Text> </Text>
                    )}
                    {touched.case_type && errors.case_type ? (
                      <ErrorText>{errors.case_type}</ErrorText>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <InputLevel>Case No.:</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     value={values.caseno}
                      onChangeText={handleChange("caseno")}
                      onBlur={handleBlur("caseno")}
                      placeholder={"No./Year"}
                      isEditing={isEditing}
                      keyboardType="numeric"
                     
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.caseno && errors.caseno ? (
                      <ErrorText>{errors.caseno}</ErrorText>
                    ) : null}
                  </FormGroup>  
                  <FormGroup>
                    <InputLevel>Section:</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     value={values.section}
                      onChangeText={handleChange("section")}
                      onBlur={handleBlur("section")}
                      placeholder={"section"}
                      isEditing={isEditing}
                     
                      //keyboardType="numeric"
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.section && errors.section ? (
                      <ErrorText>{errors.section}</ErrorText>
                    ) : null}
                  </FormGroup> 
                  <FormGroup>
                    <InputLevel>Act:</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     value={values.act}
                      onChangeText={handleChange("act")}
                      onBlur={handleBlur("act")}
                      placeholder={"Digit Only"}
                      isEditing={isEditing}
                      //keyboardType="numeric"
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.act && errors.act ? (
                      <ErrorText>{errors.act}</ErrorText>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <InputLevel>Article:</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     value={values.article}
                      onChangeText={handleChange("article")}
                      onBlur={handleBlur("article")}
                      placeholder={"Digit Only"}
                      isEditing={isEditing}
                      //keyboardType="numeric"
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.article && errors.article ? (
                      <ErrorText>{errors.article}</ErrorText>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <InputLevel>Oder:</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     value={values.jud_order}
                      onChangeText={handleChange("jud_order")}
                      onBlur={handleBlur("jud_order")}
                      placeholder={"Digit Only"}
                      isEditing={isEditing}
//keyboardType="numeric"
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.jud_order && errors.jud_order ? (
                      <ErrorText>{errors.jud_order}</ErrorText>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <InputLevel>Rule:</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     value={values.jud_rule}
                      onChangeText={handleChange("jud_rule")}
                      onBlur={handleBlur("jud_rule")}
                      placeholder={"Digit Only"}
                      isEditing={isEditing}
                     // keyboardType="numeric"
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.jud_rule && errors.jud_rule ? (
                      <ErrorText>{errors.jud_rule}</ErrorText>
                    ) : null}
                  </FormGroup>

                  <FormGroup>
                  <InputLevel>Book Ref.:</InputLevel>
                    <View  style={{ marginLeft:0, flexDirection: 'row' }}>
                    
                    {isEditing ? (
                     <View>
                      <DropDownFormikBook
                       zIndex={9999988}
                       zIndexInverse={9999999}
                        listMode="SCROLLVIEW"
                        open={bookItemsOpen}
                        setOpen={setBookItemsOpen}
                        dropDownContainerStyle={{
                          minHeight:responsiveHeight(55),
                          width:90,
                          lineHeight:15
                        }}
                       style={{ backgroundColor: pickedBookValue === null? "#fff":"#fced1a"}}
                      // searchable={true}
                      value={pickedBookValue}
                   setValue={(val) => {
                    selectBook(val());
                                      }}
                   
                        name="book_id"
                        placeholder="Name"
                        items={RefBookList}
                      />
                    
                   </View> ):(
                      <Text> </Text>
                    )}
                    {touched.book_id && errors.book_id ? (
                      <ErrorText>{errors.book_id}</ErrorText>
                    ) : null}

                  {isEditing ? (
                     <View>
                      <DropDownFormikBook
                       zIndex={9999988}
                       zIndexInverse={9999999}
                        listMode="SCROLLVIEW"
                        open={voliumeItemsOpen}
                        setOpen={setVoliumeItemsOpen}
                      // searchable={true}
                     // setValue={setParaValue}
                     style={{ backgroundColor: pickedVolumeValue === null? "#fff":"#fced1a"}}
                     // searchable={true}
                     value={pickedVolumeValue}
                  setValue={(val) => {
                    selectVolume(val());
                                     }}
                  
                    name="Volume_id"
                        placeholder="Volume"
                        items={Volume_no}
                      />
                   
                   </View> ):(
                      <Text> </Text>
                    )}
                    {touched.court_division_id && errors.court_division_id ? (
                      <ErrorText>{errors.court_division_id}</ErrorText>
                    ) : null}

                    {isEditing ? (
                     <View>
                      <DropDownFormikBook
                       zIndex={9999988}
                       zIndexInverse={9999999}
                        listMode="SCROLLVIEW"
                        
                        open={dvItemsOpen}
                        setOpen={setDvItemsOpen}
                      // searchable={true}
                      style={{ backgroundColor: pickedDvValue === null? "#fff":"#fced1a"}}
                      // searchable={true}
                      value={pickedDvValue}
                   setValue={(val) => {
                    selectDV(val()); 
                                      }}
                   
                        name="division_id"
                        placeholder="Division"
                        items={DV_no?DV_no:null}
                      />
                    
                   </View> ):(
                      <Text> </Text>
                    )}
                    {touched.division_id && errors.division_id ? (
                      <ErrorText>{errors.division_id}</ErrorText>
                    ) : null}

                    {isEditing ? (
                    
                     <View style={{ width:responsiveWidth(15)}}>
                      { pickedBookValue==="B"?(<DropDownFormikBook
                       zIndex={9999988}
                       zIndexInverse={9999999}
                        listMode="SCROLLVIEW"
                        open={pageItemsOpen}
                        setOpen={setPageItemsOpen}

                      // searchable={true}
                       style={{ backgroundColor: pickedPageValue === null? "#fff":"#fced1a"}}
                      // searchable={true}
                      value={pickedPageValue}
                   setValue={(val) => {
                    setPickedPageValue(val()); 
                                      }}
                     // setValue={ setPickedDvValue(value)}                    
                        name="page_id"
                        placeholder="Page"
                        items={PagesLists}
                      />): <BasicFormInputref
                      value={values.page_id}
                     // onTouchStart={pickedBook.key==="B"?onShowPage:""}
                     // showSoftInputOnFocus={false}
                      onChangeText={handleChange("page_id")}
                      onBlur={handleBlur("page_id")}
                      placeholder={"Page"}
                      isEditing={isEditing}
                     keyboardType="numeric"
                    />
                  }
                   </View> ):(
                      <Text> </Text>
                    )}
                    {touched.page_id && errors.page_id ? (
                      <ErrorText>{errors.page_id}</ErrorText>
                    ) : null}

                    </View>
                  </FormGroup> 

                  <FormGroup>
                    <InputLevel>Important Para:</InputLevel>
                    {isEditing ? (
                     <View style={{flexDirection:'row',  flex: 1,}}>
                      <DropDownFormik
                       zIndex={9999980}
                       zIndexInverse={99999998}
                        listMode="SCROLLVIEW"
                        open={paraItemsOpen}
                        setOpen={setParaItemsOpen}
                        dropDownDirection="TOP"
                      // searchable={true}
                     // setValue={setParaValue}                    
                        name="summeryBookChecked"
                        placeholder="Select Important Para"
                        items={ParaItems}
                      />
                    
                   </View> ):(
                      <Text> </Text>
                    )}
                    {touched.summeryBookChecked && errors.summeryBookChecked ? (
                      <ErrorText>{errors.summeryBookChecked}</ErrorText>
                    ) : null}
                  </FormGroup> 
                  <FormGroup>
                    <InputLevel>Free Text:</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     value={values.anykey}
                      onChangeText={handleChange("anykey")}
                      onBlur={handleBlur("anykey")}
                      placeholder={"Any Word, Matter, Section, Sentence or any Reference"}
                      numberOfLines={2}
                      isEditing={isEditing}
                    /> ):(
                      <Text></Text>
                    )}
                    {touched.anykey && errors.anykey ? (
                      <ErrorText>{errors.anykey}</ErrorText>
                    ) : null}
                  </FormGroup>

                 {showMoreOne? <FormGroup>
                    <InputLevel>More Search:</InputLevel>
                    <View style={{flexDirection:'row'}}>
                      <View style={{}}>
                    <BasicFormInput
                     value={values.MoreOne}
                      onChangeText={handleChange("MoreOne")}
                      onBlur={handleBlur("MoreOne")}
                      placeholder={"More Search"}
                      isEditing={isEditing}
                    /></View>
                    <View style={{marginLeft:-30}}>
                     <TouchableOpacity  onPress={() =>{closeMoreSearch1(); closeMoreSearch1on(handleChange("MoreOne"))}}>
               <FontAwesome     name="times-circle"  size={30} color='#FF0000'   ></FontAwesome></TouchableOpacity>
               </View>
               </View>
                    {/* <View><Text><Icon name="minuscircle" size={24} color="red" /></Text></View> */}
                    {touched.MoreOne && errors.MoreOne ? (
                      <ErrorText>{errors.MoreOne}</ErrorText>
                    ) : null}
                  </FormGroup>:null

                 }
                  {showMoreTwo? <FormGroup>
                    <InputLevel></InputLevel>
                    <View style={{flexDirection:'row'}}>
                      <View >
                    <BasicFormInput
                     value={values.MoreTwo}
                      onChangeText={handleChange("MoreTwo")}
                      onBlur={handleBlur("MoreTwo")}
                      placeholder={"More Search"}
                      isEditing={isEditing}
                    /></View>
                    <View style={{ marginLeft:-30}}>
                     <TouchableOpacity  onPress={() =>{closeMoreSearch2(); closeMoreSearch2on(handleChange("MoreTwo"))}}>
               <FontAwesome     name="times-circle"  size={30} color='#FF0000'   ></FontAwesome></TouchableOpacity>
               </View>
               </View>
                    {/* <View><Text><Icon name="minuscircle" size={24} color="red" /></Text></View> */}
                    {touched.MoreTwo && errors.MoreTwo ? (
                      <ErrorText>{errors.MoreTwo}</ErrorText>
                    ) : null}
                  </FormGroup>:null

                 }




                 {/* submit button  */}
                 <View style={{ flexDirection: 'row', justifyContent:'space-between', paddingHorizontal:15, marginTop:-15  }}>                
                 
                  <View style={{ marginTop: 20, paddingHorizontal: 3 }}>
                    {/* <AppBtn
                      title="More Search"
                     onPress={AddMoreSearch}
                     // disabled={isSubmitting ? true: false}
                      BGcolor="#ffb822"
                    /> */}
                     <TouchableOpacity
        onPress={() => {
          AddMoreSearch();
        }}
   ><Button
                        title="More Search"
                        color="#ffb822"
                       // onPress={() => AddMoreSearch()}
                      /></TouchableOpacity>
                  </View>
                  
                  {isEditing ? (
                  
                  <TouchableOpacity >
                 {/* <Button type="reset"  title='Clear' onClick={ e => formikElement.current.handleReset()}></Button> */}
                     <View style={{ marginTop: 20, paddingHorizontal: 3 }}>
                       <AppBtn
                         title="Clear"
                        // onPress={ resetForm }
                        onPress={() => { resetForm(); resetSearchData();  }}
                        // disabled={isSubmitting ? true: false}
                         BGcolor="#fd397a"
                         
                       />
                     </View>
                     </TouchableOpacity>
                   
                     ):null }
                    {isEditing ? (
                 
                  <View style={{ marginTop: 20, paddingHorizontal: 3 }}>
                    <AppBtn
                      title="Search"
                     // onPress={handleSubmit}
                     onPress={() => {formikElement.current.handleSubmit();   scrollHandler();    Keyboard.dismiss(); }}
                    
                      //disabled={isSubmitting ? true: false}
                      BGcolor="#5cb85c"
                      Tcolor="#00f"
                    />
                  </View>
                 
                  ):null }
                </View>
                </View>
                </>
              )}
            </Formik>
           
          </View>
        </KeyboardAvoidingView>
     
    </ContainerFluid>
   { isSubmitting?
<View style={{marginTop: -40 }}>
  <Text style={{color:'#5BB85D', textAlign:'center', fontWeight:'bold', padding:2, paddingHorizontal:10, backgroundColor:'#fff' , marginHorizontal:10, borderColor:'#000', borderWidth:1, borderRadius:7  }}>If you didn't get the desired result, please try to search again using nearest or less words.</Text>
</View>:null}
    </View> 
 
    <SafeAreaView style={styles.container}>
                  
        {/* {loading == true &&
          <View style={{ flex: 1, justifyContent: "center", alignItems: "center", marginTop:-300 }}>
            <ActivityIndicator animating size="large" color="#ddf3a9" />
          </View>
        } */}
  <View  key={1}
             
             onLayout={(event) => {
               const layout = event.nativeEvent.layout;
               dataSourceCords[1] = layout.y;
               setDataSourceCords(dataSourceCords);
              
             }}></View>
        {loading == true &&
            <SafeAreaView style={styles.main_loader}>
              <View 
              
              style={{ flex: 1, backgroundColor:'#dcf3f2', justifyContent: "center", alignItems: "center", paddingTop:200,paddingBottom:300 }}>
                <Image
                    source={require('../loader/loop-loading.gif')}
                    style={{
                      width: '120%',
                      height: 150,
                      resizeMode: 'contain',
                      margin: 0,
                    }}
                  />
              </View>
            </SafeAreaView>
          }
     

          {nullbody  == false &&       
        

             <View style={  (!endLoader &&  total_case.length >0)? styles.main_containers : styles.main_container }  >
               
             {total_case.length >0 &&   
             
             <View  style={styles.main_body}>

            
              
               {/* <Text style={styles.TotalCaseFound}>Total Example Case Found: {total_case.length}</Text> */}
      <ScrollView horizontal={true}>
     
                <FlatList  data={total_case}
                      keyExtractor={(item, index) => index.toString()}
                      showsVerticalScrollIndicator={false}
                      bounces={false}
                      numColumns={1}  renderItem={({ item, index}) => (
                    <View  key={item.key} style={{width:responsiveWidth(94.5)}}>
                       

                        <Pressable  onPress={() => {  
                      //  navigation.navigate('HomeScreenStack', {
                      //   screen: 'FullJudgment',
                      //   params: {itemId: item.Id },
                      // });
                           navigation.navigate('FullJudgment', {itemId: item.Id});   
                     
                      } } >
                        <View style={styles.hddata} >
                          {/* <View style={{flexDirection:"row"}}>
                        <TouchableOpacity   style={styles.viewButton}  onPress={() => { navigation.navigate("TotalCaseScreenStack", {   screen: "CaseDetails",  params: { caseId: item.case_table_id, chamber_id:chamber_id },  })}}>
                         <Text style={ styles.viewButtonText } >View</Text>
                        </TouchableOpacity>                        
                        </View> */}
                         <View style={{ flexDirection: "row", padding: 0 }}>
                            <Text style={styles.textTile}></Text>
                            <Text style={styles.textTilecln}></Text>
                            <Text style={styles.textDescription}>{item.division_name}</Text>
                           
                          </View>
                          {/* {pickedJusticesName.key>0?<>
                         
                          <View style={{ color:'#006699', backgroundColor:'#006699', width:'99%', flexDirection: "row", padding: 0, marginBottom:15 }}>
                       
                          <HTML
                         source = {{
                          html: `${ decode(item.Id?judgNameView(item.Id):"")}`
                            } }
                            tagsStyles={tagsStyles}
                          />   
                          </View >
                          </>:<View></View>} */}

                          <View style={{ flexDirection: "row", padding: 0 }}>
                            <Text style={styles.textTile}>Case No.</Text>
                            <Text style={styles.textTilecln}>:</Text>
                            <Text style={styles.textDescription}>{item.caseno}</Text>
                          </View>
                          
                          <View style={{ flexDirection: "row", padding: 0 }}>
                            <Text style={styles.textTile}>Judgment Date</Text>
                            <Text style={styles.textTilecln}>:</Text>
                            <Text style={styles.textDescription}>{item.judgment_date}</Text>
                          </View> 

                          {searchEntity.lawyer?<>
                         
                          <View style={{ color:'#006699', backgroundColor:'#006699', width:'99%', flexDirection: "row", padding: 0, marginBottom:15 }}>
                       
                          <HTML
                         source = {{
                          html: `${ decode(item.lawyer)}`
                            } }
                            tagsStyles={tagsStyles}
                          />   
                          </View >
                          </>:<View style={{margin:0,padding:0 }}></View>}

                          <View style={{ flexDirection: "row", padding: 0 }}>
                            <Text style={styles.textTile}>Reference</Text>
                            <Text style={styles.textTilecln}>:</Text>
                           
                          </View> 
                          <View style={{ color:'#fff', backgroundColor:'#006699', width:'99%', flexDirection: "row", padding: 0, }}>                      
                              <Text style={[tagsStyles.p,{marginBottom:0 }]}><HighlightText
                               highlightStyle={{ backgroundColor: '#ffb822' }}
                               // style={{ color: '#fff' }}    
                              // textComponent={CustomText}
                               //highlightComponent={CustomHighlight}                            
                                searchWords={searchWordArray}
                               // textToHighlight={item.full_judgment?.map(Newjudgmentarray).filter(x => typeof x === 'string' && x.length > 0).join('\n\n').toString().trim()}
                               textToHighlight={item.full_judgment?.toString().trim()}
                              /></Text>
                          </View >
                    {ParaValue===2?<>
                          <View style={{ flexDirection: "row", padding: 0 }}>
                         { item.summery?<Text style={styles.textTileImp}>According to BLR, these para/paras are more important for this judgment.:</Text>:<Text></Text>}
                            
                           
                          </View> 
                          <View style={{ color:'#006699', backgroundColor:'#006699', width:'99%', flexDirection: "row", padding: 0, marginBottom:15 }}>
                       
                          <HTML
                         source = {{
                          html: `${ decode(item.summery?.map(judgmentImPara).toString())}`
                            } }
                            tagsStyles={tagsStyles}
                          />   
                          </View >
                          </>:<View style={{margin:0,padding:0 }}></View>}
                          <View style={{   flexWrap: 'wrap',  alignContent:"flex-start",    flexDirection: 'row' }}> 
                          <View style={{   marginTop: 5, paddingHorizontal: 2,  }}>                          
                          <Text  style={{   padding:3,   borderRadius:6,   backgroundColor: "#5cb85c", maxWidth:175, color:"#fff",  fontSize: 12, fontWeight:'bold', textAlign: "center",   }} >BLR {item.jud_year}{item.division_name === "Appellate Division"?" AD ":" HD "} {item.jud_no }</Text>
                         
                                                
                          </View> 
                          {item.book_ref?pickedBook.key==="B"?null:item.book_ref.map(bookref):null} 
                          </View>
                      </View>
                      </Pressable>    
                      </View>
                     )}


                      removeClippedSubviews={true}
                     // initialNumToRender={20}
                     // maxToRenderPerBatch={20}
                      onEndReachedThreshold={0.1}
                      onEndReached={() =>{ endLoader?fetchMore():null }}
                     // refreshing={refreshing}
                      //updateCellsBatchingPeriod={20}
                     
                      initialNumToRender={2} // Reduce initial render amount
                      maxToRenderPerBatch={1} // Reduce number in each render batch
                      updateCellsBatchingPeriod={100} // Increase time between renders
                      windowSize={7} // Reduce the window size
                      ListFooterComponent={() => endLoader ? ListFooterComponent : endFooterComponent}
                     // windowSize={10}
                    />  

                   
</ScrollView>
               
            </View>
             }


                        {/* { !endLoader &&  total_case.length >0 && (() => {
                            return ( 
                          <View style={styles.main_body_two}> 
                              <Text style={{textAlign:"center", fontSize: 20}}>
                                  End.
                              </Text>     
                            </View>   
                                            
                        )})()
                        } */}
                    {/* { total_case.length == 0 && (() => {
                              return ( 
                                <View style={styles.main_body_three}> 
                                <Text style={{textAlign:"center", color:"#f00", fontWeight:"bold", fontSize: 20}}>
                                Judgement not found.
                                </Text>     
                              </View>
                                              
                          )})()
                          } */}

         

        

          </View>


          }

        </SafeAreaView> 
  </ScrollView>
  {topsCrollShow? <View  style={styles.btnClickContain}><TouchableOpacity
                      onPress={scrollToTop}
                     >
                      
                          <FontAwesome     name="arrow-up"  size={30} color='#FFf'   ></FontAwesome>
                       
                        
                      
                    </TouchableOpacity></View>:null }
</View></>
    );
};
 
export default HomeScreen;


async function getChamberId() {
  try {
    const jsonValue = await AsyncStorage.getItem("chamber_id")
    if(jsonValue !== null){
      //console.log("chamber_id: "+JSON.parse(jsonValue));
      return JSON.parse(jsonValue); 
    }
  } catch(err) {
    console.error(err);
  }
};

const tagsStyles = StyleSheet.create( {
  body:{
    color:'#006699'
  },
  h1: {
    color: '#000',
    
    marginBottom: 13,
    backgroundColor: '#ff0'
  },
  img: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20
  },
  p: {
    //textAlign: 'justify',
    textAlign: "left",
    flex: 1,
    fontSize: 16, 
    color:'#fff',
    width:responsiveWidth(94.4),
  // lineHeight: 20,
 // marginBottom: -hp('9%'),
   backgroundColor:'#006699',
   //marginBottom:-10,
  },
  b: {
    textAlign: "justify",
    fontSize: 12, color:'#000',
   // width:responsiveWidth(92),
   backgroundColor:'#ff0'
  },
  ul: { fontSize: 12, color:'#333111', textAlign:"justify" },                              
  li:{textAlign:"justify"},
  span:{textAlign: "justify",
  fontSize: 16, color:'#fff',
 // width:responsiveWidth(92),
 lineHeight: 20,
 backgroundColor:'#006699',
 }
});

const styles = StyleSheet.create({
   btnClickContain: {
    //flex: 1,
    //position: 'absolute',
    width: 50,
    height: 50,
    alignSelf:'flex-end',
    alignItems: 'center',
    justifyContent: 'center',
    right: 10,
    bottom: 10,
    marginTop: -50,
    borderRadius: 10,
   // marginRight:100,
  // marginBottom: 100,
   backgroundColor:'#05eff7',
   opacity: 0.57,
    zIndex:99999999,
  },
  btnContainer: {
    //flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
    alignSelf: 'stretch',
    borderRadius: 10,
  },

  headerFixed: {
    top: 0,
    left: 0,
    flex:0,
    zIndex: 2,
    width:'100%', 
    position: 'absolute',
    backgroundColor: '#fff',
    flexDirection:'row'
    // paddingBottom:10,
  },

   // Use
   viewButton: {
    backgroundColor: '#0040ff',
   width:80,
    borderRadius:13,
    padding:2,
    alignItems:"center",
    marginLeft:50,
   },
   materButton: {
    backgroundColor: '#00bc77',
   width:150,
    borderRadius:13,
    padding:2,
    alignItems:"center",
    marginLeft:50,
   },
   viewButtonText: {
    color: '#fff',
    fontSize: 18,
    textTransform: "capitalize",
    textAlign: "center",
   },
   dropdown4BtnStyle: {
    width: 140,
    height: 32,
    backgroundColor: '#307ecc',
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#f00',
  },

  dropdown4BtnTxtStyle: {color: '#fff', textAlign: 'center'},
  dropdown4DropdownStyle: {backgroundColor: '#f00'},
  dropdown4RowStyle: {backgroundColor: '#307ecc', borderBottomColor: '#dadae8'},
  dropdown4RowTxtStyle: {color: '#white', textAlign: 'left'},


  SectionStyle: {
    flexDirection: 'row',
    height: 32,
    margin: 2,
    width:85,
  },

  SectionStyleYear: {
    flexDirection: 'row',
    height: 32,
    margin: 2,
    width:150,
  },

  SectionStyleStatus: {
    flexDirection: 'row',
    height: 32,
    margin: 1,
    width:150,
  },

  inputStyle: {
    flex: 1,
    backgroundColor: '#307ecc',
    color: 'white',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: '#f00',
    fontWeight:'bold',
    textAlign: 'center',
  },
  
  buttonStyle: {
    backgroundColor: '#f00',
    borderWidth: 0,
    color: '#f00',
    borderColor: '#f00',
    height: 35,
    alignItems: 'center',
    borderRadius: 30,
    width:70,
  },


  buttonStyleSearch: {
    backgroundColor: '#f00',
    borderWidth: 0,
    color: '#f00',
    borderColor: '#f00',
    height: 35,
    alignItems: 'center',
    borderRadius: 30,
    width:80,
  },

  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 6,
    fontSize: 16,
  },

  registerTextStyle: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'center',
    padding: 10,
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },
  titleText:{
    fontSize: 30,
    alignItems: 'center',
    justifyContent: 'center',
    color : "#fff",
  },
  
  form: {
    backgroundColor: "#ccc",
    flex: 1,
    alignItems: "center", // ignore this - we'll come back to it
    justifyContent: "center", // ignore this - we'll come back to it
    flexDirection: "row",
  },

  listFooter: {
    paddingVertical: 1,
    marginTop:2,
    marginBottom:30,

    
    // borderTopWidth: 1,
    // borderColor: "#CED0CE"
  },


  container: {
    marginTop:0,
    flex: 1,
    alignItems: "center",
    // justifyContent: "center",
    //backgroundColor: "#0373BB",
    backgroundColor: "#fff",
  },
  indicator: {
    backgroundColor: 'black',
    borderRadius: 3,
    width: 6,
  },
  details: {
    padding: 5,
    borderWidth: .5,
    borderColor: "red",
    borderRadius: 6,
    // width: 300,
    marginTop:10,
    marginLeft:20
  },

  main_container:{
    borderRadius: 4,
    width:responsiveWidth(100),
    // borderWidth: 4,
    borderLeftWidth:4,
    borderRightWidth:4,
    borderBottomWidth:4,
    borderColor: "#ccc",
    height:'100%',

  },

  main_containers:{
    borderRadius: 4,
    width:responsiveWidth(100),
    // borderWidth: 4,
    borderLeftWidth:4,
    borderRightWidth:4,
    borderBottomWidth:4,
    borderColor: "#ccc",
    height:'100%',
    paddingBottom:67,
    color:"#fff",
  },

    
  main_loader:{
    width:responsiveWidth(100),
  },

  main_body: {
    padding: 5,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 6,
    marginBottom:0,
    backgroundColor: "#FFF",
    width:responsiveWidth(98),
  },


  main_body_two: {
    padding: 7,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 6,
    marginTop:3,
    marginBottom:0,
    backgroundColor: "#FFF",
    width:responsiveWidth(95),
  },
  main_body_three: {
    padding: 7,
    borderWidth: 1,
    borderColor: "red",
    borderRadius: 6,
    marginTop:3,
    marginBottom:20,
    backgroundColor: "#FFF",
    width:responsiveWidth(98),
  },
  TotalCaseFound:{
    fontSize: 14,
    textAlign:'center',
    fontWeight:'bold',
    width:responsiveWidth(100),
  },

  textTile:{
    fontSize: 16,
    alignItems: "center",
    justifyContent: "center",
    width:responsiveWidth(28),
    color:"#fff" 
  },
  textTileImp:{
    fontSize: 16,
    alignItems: "center",
    justifyContent: "center",
    width:responsiveWidth(98),
    color:"#fff000" 
  },
  CaseTypeNoTile:{
    fontSize: 16,
    alignItems: "center",
    justifyContent: "center",
    width:99,
    color:"#fff"
  },

  textTilecln:{
    fontSize: 16,
    alignItems: "center",
    justifyContent: "center",
    width:8,
    color:"#fff"
  },

  textDescription:{
    paddingTop:3,
    fontSize: 16,
    width:responsiveWidth(60),
    color:"#fff"
  },
  textDescriptionfull:{
    paddingTop:3,
    fontSize: 16,
    textAlign:"justify",
    width:responsiveWidth(91),
    color:"#000"
  },

  textParties:{
    fontSize: 8,
    paddingTop:8,
  },


  titleText: {
    fontSize: 30,
    alignItems: "center",
    justifyContent: "center",
  },

  LawyerInfo: {
    padding: 5,
    borderWidth: 1,
    borderColor: "white",
    borderRadius: 6,
  },
  LawyerInfoText: {
    fontSize: 15,
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },

  button: {
    alignItems: "center",
    backgroundColor: "#419641",
    width: 120,
    height: 36,
    padding: 3,
    borderWidth: 1,
    borderColor: "white",
    borderRadius: 6,
    marginTop: 10,
    marginLeft:10
  },

  buttonRefresh: {
    alignItems: "center",
    backgroundColor: "#FFFFBD",
    width: 75,
    height: 36,
    paddingTop: 5,
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 3,
    borderWidth: 1,
    borderColor: "red",
    color:"#000",
    borderRadius: 6,
    marginTop: 10,
    marginLeft:10,
  },

  buttonNexDate: {
    alignItems: "center",
    backgroundColor: "#FFFFBD",
    width: 85,
    height: 36,
    paddingTop: 5,
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 3,
    borderWidth: 1,
    borderColor: "red",
    color:"#000",
    borderRadius: 6,
    marginTop: 10,
    marginLeft:10
  },

  buttonTextRefresh: {
    fontSize: 16,
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },

  buttonText: {
    fontSize: 20,
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },
  input: {
    width: 140,
    fontSize: 22,
    height: 36,
    paddingLeft: 10,
    borderWidth: 1,
    marginTop: 10,
    borderColor: "white",
    color: "white",
    marginVertical: 10,
    borderRadius: 6,
  },

  item: {
    backgroundColor: "#f9c2ff",
    padding: 20,
    marginVertical: 8,
  },
  header: {
    fontSize: 32,
    backgroundColor: "#fff",
  },
  title: {
    fontSize: 24,
  },

  SectionHeaderStyle: {
    backgroundColor: "#CDDC89",
    fontSize: 20,
    padding: 5,
    color: "#fff",
  },

  SectionListItemStyle: {
    fontSize: 15,
    padding: 15,
    color: "#000",
    backgroundColor: "#F5F5F5",
  },

  hddata: {
    padding: 5,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 6,
    marginTop:5,
    marginBottom:0,
    backgroundColor: "#006699",
    color:"#fff",
  },

  basicInfoWrapper: {
    flex: 1,
    paddingHorizontal: 5,
    marginTop: 0,
    marginBottom: 30,
    justifyContent: "center",
  },
  hddataText: {
    fontSize: 20,
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },

  hddataResult: {
    padding: 5,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 6,
    marginTop:5,
    marginLeft:7,
    marginRight:7,
    backgroundColor: "#FFFFBD",
  },

  totalfound: {
    fontSize: 14,
    alignItems: "center",
    color: "#fff",
  },



  noItems: {
    fontSize: 24,
    alignItems: "center",
    justifyContent: "center",
    color: "#FFF",
    paddingLeft:90,
    width:300,
    paddingTop:20,
    paddingBottom:20,

    textShadowColor: 'rgba(0, 0, 0,0.9)',
    textShadowOffset: {width: -1, height: 2},
    textShadowRadius: 15
  },

  errorColor: {
    color:'coral',
  },

  isDraft: {
    fontSize: 20,
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    marginLeft:10,
  },

  CaseResultDate: {
    padding: 5,
    borderWidth: 1,
    borderColor: "white",
    borderRadius: 6,
    marginLeft:5,
    marginRight:6,
    backgroundColor: "#fff",
    color: "#000",
    alignItems: "center",
    justifyContent: "center",
  },

  get_date: {
    fontSize: 17,
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },

  last_datetime: {
    fontSize: 14,
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },

  fadingContainer: {
    backgroundColor: "blueviolet",
    borderRadius:4,
    margin: 20,
  },

  fadingText: {
    fontSize: 16,
    textAlign: "center",
    color : "#fff",
    paddingVertical: 5,
    paddingHorizontal: 25,
  },

});