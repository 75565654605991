
import 'react-native-gesture-handler';

// Import React and Component
import React, {useState,useContext,}  from 'react';
import {
 
  Platform ,
  View, 
  StyleSheet,
  Image,
 
} from 'react-native';

// Import Navigators from React Navigation

import {createStackNavigator} from '@react-navigation/stack';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
// Import Screens
import NavigationDrawerHeader from './Components/NavigationDrawerHeader';
import HomeScreen from './DrawerScreens/HomeScreen';
import FullJudgment from '../Screen/DrawerScreens/FullJudgment';

import  DrawerNavigationRoutes from '../Screen/DrawerNavigationRoutes';




import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import TabBar from 'react-native-custom-navigation-tabs';
import Icon from 'react-native-vector-icons/FontAwesome'
// import NavigationDrawerHeader from './Screen/Components/NavigationDrawerHeader';
// import CustomSidebarMenu from './Screen/Components/CustomSidebarMenu';
// import TotalCaseScreen from './Screen/DrawerScreens/TotalCaseScreen';


const Tab = createMaterialBottomTabNavigator();
const Stack = createStackNavigator();


const HomeScreenStack = () => {

  const navigation = useNavigation();

  return (
    <Stack.Navigator initialRouteName="HomeScreen">
      <Stack.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={{
          title: 'Judgment / Reference (BLR)', //Set Header Title
          headerTitleAlign: 'center',
         // headerShown: true,
         
          // headerRight: () => (
          //   <NavigationDrawerHeader navigationProps={navigation} />
          // ),
          headerStyle: {
            //backgroundColor: '#307ecc', //Set Header color
            backgroundColor: '#3c3ce0', //Set Header color
          },
          headerTintColor: '#fff', //Set Header text color
          headerTitleStyle: {
            fontWeight: 'bold', //Set Header text style
          },
        }}
      />

      <Stack.Screen
        name="FullJudgment"
        component={FullJudgment}
        // options={{
        //   title: 'Full Judgment', //Set Header Title
        //   headerTitleAlign: 'center',
        // }}
        options={{
          title: 'Full Judgment', //Set Header Title
          headerTitleAlign: 'center',
         // headerShown: true,
          // headerRight: () => (
          //   <NavigationDrawerHeader navigationProps={navigation} />
          // ),
          headerStyle: {
            //backgroundColor: '#307ecc', //Set Header color
            backgroundColor: '#3c3ce0', //Set Header color
          },
          headerTintColor: '#fff', //Set Header text color
          headerTitleStyle: {
            fontWeight: 'bold', //Set Header text style
          },
        }}

      //  initialParams={{ chamber_id: 18 }}
      />
    </Stack.Navigator>
  );
};


 
const Home = () => {
 
  
 

  //user login status
  
  return (

  
    <Tab.Navigator  initialRouteName="HomeScreenStack"   
    activeColor="#f0edf6"
    inactiveColor="#3e2465"
    barStyle={{ backgroundColor: '#694fad' }}
    shifting={true}
    tabPress={true}
  >
      
        <Tab.Screen
          name="LCMS"
          component={DrawerNavigationRoutes}
          // Hiding header for Navigation Drawer
         // options={{headerShown: false}}
         options={{
          headerShown: true,
          tabBarLabel: 'Home',
          tabBarIcon: ({ color, size }) => (
            <Icon name="home" color={color} size={26} />
          ),
        }}
        />
        {/* <Tab.Screen

        name="FullJudgment"
        component={FullJudgment}
        // Hiding header for Navigation Drawer
        options={{headerShown: false, unmountOnBlur: true }}
      />   */}
           <Tab.Screen

            name="HomeScreenStack"
            component={HomeScreenStack}
            options={{
              headerShown: true,
              tabBarLabel: 'BD Law Reference',
              tabBarIcon: ({ focused }) => (
                <View style={{borderRadius:23}}>{focused ? <Image style={styles.image_show} source={require('../assets/blr.png')}                               
                        />:<Image style={styles.image_show} source={require('../assets/blr.png')} />}</View>
            ),
            }}
           />  
               
          </Tab.Navigator>
          

 


  )
};
const styles = StyleSheet.create({
  image_show: {
height:25, width:25,
borderRadius:5
  },
});
export default Home;
