import * as React from 'react';
import {
    LayoutAnimation,
    StyleSheet,
    View,
    Text,
    ScrollView,
    UIManager,
    TouchableOpacity,
    Platform,
  } from 'react-native';

  import LcmsScreen from '../DrawerScreens/LcmsScreen';
  import HomeScreen from '../DrawerScreens/HomeScreen';
  import CaseResultScreen from '../DrawerScreens/CaseResultScreen';
  import NewCaseEntry from '../DrawerScreens/NewCaseEntry';
  

class ExpandableItemComponent extends React.Component {
    //Custom Component for the Expandable List
    constructor() {
      super();
      this.state = {
        layoutHeight: 0,
        menuStyle:null,
      };
    }
  
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.item.isExpanded) {
        this.setState(() => {
          return {
            layoutHeight: null,
          };
        });
      } else {
        this.setState(() => {
          return {
            layoutHeight: 0,
          };
        });
      }
     
    }
    // componentWillReceiveProps(nextProps) {
    //   if (nextProps.item.isExpanded) {
    //     this.setState(() => {
    //       return {
    //         layoutHeight: null,
    //       };
    //     });
    //   } else {
    //     this.setState(() => {
    //       return {
    //         layoutHeight: 0,
    //       };
    //     });
    //   }
    // }


    selectCat(screenName) {
      if (screenName !==null) {

     
        this.setState(() => {
          return {
            menuStyle: screenName,
          };
         
        });
        
      }
     
    }
  
    render() {
        const context = this;
      return (
        <View>
          {/*Header of the Expandable List Item*/}
          <TouchableOpacity
            activeOpacity={0.8}
            onPress={this.props.onClickFunction}
            style={styles.header}>
            <Text style={styles.headerText}>{this.props.item.category_name}</Text>
          </TouchableOpacity>
          <View
            style={{
              height: this.state.layoutHeight,
              overflow: 'hidden',
            }}>
            {/*Content under the header of the Expandable List Item*/}
            {this.props.item.subcategory.map((item, index) => (
              <TouchableOpacity
                key={item.id}
                style={styles.content}
                onPress={() => { context.props.navObj.navigate(`${item.stackScreen}`, {   screen: `${item.screenName}` });   context.props.styleback(item.id); } }>
                 { (() => {
                    if (context.props.menuStyle === item.id){
                      return (   <Text  style={styles.textB}>
                              {  item.val} 
                      </Text>)
                    }else{
                    return ( 
                      <Text  style={styles.text}>
                      {  item.val}
                      </Text>
                          )         
                   } })()}

                <View style={styles.separator} />
               
              </TouchableOpacity>
            ))}
          </View>
        </View>
      );
    }
  }
export default class ExpandableViewSeparate extends React.Component {
    //Main View defined under this Class
    constructor() {
      super();
      if (Platform.OS === 'android') {
        UIManager.setLayoutAnimationEnabledExperimental(true);
      }
      this.state = { listDataSource: CONTENT,  menuStyle: null, };
    }
  
    updateLayout = index => {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      const array = [...this.state.listDataSource];
      array[index]['isExpanded'] = !array[index]['isExpanded'];
      this.setState(() => {
        return {
          listDataSource: array,
          
        };
      });
     
    };
    
    updateStyle = (data) => {
    
      this.setState(() => {
        return {
          menuStyle:data
          
        };
      });
     
    };

  
    render() {
      return (
        <View style={styles.container}>
         
          <ScrollView>
            {this.state.listDataSource.map((item, key) => (
              <ExpandableItemComponent
                key={item.category_name}
                onClickFunction=  {this.updateLayout.bind(this, key)}
                item={item}
                navObj={this.props.navObj}
                menuStyle= {this.state.menuStyle}
                styleback={this.updateStyle.bind(this.props.styleback)}
              />
            ))}
          </ScrollView>
        </View>
      );
    }
  }
  
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      paddingTop: 30,
      backgroundColor: '#337ab7',
    },
    topHeading: {
      paddingLeft: 10,
      fontSize: 20,
    },
    header: {
      backgroundColor: '#337ab7',
      padding: 16,
    },
    headerText: {
      fontSize: 18,
      fontWeight: '500',
      color: 'white',
    },
    separator: {
   
      height: 0.5,
      backgroundColor: '#ccc',
      width: '95%',
     
    
    
      paddingTop:1,
      paddingBottom:1
    },
    text: {
      fontSize: 16,
      color: 'white',
      padding: 7,
      marginRight:4
      
    },
    textB: {
      fontSize: 16,
      color: 'white',
      padding: 7,
      backgroundColor: '#777eee',
    },
    content: {
      marginLeft:30,
      paddingLeft: 10,
      paddingRight: 10,
     
      backgroundColor: '#331eee',
    },
  });
  
  //Dummy content to show
  //You can also use dynamic data by calling webservice
  const CONTENT = [
    {
      isExpanded: false,
      category_name: 'Dashboard',
      subcategory: [{ id: 1, val: 'Home', stackScreen:'LcmsScreenStack', screenName:'LcmsScreen' }],
    },
    // {
    //   isExpanded: false,
    //   category_name: 'Case',
    //   subcategory: [ { id: 2, val: 'Total Case', stackScreen:'TotalCaseScreenStack', screenName:'TotalCaseScreen' },{ id: 3, val: 'New Case Entry', stackScreen:'NewCaseEntryStack', screenName:'NewCaseEntry' }, { id: 4, val: 'Case Result', stackScreen:'CaseResultScreenStack', screenName:'CaseResultScreen' }, { id: 5, val: 'Case Register & Report', stackScreen:'TotalCaseScreenStack', screenName:'TotalCaseScreen' },{ id: 6, val: 'Case Payment System', stackScreen:'TotalCaseScreenStack', screenName:'TotalCaseScreen' },  ],
    // },
    // {
    //   isExpanded: false,
    //   category_name: 'Example Cases',      
    //   subcategory: [ { id: 21, val: 'Example Cases', stackScreen:'ExampleStack', screenName:'ExampleCases' },{ id: 22, val: 'Example Documents', stackScreen:'ExampleStack', screenName:'ExampleDocument' }  ],
    // },
    // {
    //   isExpanded: false,
    //   category_name: 'Calender',      
    //   subcategory: [ { id: 41, val: 'Home', stackScreen:'CalendarStack', screenName:'Calender' },{ id: 42, val: 'Appointments', stackScreen:'CalendarStack', screenName:'Appointments' } ,{ id: 43, val: 'Task', stackScreen:'CalendarStack', screenName:'Task' }  ],
    // },
    // {
    //   isExpanded: false,
    //   category_name: 'Lawyer Panel',      
    //   subcategory: [ { id: 101, val: 'Add Organizaton', stackScreen:'OrganizationPanelStack', screenName:'AddOrganization' },{ id: 102, val: 'Organization Renewal', stackScreen:'OrganizationPanelStack', screenName:'OrganizationRenewal' }  ,{ id: 103, val: 'Org. Bill Receive', stackScreen:'OrganizationPanelStack', screenName:'OrgBillRecieved' } ,{ id: 104, val: 'Org. Bill Approve', stackScreen:'OrganizationPanelStack', screenName:'OrgBillAproved' } ,{ id: 105, val: ' Org. Bill Payment', stackScreen:'OrganizationPanelStack', screenName:'OrgBillPayment' } ,{ id: 106, val: 'Org. Bill Due', stackScreen:'OrganizationPanelStack', screenName:'OrgBillDue' } ,{ id: 107, val: 'Org Billing Item', stackScreen:'OrganizationPanelStack', screenName:'OrgBillingItems' },{ id: 108, val: 'List of Organizations', stackScreen:'OrganizationPanelStack', screenName:'ListofOrganizationReport' }  ,{ id: 109, val: 'Organization wise transaction reports', stackScreen:'OrganizationPanelStack', screenName:'OrgWiseTransactionReportsPdf' } ,{ id: 110, val: 'Case Wise Org Bill', stackScreen:'OrganizationPanelStack', screenName:'CaseWiseOrgBillReports' }, ,{ id: 111, val: 'Org.Unapproved Bills', stackScreen:'OrganizationPanelStack', screenName:'UnpaidUnapprovedBillsReports' }  ],
    // },
    // {
    //   isExpanded: false,
    //   category_name: 'Organization Panel',      
    //   subcategory: [ { id: 201, val: 'Add Panel Lawyers', stackScreen:'LayerPanelStack', screenName:'AddOrganization' },{ id: 202, val: 'Panel Lawyers Renewal', stackScreen:'LayerPanelStack', screenName:'PanelLawyersRenewal' }  ,{ id: 203, val: 'Bill Receive', stackScreen:'LayerPanelStack', screenName:'LawyerBillRecieved' } ,{ id: 204, val: 'Bill Approve', stackScreen:'LayerPanelStack', screenName:'LawyerBillAproved' } ,{ id: 205, val: 'Bill Payment', stackScreen:'LayerPanelStack', screenName:'LawyerBillPayment' } ,{ id: 206, val: 'Dues Bill', stackScreen:'LayerPanelStack', screenName:'LawyerBillDue' } ,{ id: 207, val: 'Billing Item', stackScreen:'LayerPanelStack', screenName:'LawyerBillingItems' },{ id: 208, val: 'Transaction reports', stackScreen:'LayerPanelStack', screenName:'LawyerWiseTransactionReportsPdf' },{ id: 209, val: 'List of panel Lawyers', stackScreen:'LayerPanelStack', screenName:'ListofLawyerReport' } ,{ id: 210, val: 'Case Wise Lawyers Bill', stackScreen:'LayerPanelStack', screenName:'AddOrganization' } ,{ id: 211, val: 'Unapproved Bills', stackScreen:'LayerPanelStack', screenName:'UnpaidUnapprovedLawyerBillsReports' }  ],
    // },
    // {
    //   isExpanded: false,
    //   category_name: 'Our Team',      
    //   subcategory: [ { id: 61, val: 'Associates', stackScreen:'TeamStack', screenName:'Associates' },{ id: 62, val: 'Employee', stackScreen:'TeamStack', screenName:'Employee' }  ],
    // },
    // {
    //   isExpanded: false,
    //   category_name: 'Settings',      
    //   subcategory: [ { id: 81, val: 'Home', stackScreen:'CalendarStack', screenName:'Calender' },{ id: 82, val: 'Set Red Color Days', stackScreen:'CalendarStack', screenName:'Appointments' }  ,{ id: 83, val: 'Email Option', stackScreen:'SettingsScreenStack', screenName:'EmailSettings' } ,{ id: 84, val: 'Password Change ', stackScreen:'CalendarStack', screenName:'Appointments' } ,{ id: 85, val: 'Create User', stackScreen:'CalendarStack', screenName:'Appointments' } ,{ id: 86, val: 'User Access', stackScreen:'CalendarStack', screenName:'Appointments' } ,{ id: 87, val: 'User Activity', stackScreen:'CalendarStack', screenName:'Appointments' },{ id: 88, val: 'Chamber Information', stackScreen:'CalendarStack', screenName:'Appointments' }  ],
    // },
   
  ];