// Import React and Component
import React, {useState, createRef, useEffect, useRef} from 'react';
import {
  StyleSheet,
  TextInput,
  View,
  Text,
  ScrollView,
  Image,
  Keyboard,
  TouchableOpacity,
  KeyboardAvoidingView,
  Dimensions,
  Linking,
  Button,
  SafeAreaView,
  ActivityIndicator,
  Animated,
  FlatList,
  LogBox 
} from 'react-native';

import {
  responsiveHeight,
  responsiveWidth,
  responsiveFontSize
} from "react-native-responsive-dimensions";


import { BASE_URL } from '../Components/BaseUrl';
import Loader from '../Components/Loader';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

import SelectDropdown from 'react-native-select-dropdown';
import { apiPathLocal } from "../utils/constants/Consts";

import { useFocusEffect } from "@react-navigation/native"; 
import Modal from "react-native-modal";
import DiaryBox from "../shared/updateDiary";
const CaseResultScreen = () => {

  console.log("Case Result 33");

  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(true);
  const [nullbody, setNullbody] = useState(true);
  const [caseNumber, setCaseNumber] = useState('');
  const [case_year, setCase_year] = useState('');
  const [statuses, setStatuses] = useState('');
  const [chamber_id, setChamber_id] = useState('');
  const [endLoader, setEndLoader] = useState(1);
  const [errortext, setErrortext] = useState('');
  const [total_case, setTotal_case] = useState([]);
  const [focusLength, setFocusLength] = useState(true);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalEntry, setModalEntry] = useState();
 // State to store count value
 const [count, setCount] = useState(0);

 const CaseStatus = ["All", "Pending", "Disposed"];

 useFocusEffect(
  React.useCallback(() => {
    //async fetch user data
    setTotal_case([]);
    setFocusLength(false);
    setStatuses(0);
    setCase_year('');
    setCaseNumber('');
  }, [])
);

  useEffect(() => {

    getChamberId().then(chamberId => setChamber_id(chamberId));

    //LogBox.ignoreLogs(['VirtualizedLists should never be nested']);

    //console.log("Case Result");

    return () => {
      setTotal_case([]);
    };

  }, []);


  const handleSubmitPress = () => {

    
    setErrortext('');
    
    /*
    if (!caseNumber) {
      alert('Please fill Case Number');
      return;
    }
    if (!case_year) {
      alert('Please fill Case Year');
      return;
    }*/

    let statusesVal = "";
    if(statuses === undefined || statuses === "")
    {
      statusesVal = 0;
    }
    else
    {
      statusesVal = statuses;
    }

    setLoading(true);
    setNullbody(true);


    //console.log("======="+case_year);

    let dataToSend = {case_number: caseNumber, case_year: case_year, chamber_id: chamber_id, case_status: statusesVal};
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    formBody = formBody.join('&');


    //console.log(formBody);     

      try {

        let url = apiPathLocal + "/TotalCaseResult";
 
         axios.post(url, formBody).then(response => {
             //Hide Loader
            setLoading(false);
            //console.log(response);               

            setTotal_case(response.data);
            setNullbody(false);
            setEndLoader(1);
            setFocusLength(true);


         }).catch((error) => {
          //Hide Loader
          setLoading(false);
          console.error(error);
        });
        
       } 
       catch (err) 
       {
         console.error(err);
       }

  };

  const incrementCount = () => {
    // Update state with incremented value
    setCount(count + 1);
  };

  const onEndReached = () => {
    //console.log('end reached');
    setEndLoader(0);
  }
  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };


  const ListFooterComponent = (
    <View style={styles.listFooter}>
      <ActivityIndicator animating={true} size="large" color="#00ff00" />
    </View>
  )



  return (
    <View style={styles.container}>
      <Modal isVisible={isModalVisible}  
        onSwipeComplete={() => setModalVisible(false)}
        //onBackdropPress={() => setModalVisible(false)}
        swipeDirection={['left', 'right','down','up']}>
    <View>
    
      <DiaryBox item={modalEntry}  closeModal={() => setModalVisible(false)} />
      
    </View>
    </Modal>

      <View style={{ flexDirection: "row", flexWrap: "wrap", marginLeft: 0}}>


      <View style={styles.form}>
        <View style={styles.SectionStyle}>
                <TextInput
                  value={caseNumber}
                  style={styles.inputStyle}
                  onChangeText={(caseNumber) =>
                    setCaseNumber(caseNumber)
                  }
                  placeholder="Case No" 
                  placeholderTextColor="#8b9cb5"
                  autoCapitalize="none"
                  keyboardType="number-pad"
                  returnKeyType="next"
                  underlineColorAndroid="#f000"
                  blurOnSubmit={false}
                />
          </View>
          <View style={styles.SectionStyleYear}>
                <TextInput
                  value={case_year}
                  style={styles.inputStyle}
                  onChangeText={(case_year) =>
                    setCase_year(case_year)
                  }
                  placeholder="Year" 
                  placeholderTextColor="#8b9cb5"
                  autoCapitalize="none"
                  keyboardType="number-pad"
                  returnKeyType="next"
                  underlineColorAndroid="#f000"
                  blurOnSubmit={false}
                />
          </View>
          <View style={styles.SectionStyleStatus}>
                <SelectDropdown
                  data={CaseStatus}
                  defaultValueByIndex={statuses === '' ? 0 : statuses} 
                  onSelect={(selectedItem, index) => {
                    setStatuses(index);
                  }}
                  buttonTextAfterSelection={(selectedItem, index) => {
                  return selectedItem
                  }}
                  rowTextForSelection={(item, index) => {
                    return item
                  }}
                  onChangeText={(statuses) =>
                    setStatuses(statuses)
                  }
                  buttonStyle={styles.dropdown4BtnStyle}
                  buttonTextStyle={styles.dropdown4BtnTxtStyle}
              />
              </View>
          <TouchableOpacity
              style={styles.buttonStyleSearch}
              activeOpacity={0.5}
              onPress={handleSubmitPress}>
              <Text style={styles.buttonTextStyle}>Search</Text>
          </TouchableOpacity> 
      </View>

      </View> 
 
        <SafeAreaView style={styles.container}>
        {/*           
        {loading == true &&
          <View style={{ flex: 1, justifyContent: "center", alignItems: "center", marginTop:-300 }}>
            <ActivityIndicator animating size="large" color="#00ff00" />
          </View>
        } */}

        {loading == true &&
            <SafeAreaView style={styles.main_loader}>
              <View style={{ flex: 1, justifyContent: "center", alignItems: "center", marginTop:200 }}>
                <Image
                    source={require('../loader/loop-loading.gif')}
                    style={{
                      width: '50%',
                      height: 100,
                      resizeMode: 'contain',
                      margin: 0,
                    }}
                  />
              </View>
            </SafeAreaView>
          }
       

          {nullbody  == false &&   
        
        <View style={  (!endLoader &&  total_case.length >0)? styles.main_containers : styles.main_container }  >
               
             {total_case.length >0 &&   
             
             <View style={styles.main_body}>
              
               <Text style={styles.TotalCaseFound}>Total Case Result Found: {total_case.length}</Text>
               <Text style={styles.asperSupremeCourt}>Case Result (As Per Supreme Court Website)</Text>

                <FlatList

                        data={total_case}

                      keyExtractor={(item, index) => index.toString()}
                      showsVerticalScrollIndicator={false}
                      bounces={false}
                      numColumns={1}
                 

                      renderItem={({ item, i }) => (
                        <View key={item.key}>
                        <TouchableOpacity    onPress={() => {  setModalVisible(!isModalVisible);
                                        setModalEntry(item);}}>
                        <View style={styles.hddata} >
                        
                          <View style={{ flexDirection: "row", padding: 0 }}>
                            <Text style={styles.textTile}>Court Division</Text>
                            <Text style={styles.textTilecln}>:</Text>
                            <Text style={styles.textDescription}>{item.court_division_name}</Text>
                          </View>

                          <View style={{ flexDirection: "row", padding: 0 }}>
                            <Text style={styles.textTile}>Order Date</Text>
                            <Text style={styles.textTilecln}>:</Text>
                            <Text style={styles.textDescription}>{item.order_date }</Text>
                          </View>
                          
                          <View style={{ flexDirection: "row", padding: 0 }}>
                            <Text style={styles.textTile}>Case No.</Text>
                            <Text style={styles.textTilecln}>:</Text>
                            <Text style={styles.textDescription}>{item.court_name+" "+item.caseNoYear }</Text>
                          </View>

                          <View style={{ flexDirection: "row", padding: 0 }}>
                            <Text style={styles.textTile}>Upload Time</Text>
                            <Text style={styles.textTilecln}>:</Text>
                            <Text style={styles.textDescription}>{item.upload_date } 8:00 pm</Text>
                          </View>

                          <View style={{ flexDirection: "row", padding: 0 }}>
                            <Text style={styles.textTile}>Result</Text>
                            <Text style={styles.textTilecln}>:</Text>
                            <Text style={styles.textDescription}>{ item.result }</Text>
                          </View>

                          <View style={{ flexDirection: "row", padding: 0 }}>
                            <Text style={styles.textTile}>Status</Text>
                            <Text style={styles.textTilecln}>:</Text>
                            <Text style={styles.textDescription}>{ (item.StatusCase == 1)? "Pending" : "Disposed" }</Text>
                          </View>

      
                      </View>
                      </TouchableOpacity>      
                      </View>
                     )}

                      removeClippedSubviews={true}
                      initialNumToRender={30}
                      maxToRenderPerBatch={20}
                      onEndReachedThreshold={0.1}
                      onEndReached={onEndReached}
                      refreshing={refreshing}
                      updateCellsBatchingPeriod={20}
                      ListFooterComponent={() => (endLoader && total_case.length > 0) ? ListFooterComponent : null}
                      windowSize={10}
                    />                
            </View>
             }


            { !endLoader &&  total_case.length >0 && (() => {
                 return ( 
               <View style={styles.main_body_two}> 
                  <Text style={{textAlign:"center", fontSize: 20}}>
                      End.
                  </Text>     
                </View>   
                                
            )})()
            }


          { (total_case.length ==0)?(
                 
                 focusLength?(
                <View style={styles.main_body_two}> 
                   <Text style={{textAlign:"center", fontSize: 20}}>
                     No Case Found.
                   </Text>     
                 </View> ):null
                    ): null
                                 
          
            }

          </View>
         

          }

        </SafeAreaView> 

      </View>
    );
};
 
export default CaseResultScreen;


async function getChamberId() {
  try {
    const jsonValue = await AsyncStorage.getItem("chamber_id")
    if(jsonValue !== null){
      //console.log("chamber_id: "+JSON.parse(jsonValue));
      return JSON.parse(jsonValue); 
    }
  } catch(err) {
    console.error(err);
  }
};




const styles = StyleSheet.create({

   // Use
   dropdown4BtnStyle: {
    width: 110,
    height: 32,
    backgroundColor: '#307ecc',
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#f00',
  },

  dropdown4BtnTxtStyle: {color: '#fff', textAlign: 'center'},
  dropdown4DropdownStyle: {backgroundColor: '#f00'},
  dropdown4RowStyle: {backgroundColor: '#307ecc', borderBottomColor: '#dadae8'},
  dropdown4RowTxtStyle: {color: '#white', textAlign: 'left'},


  SectionStyle: {
    flexDirection: 'row',
    height: 32,
    margin: 2,
    width:85,
  },

  SectionStyleYear: {
    flexDirection: 'row',
    height: 32,
    margin: 2,
    width:70,
  },

  SectionStyleStatus: {
    flexDirection: 'row',
    height: 32,
    margin: 2,
    width:110,
  },

  inputStyle: {
    flex: 1,
    backgroundColor: '#307ecc',
    color: 'white',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: '#f00',
    fontWeight:'bold',
    textAlign: 'center',
  },
  
  buttonStyle: {
    backgroundColor: '#f00',
    borderWidth: 0,
    color: '#f00',
    borderColor: '#f00',
    height: 35,
    alignItems: 'center',
    borderRadius: 30,
    width:70,
  },


  buttonStyleSearch: {
    backgroundColor: '#f00',
    borderWidth: 0,
    color: '#f00',
    borderColor: '#f00',
    height: 35,
    alignItems: 'center',
    borderRadius: 30,
    width:80,
  },

  buttonTextStyle: {
    color: '#FFFFFF',
    paddingVertical: 6,
    fontSize: 16,
  },

  registerTextStyle: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'center',
    padding: 10,
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },
  titleText:{
    fontSize: 30,
    alignItems: 'center',
    justifyContent: 'center',
    color : "#fff",
  },
  
  form: {
    backgroundColor: "#ccc",
    flex: 1,
    alignItems: "center", // ignore this - we'll come back to it
    justifyContent: "center", // ignore this - we'll come back to it
    flexDirection: "row",
  },

  listFooter: {
    paddingVertical: 1,
    marginTop:2,
    marginBottom:30,
    // borderTopWidth: 1,
    // borderColor: "#CED0CE"
  },


  container: {
    flex: 1,
    alignItems: "center",
    // justifyContent: "center",
    //backgroundColor: "#0373BB",
    backgroundColor: "#fff",
  },
  indicator: {
    backgroundColor: 'black',
    borderRadius: 3,
    width: 6,
  },
  details: {
    padding: 5,
    borderWidth: .5,
    borderColor: "red",
    borderRadius: 6,
    // width: 300,
    marginTop:10,
    marginLeft:20
  },

  main_container:{
    borderRadius: 4,
    width:responsiveWidth(100),
    // borderWidth: 4,
    borderLeftWidth:4,
    borderRightWidth:4,
    borderBottomWidth:4,
    borderColor: "#ccc",
    height:'100%',
  },

  main_containers:{
    borderRadius: 4,
    width:responsiveWidth(100),
    // borderWidth: 4,
    borderLeftWidth:4,
    borderRightWidth:4,
    borderBottomWidth:4,
    borderColor: "#ccc",
    height:'100%',
    paddingBottom:80,
  },


  
  main_loader:{
    width:responsiveWidth(100),
  },

  main_body: {
    padding: 5,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 6,
    marginBottom:0,
    backgroundColor: "#FFF",
    width:responsiveWidth(98),
  },


  main_body_two: {
    padding: 7,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 6,
    marginTop:3,
    marginBottom:0,
    backgroundColor: "#FFF",
    width:responsiveWidth(98),
  },

  TotalCaseFound:{
    fontSize: 14,
    textAlign:'center',
    fontWeight:'bold',
    width:responsiveWidth(100),
  },

  asperSupremeCourt:{
    fontSize: 10,
    textAlign:'center',
    fontWeight:'bold',
    width:responsiveWidth(100),
  },


  textTile:{
    fontSize: 14,
    alignItems: "center",
    justifyContent: "center",
    width:responsiveWidth(28),
  },

  CaseTypeNoTile:{
    fontSize: 14,
    alignItems: "center",
    justifyContent: "center",
    width:99,
  },

  textTilecln:{
    fontSize: 16,
    alignItems: "center",
    justifyContent: "center",
    width:8,
  },

  textDescription:{
    paddingTop:3,
    fontSize: 13,
    width:responsiveWidth(60),
  },

  textParties:{
    fontSize: 8,
    paddingTop:8,
  },


  titleText: {
    fontSize: 30,
    alignItems: "center",
    justifyContent: "center",
  },

  LawyerInfo: {
    padding: 5,
    borderWidth: 1,
    borderColor: "white",
    borderRadius: 6,
  },
  LawyerInfoText: {
    fontSize: 15,
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },

  button: {
    alignItems: "center",
    backgroundColor: "#419641",
    width: 120,
    height: 36,
    padding: 3,
    borderWidth: 1,
    borderColor: "white",
    borderRadius: 6,
    marginTop: 10,
    marginLeft:10
  },

  buttonRefresh: {
    alignItems: "center",
    backgroundColor: "#FFFFBD",
    width: 75,
    height: 36,
    paddingTop: 5,
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 3,
    borderWidth: 1,
    borderColor: "red",
    color:"#000",
    borderRadius: 6,
    marginTop: 10,
    marginLeft:10,
  },

  buttonNexDate: {
    alignItems: "center",
    backgroundColor: "#FFFFBD",
    width: 85,
    height: 36,
    paddingTop: 5,
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 3,
    borderWidth: 1,
    borderColor: "red",
    color:"#000",
    borderRadius: 6,
    marginTop: 10,
    marginLeft:10
  },

  buttonTextRefresh: {
    fontSize: 16,
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },

  buttonText: {
    fontSize: 20,
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },
  input: {
    width: 140,
    fontSize: 22,
    height: 36,
    paddingLeft: 10,
    borderWidth: 1,
    marginTop: 10,
    borderColor: "white",
    color: "white",
    marginVertical: 10,
    borderRadius: 6,
  },

  item: {
    backgroundColor: "#f9c2ff",
    padding: 20,
    marginVertical: 8,
  },
  header: {
    fontSize: 32,
    backgroundColor: "#fff",
  },
  title: {
    fontSize: 24,
  },

  SectionHeaderStyle: {
    backgroundColor: "#CDDC89",
    fontSize: 20,
    padding: 5,
    color: "#fff",
  },

  SectionListItemStyle: {
    fontSize: 15,
    padding: 15,
    color: "#000",
    backgroundColor: "#F5F5F5",
  },

  hddata: {
    padding: 5,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 6,
    marginTop:5,
    marginBottom:0,
    backgroundColor: "#C1EfFf",
  },


  hddataText: {
    fontSize: 20,
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },

  hddataResult: {
    padding: 5,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 6,
    marginTop:5,
    marginLeft:7,
    marginRight:7,
    backgroundColor: "#FFFFBD",
  },

  totalfound: {
    fontSize: 14,
    alignItems: "center",
    color: "#fff",
  },



  noItems: {
    fontSize: 24,
    alignItems: "center",
    justifyContent: "center",
    color: "#FFF",
    paddingLeft:90,
    width:300,
    paddingTop:20,
    paddingBottom:20,

    textShadowColor: 'rgba(0, 0, 0,0.9)',
    textShadowOffset: {width: -1, height: 2},
    textShadowRadius: 15
  },

  errorColor: {
    color:'coral',
  },

  isDraft: {
    fontSize: 20,
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    marginLeft:10,
  },

  CaseResultDate: {
    padding: 5,
    borderWidth: 1,
    borderColor: "white",
    borderRadius: 6,
    marginLeft:5,
    marginRight:6,
    backgroundColor: "#fff",
    color: "#000",
    alignItems: "center",
    justifyContent: "center",
  },

  get_date: {
    fontSize: 17,
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },

  last_datetime: {
    fontSize: 14,
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },

  fadingContainer: {
    backgroundColor: "blueviolet",
    borderRadius:4,
    margin: 20,
  },

  fadingText: {
    fontSize: 16,
    textAlign: "center",
    color : "#fff",
    paddingVertical: 5,
    paddingHorizontal: 25,
  },

});