// Import React
//import React from 'react';
import React, {useState, createRef, useEffect, useContext, useRef} from 'react';
 
// Import Navigators from React Navigation
import {createStackNavigator} from '@react-navigation/stack';
import {createDrawerNavigator} from '@react-navigation/drawer';
 
// Import Screens
import HomeScreen from './DrawerScreens/HomeScreen';
//import SettingsScreen from './DrawerScreens/SettingsScreen';
import LcmsScreen from './DrawerScreens/LcmsScreen';
// import NewCaseEntry from './DrawerScreens/NewCaseEntry';
// import ExampleCases from './DrawerScreens/ExampleCases';
// import ExampleDocument from './DrawerScreens/ExampleDocument';
// import TotalCaseScreen from './DrawerScreens/TotalCaseScreen';
// import CaseResultScreen from './DrawerScreens/CaseResultScreen';
// import Calendar from './DrawerScreens/Calendar';
// import Appointments from './DrawerScreens/Appointments';
// import Task from './DrawerScreens/Task';
import CustomSidebarMenu from './Components/CustomSidebarMenu';
import NavigationDrawerHeader from './Components/NavigationDrawerHeader';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from "@react-navigation/native";
//import CaseDetails from './Case/CaseDetails';
//import OtherMatters from './Case/OtherMatters';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
//import LoginScreen from '../Screen/LoginScreen';
// import Associates from './DrawerScreens/Associates';
// import AddAssociates from './DrawerScreens/AddAssociates';
// import Employee from './DrawerScreens/Employee';
// import AddEmploee from './DrawerScreens/AddEmploee';
// import LawyerBillRecieved from './DrawerScreens/LawyerBillRecieved';
// import CreateLawyerBillReceive from './DrawerScreens/CreateLawyerBillReceive';
// import AddPanelLawyer from './DrawerScreens/AddPanelLawyer';
// import CreateNewPanelLawyer from './DrawerScreens/CreateNewPanelLawyer';
// import PanelLawyersRenewal from './DrawerScreens/PanelLawyersRenewal';
// import CreateNewLawyerRenewal from './DrawerScreens/CreateNewLawyerRenewal';
// import LawyerBillAproved from './DrawerScreens/LawyerBillAproved';
// import LawyerBillPayment from './DrawerScreens/LawyerBillPayment';
// import LawyerBillDue from './DrawerScreens/LawyerBillDue';
// import LawyerBillingItems from './DrawerScreens/LawyerBillingItems';
// import ListofLawyerReport from './DrawerScreens/ListofLawyerReport';
// import CaseWiseLawyerBillReports from './DrawerScreens/CaseWiseLawyerBillReports';
// import UnpaidUnapprovedLawyerBillsReports from './DrawerScreens/UnpaidUnapprovedLawyerBillsReports';
// import LawyerWiseTransactionReportsPdf from './DrawerScreens/LawyerWiseTransactionReportsPdf';

// import AddOrganization from './DrawerScreens/AddOrganization';
// import CreateNewOrg from './DrawerScreens/CreateNewOrg';
// import OrganizationRenewal from './DrawerScreens/OrganizationRenewal';
// import CreateNewOrgRenewal from './DrawerScreens/CreateNewOrgRenewal';
// import OrgBillRecieved from './DrawerScreens/OrgBillRecieved';
// import CreateNewBillReceive from './DrawerScreens/CreateNewBillReceive';
// import OrgBillAproved from './DrawerScreens/OrgBillAproved';
// import OrgBillPayment from './DrawerScreens/OrgBillPayment';
// import OrgBillDue from './DrawerScreens/OrgBillDue';
// import OrgBillingItems from './DrawerScreens/OrgBillingItems';
// import ListofOrganizationReport from './DrawerScreens/ListofOrganizationReport';
// import OrgWiseTransactionReportsPdf from './DrawerScreens/OrgWiseTransactionReportsPdf';
// import CaseWiseOrgBillReports from './DrawerScreens/CaseWiseOrgBillReports';
// import UnpaidUnapprovedBillsReports from './DrawerScreens/UnpaidUnapprovedBillsReports';
// import EmailSettings from './DrawerScreens/EmailSettings';
import FullJudgment from './DrawerScreens/FullJudgment';

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();
const Tab = createBottomTabNavigator();

const HomeScreenStack = ({navigation}) => {

 

  return (
    <Stack.Navigator initialRouteName="HomeScreen">
      <Stack.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={{
          title: 'BD Law Reference', //Set Header Title
          headerTitleAlign: 'center',
          headerRight: () => (
            <NavigationDrawerHeader navigationProps={navigation} />
          ),
          headerStyle: {
            //backgroundColor: '#307ecc', //Set Header color
            backgroundColor: '#3c3ce0', //Set Header color
          },
          headerTintColor: '#fff', //Set Header text color
          headerTitleStyle: {
            fontWeight: 'bold', //Set Header text style
          },
        }}
      />

      <Stack.Screen
        name="FullJudgment"
        component={FullJudgment}
        options={{
          title: 'Full Judgment', //Set Header Title
          headerTitleAlign: 'center',
        }}
        initialParams={{ chamber_id: 18 }}
      />
    </Stack.Navigator>
  );
};


// const NewCaseEntryStack = ({navigation}) => {
 
//   return (
//     <Stack.Navigator
//       initialRouteName="NewCaseEntry"
//       screenOptions={{
//         headerRight: () => (
//           <NavigationDrawerHeader navigationProps={navigation} />
//         ),
//         headerStyle: {
//           //backgroundColor: '#307ecc', //Set Header color
//           backgroundColor: '#3c3ce0', //Set Header color
//         },
//         headerTintColor: '#fff', //Set Header text color
//         headerTitleStyle: {
//           fontWeight: 'bold', //Set Header text style
//         },
//       }}>
//       <Stack.Screen
//         name="NewCaseEntry"
//         component={NewCaseEntry}
//         options={{
//           title: 'New Case Entry', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
//     </Stack.Navigator>
//   );
// };
const LcmsScreenStack = ({navigation}) => {
 
  return (
    <Stack.Navigator
      initialRouteName="LcmsScreen"
      screenOptions={{
        headerRight: () => (
          <NavigationDrawerHeader navigationProps={navigation} />
        ),
        headerStyle: {
          //backgroundColor: '#307ecc', //Set Header color
          backgroundColor: '#3c3ce0', //Set Header color
        },
        headerTintColor: '#fff', //Set Header text color
        headerTitleStyle: {
          fontWeight: 'bold', //Set Header text style
        },
      }}>
      <Stack.Screen
        name="LcmsScreen"
        component={LcmsScreen}
        options={{
          title: 'Dashboard', //Set Header Title
          headerTitleAlign: 'center',
        }}
        initialParams={{ chamber_id: 18 }}
      />
    </Stack.Navigator>
  );
};
// const OrganizationPanelStack = ({navigation}) => {
 
//   return (
//     <Stack.Navigator
//       initialRouteName="AddOrganization"
//       screenOptions={{
//         headerRight: () => (
//           <NavigationDrawerHeader navigationProps={navigation} />
//         ),
//         headerStyle: {
//           //backgroundColor: '#307ecc', //Set Header color
//           backgroundColor: '#3c3ce0', //Set Header color
//         },
//         headerTintColor: '#fff', //Set Header text color
//         headerTitleStyle: {
//           fontWeight: 'bold', //Set Header text style
//         },
//       }}>

//       <Stack.Screen
//         name="AddOrganization"
//         component={AddOrganization}
//         options={{
//           title: 'Add Lawyer Panel (for Organization)', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
      
//       />
//        <Stack.Screen
//         name="CreateNewOrg"
//         component={CreateNewOrg}
//         options={{
//           title: 'Add  Organization', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
       
//       />
      

//     <Stack.Screen
//         name="OrganizationRenewal"
//         component={OrganizationRenewal}
//         options={{
//           title: 'Organization Renewal', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
        
//       />
//        <Stack.Screen
//         name="CreateNewOrgRenewal"
//         component={CreateNewOrgRenewal}
//         options={{
//           title: 'Add  Organization Renewal', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
       
//       />
//        <Stack.Screen
//         name="OrgBillRecieved"
//         component={OrgBillRecieved}
//         options={{
//           title: 'Org Bill Recieved', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
        
//       />
//        <Stack.Screen
//         name="CreateNewBillReceive"
//         component={CreateNewBillReceive}
//         options={{
//           title: 'Create Organization Receive', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
       
//       />
      
//        <Stack.Screen
//         name="OrgBillAproved"
//         component={OrgBillAproved}
//         options={{
//           title: 'Org Bill Aproved', //Set Header Title
//           headerTitleAlign: 'center',
//         }}        
//       />
//        <Stack.Screen
//         name="OrgBillingItems"
//         component={OrgBillingItems}
//         options={{
//           title: 'Org Billing Items', //Set Header Title
//           headerTitleAlign: 'center',
//         }}        
//       />
//       <Stack.Screen
//       name="OrgBillPayment"
//       component={OrgBillPayment}
//       options={{
//         title: 'Org Bill Payment', //Set Header Title
//         headerTitleAlign: 'center',
//       }}      
//     />
//      <Stack.Screen
//       name="OrgBillDue"
//       component={OrgBillDue}
//       options={{
//         title: 'Org Bill Due', //Set Header Title
//         headerTitleAlign: 'center',
//       }}      
//     />
//     <Stack.Screen
//     name="ListofOrganizationReport"
//     component={ListofOrganizationReport}
//     options={{
//       title: 'Lis of Organization Report', //Set Header Title
//       headerTitleAlign: 'center',
//     }}    
//   />
//    <Stack.Screen
//     name="UnpaidUnapprovedBillsReports"
//     component={UnpaidUnapprovedBillsReports}
//     options={{
//       title: 'Unpaid Unapproved Bills Reports', //Set Header Title
//       headerTitleAlign: 'center',
//     }}    
//   />
//     <Stack.Screen 
//     name="OrgWiseTransactionReportsPdf"
//     component={OrgWiseTransactionReportsPdf}
//     options={{
//       title: 'Org Wise Transaction Reports', //Set Header Title
//       headerTitleAlign: 'center',
//     }}    
//   />

// <Stack.Screen
//     name="CaseWiseOrgBillReports"
//     component={CaseWiseOrgBillReports}
//     options={{
//       title: 'Case Wise Org Bill Reports', //Set Header Title
//       headerTitleAlign: 'center',
//     }}    
//   />
//     </Stack.Navigator>
//   );
// };

// const LayerPanelStack = ({navigation}) => {
 
//   return (
//     <Stack.Navigator
//       initialRouteName="AddPanelLawyer"
//       screenOptions={{
//         headerRight: () => (
//           <NavigationDrawerHeader navigationProps={navigation} />
//         ),
//         headerStyle: {
//           //backgroundColor: '#307ecc', //Set Header color
//           backgroundColor: '#3c3ce0', //Set Header color
//         },
//         headerTintColor: '#fff', //Set Header text color
//         headerTitleStyle: {
//           fontWeight: 'bold', //Set Header text style
//         },
//       }}>
//       <Stack.Screen
//         name="AddPanelLawyer"
//         component={AddPanelLawyer}
//         options={{
//           title: 'Add Organization (for Panel Lawyer)', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
//        <Stack.Screen
//         name="CreateNewPanelLawyer"
//         component={CreateNewPanelLawyer}
//         options={{
//           title: 'Add  Panel Lawyer', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
//        <Stack.Screen
//         name="PanelLawyersRenewal"
//         component={PanelLawyersRenewal}
//         options={{
//           title: 'Panel Lawyers Renewal', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
//        <Stack.Screen
//         name="CreateNewLawyerRenewal"
//         component={CreateNewLawyerRenewal}
//         options={{
//           title: 'Create Lawyer Renewal', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
//        <Stack.Screen
//         name="LawyerBillRecieved"
//         component={LawyerBillRecieved}
//         options={{
//           title: 'Bill Receive', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
//       <Stack.Screen
//         name="CreateLawyerBillReceive"
//         component={CreateLawyerBillReceive}
//         options={{
//           title: 'Create Bill Receive', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
//       <Stack.Screen
//         name="LawyerBillAproved"
//         component={LawyerBillAproved}
//         options={{
//           title: 'Bill Aproved', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
//        <Stack.Screen
//         name="LawyerBillDue"
//         component={LawyerBillDue}
//         options={{
//           title: 'Bill Due', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
//        <Stack.Screen
//         name="LawyerBillPayment"
//         component={LawyerBillPayment}
//         options={{
//           title: 'Bill Payment', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
//       <Stack.Screen
//         name="LawyerBillingItems"
//         component={LawyerBillingItems}
//         options={{
//           title: 'Billing Items', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
//       <Stack.Screen
//         name="ListofLawyerReport"
//         component={ListofLawyerReport}
//         options={{
//           title: 'List of panel Lawyers', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
//         <Stack.Screen
//         name="CaseWiseLawyerBillReports"
//         component={CaseWiseLawyerBillReports}
//         options={{
//           title: 'Case Wise Lawyer Bill', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
//       <Stack.Screen
//         name="LawyerWiseTransactionReportsPdf"
//         component={LawyerWiseTransactionReportsPdf}
//         options={{
//           title: 'Transaction reports', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
//       <Stack.Screen
//         name="UnpaidUnapprovedLawyerBillsReports"
//         component={UnpaidUnapprovedLawyerBillsReports}
//         options={{
//           title: 'Unpaid Lawyer Bill', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
      
//     </Stack.Navigator>
//   );
// };
// const TotalCaseScreenStack = ({navigation}) => {
//   return (
//     <Stack.Navigator
//       initialRouteName="TotalCaseScreen"
//       screenOptions={{
//         headerRight: () => (
//           <NavigationDrawerHeader navigationProps={navigation} />
//         ),
//         headerStyle: {
//           //backgroundColor: '#307ecc', //Set Header color
//           backgroundColor: '#3c3ce0', //Set Header color
//         },
//         headerTintColor: '#fff', //Set Header text color
//         headerTitleStyle: {
//           fontWeight: 'bold', //Set Header text style
//         },
//       }}>
//       <Stack.Screen
//         name="TotalCaseScreen"
//         component={TotalCaseScreen}
//         options={{
//           title: 'Total Case', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//       />
//       <Stack.Screen
//         name="CaseDetails"
//         component={CaseDetails}
//         options={{
//           title: 'View Case Information', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//       />
//        <Stack.Screen
//         name="OtherMatters"
//         component={OtherMatters}
//         options={{
//           title: 'Other Matters', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//       />
      
//     </Stack.Navigator>
//   );
// };

// const CaseResultScreenStack = ({navigation}) => {
//   return (
//     <Stack.Navigator
//       initialRouteName="CaseResultScreen"
//       screenOptions={{
//         headerRight: () => (
//           <NavigationDrawerHeader navigationProps={navigation} />
//         ),
//         headerStyle: {
//           //backgroundColor: '#307ecc', //Set Header color
//           backgroundColor: '#3c3ce0', //Set Header color
//         },
//         headerTintColor: '#fff', //Set Header text color
//         headerTitleStyle: {
//           fontWeight: 'bold', //Set Header text style
//           //fontSize:16,
//         },
//       }}>
//       <Stack.Screen
//         name="CaseResultScreen"
//         component={CaseResultScreen}
//         options={{
//           title: 'Case Result', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//       />
//     </Stack.Navigator>
//   );
// };

// const ExampleStack = ({navigation}) => {
 
//   return (
//     <Stack.Navigator
//       initialRouteName="ExampleCases"
//       screenOptions={{
//         headerRight: () => (
//           <NavigationDrawerHeader navigationProps={navigation} />
//         ),
//         headerStyle: {
//           //backgroundColor: '#307ecc', //Set Header color
//           backgroundColor: '#3c3ce0', //Set Header color
//         },
//         headerTintColor: '#fff', //Set Header text color
//         headerTitleStyle: {
//           fontWeight: 'bold', //Set Header text style
//         },
//       }}>
//       <Stack.Screen
//         name="ExampleCases"
//         component={ExampleCases}
//         options={{
//           title: 'Example Cases', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
//     <Stack.Screen
//         name="ExampleDocument"
//         component={ExampleDocument}
//         options={{
//           title: 'Example Documents', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />


//     </Stack.Navigator>
//   );
// };
// const CalendarStack = ({navigation}) => {
 
//   return (
//     <Stack.Navigator
//       initialRouteName="Calendar"
//       screenOptions={{
//         headerRight: () => (
//           <NavigationDrawerHeader navigationProps={navigation} />
//         ),
//         headerStyle: {
//           //backgroundColor: '#307ecc', //Set Header color
//           backgroundColor: '#3c3ce0', //Set Header color
//         },
//         headerTintColor: '#fff', //Set Header text color
//         headerTitleStyle: {
//           fontWeight: 'bold', //Set Header text style
//         },
//       }}>
//       <Stack.Screen
//         name="Calendar"
//         component={Calendar}
//         options={{
//           title: 'Calendar', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
  
//   <Stack.Screen
//         name="Appointments"
//         component={Appointments}
//         options={{
//           title: 'Appointments', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
//  <Stack.Screen
//         name="Task"
//         component={Task}
//         options={{
//           title: 'Task', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
//     </Stack.Navigator>
//   );
// };
// const TeamStack = ({navigation}) => {
 
//   return (
//     <Stack.Navigator
//       initialRouteName="Associates"
//       screenOptions={{
//         headerRight: () => (
//           <NavigationDrawerHeader navigationProps={navigation} />
//         ),
//         headerStyle: {
//           //backgroundColor: '#307ecc', //Set Header color
//           backgroundColor: '#3c3ce0', //Set Header color
//         },
//         headerTintColor: '#fff', //Set Header text color
//         headerTitleStyle: {
//           fontWeight: 'bold', //Set Header text style
//         },
//       }}>
//       <Stack.Screen
//         name="Associates"
//         component={Associates}
//         options={{
//           title: 'Associates', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
       
//       />
//      <Stack.Screen
//         name="AddAssociates"
//         component={AddAssociates}
//         options={{
//           title: 'Add Associates', //Set Header Title
//           headerTitleAlign: 'center',
//         }}       
//       />
//        <Stack.Screen
//         name="AddEmploee"
//         component={AddEmploee}
//         options={{
//           title: 'Add Employee', //Set Header Title
//           headerTitleAlign: 'center',
//         }}       
//       />
//      <Stack.Screen
//         name="Employee"
//         component={Employee}
//         options={{
//           title: 'Employee', //Set Header Title
//           headerTitleAlign: 'center',
//         }}       
//       /> 
    
 
//     </Stack.Navigator>
//   );
// };

// const SettingsScreenStack = ({navigation}) => {
 
//   return (
//     <Stack.Navigator
//       initialRouteName="EmailSettings"
//       screenOptions={{
//         headerRight: () => (
//           <NavigationDrawerHeader navigationProps={navigation} />
//         ),
//         headerStyle: {
//           //backgroundColor: '#307ecc', //Set Header color
//           backgroundColor: '#3c3ce0', //Set Header color
//         },
//         headerTintColor: '#fff', //Set Header text color
//         headerTitleStyle: {
//           fontWeight: 'bold', //Set Header text style
//         },
//       }}>
//       <Stack.Screen
//         name="EmailSettings"
//         component={EmailSettings}
//         options={{
//           title: 'Email Option', //Set Header Title
//           headerTitleAlign: 'center',
//         }}
//         initialParams={{ chamber_id: 18 }}
//       />
//     </Stack.Navigator>
//   );
// };




const DrawerNavigatorRoutes = (props) => {
 
  return (
    <Drawer.Navigator
    //drawerContentOptions={{ activeBackgroundColor: '#ffff', activeTintColor: '#ooo' }}
  
      screenOptions={{
        headerShown: false,
        drawerActiveBackgroundColor : '#419641',
      drawerActiveTintColor: "#fffccc",
      drawerInactiveTintColor: "white",
        color: '#fff',
        itemStyle: {marginVertical: 5, color: '#ffff'},
        labelStyle: {
          color: '#fff',
        },
        drawerStyle: {
          backgroundColor: '#c6cbef',
          width: 240,
        },
       // overlayColor: 'transparent',
      }}
      //screenOptions={{headerShown: false}}
     drawerContent={CustomSidebarMenu}
      //drawerContent={props => <CustomSidebarMenu {...props} />}
      >
      
      {/* <Drawer.Screen
        name="HomeScreenStack"
        options={{drawerLabel: 'BD Law Reference', labelStyle: {         
          color: 'white',
          fontSize:24,
          paddingLeft:8
        },}}
        component={HomeScreenStack}
      /> */}
        <Drawer.Screen
        name="LcmsScreenStack"
        options={{drawerLabel: 'Judgment / Reference'}}
        component={LcmsScreenStack}
       
      />
       {/* <Drawer.Screen
        name="NewCaseEntryStack"
        options={{drawerLabel: 'New Case Entry'}}
        component={NewCaseEntryStack}
       
      />
	   <Drawer.Screen
        name="TotalCaseScreenStack"
        options={{drawerLabel: 'Total Case'}}
        component={TotalCaseScreenStack}
      />
	   <Drawer.Screen
        name="CaseResultScreenStack"
        options={{drawerLabel: 'Case Result'}}
        component={CaseResultScreenStack}
      />
       <Drawer.Screen
        name="ExampleStack"
        options={{drawerLabel: 'Example Cases'}}
        component={ExampleStack}
      />
       <Drawer.Screen
        name="CalendarStack"
        options={{drawerLabel: 'Calendar'}}
        component={CalendarStack}
      /> */}
       {/* <Drawer.Screen
        name="LoginScreen"
        options={{drawerLabel: 'Login'}}
        component={LoginScreen}
      /> */}
       <Drawer.Screen
        name="LcmsScreen"
        options={{drawerLabel: 'Judgment / Reference'}}
        component={LcmsScreen}
      />
       {/* <Drawer.Screen
        name="SettingsScreenStack"
        options={{drawerLabel: 'Settings'}}
        component={SettingsScreenStack}
       
      />
       <Drawer.Screen
        name="TeamStack"
        options={{drawerLabel: 'Team'}}
        component={TeamStack}
       
      />
       <Drawer.Screen
        name="Associates"
        options={{drawerLabel: 'Associates'}}
        component={Associates}
      />
      <Drawer.Screen
        name="AddAssociates"
        options={{drawerLabel: 'AddAssociates'}}
        component={AddAssociates}
      />
      <Drawer.Screen
        name="LayerPanelStack"
        options={{drawerLabel: 'Layer Panel'}}
        component={LayerPanelStack}
       
      />
      <Drawer.Screen      
        name="OrganizationPanelStack"
        options={{drawerLabel: 'Organizatio Panel'}}
        component={OrganizationPanelStack}
       
      />
       <Drawer.Screen
        name="OrganizationRenewal"      
        options={{drawerLabel: 'Organization Renewal'}}
        component={OrganizationPanelStack}       
      /> */}
       <Drawer.Screen
        name="HomeScreenStack"      
        options={{drawerLabel: 'Organization Renewal'}}
        component={HomeScreenStack}       
      />
    </Drawer.Navigator>
  );
};


 
export default DrawerNavigatorRoutes;
//export{Home, Room, Hall};
//export {DrawerNavigatorRoutes, HomeScreenStack};

