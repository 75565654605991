import React, { useContext, useState, useEffect,useRef, createRef } from "react";
import {
  View,
  TouchableWithoutFeedback,
  Keyboard,
  ScrollView,
  Text,
  StyleSheet,
  Button,
  FlatList,
  ActivityIndicator,
  TouchableOpacity,
  LogBox 
 
} from "react-native";
import Checkbox from 'expo-checkbox';
import ErrorText from "../shared/errorText";
import ContainerFluid from "../shared/containerFluid";
import FormGroup from "../shared/formGroup";
import { ThemeContext } from "../contexts/ThemeContext";
import { AuthContext } from "../contexts/AuthContext";
import { Formik } from "formik";
import * as yup from "yup";
import InputLevel from "../shared/inputLevel";

import BasicFormInput from "../shared/basicFormInput";
//import { globalStyles } from "../styles/globalStyles";
import axios from "axios";
import { serializeErrors } from "../utils/Helpers";
import { apiPathLocal } from "../utils/constants/Consts";
import { apiPath } from "../utils/constants/Consts";
import { BASE_URL } from '../Components/BaseUrl';
import useErrorHandler from "../utils/custom-hooks/ErrorHandler";
import { useFocusEffect } from "@react-navigation/native";

import ErrorMessage from "../shared/errorMessage";

//import { Picker } from "@react-native-community/picker";
import AppBtn from "../shared/appBtn";
import SearchableDropdown from 'react-native-searchable-dropdown';

import ModalFilterPicker from 'react-native-modal-filter-picker'

import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
//register validation schema
const basicInfoSchema = yup.object({
  court_division_id: yup.string().required("Courts/Division is required"), 
  district_id: yup.string().required("District is required"),
  court_id: yup.string().required("Court Name is required"),
  position: yup.string().required("Name of parties is required"),
  position1: yup.string().required("Court Name is required"),
  versus: yup.string().required("Name of parties is required"),
  versus1: yup.string().required("Name of parties is required"),
  case_no: yup.string().required(" Case No./Year (Year must Four Digit) is required"),
  jurisdictions: yup.string().required("Case Category is required"),
  case_type: yup.string().required("Case Type is required"),
 
});

import { Card } from 'react-native-paper';
import DateTimePickerModal from "react-native-modal-datetime-picker";
import moment from "moment";


function NewCaseEntry({ navigation }) {
  const { manifest } = Constants;
  const { isThemeDark } = useContext(ThemeContext);
  const { authUser, setUnauthStatus, updateAuthUserProfile } = useContext( AuthContext );
  const { updateAuthUserName } = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isallChecked, setAllChecked] = useState(true);
  const [isHide, setIsHide] = useState(false);
  const { error, showError } = useErrorHandler(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
 const [chamber_id, setChamberId] = useState('');

  const [isShowM, setIsShowM] = useState(false);
  //creates refrence to formik element
  const formikElement = createRef(null); 
  const [current, setCurrent] = useState("oboradioone");
  const [visible, setIsVisible] = useState(false);
  const [data,setData]  = useState([]);

  const [picked, setIsPicked] = useState({
    key: "",  
    label: "",   
  
  });

  const [visibleDistrict , setIsVisibleDistrict ] = useState(false);
  const [pickedDistrict , setIsPickedDistrict ] = useState({
    key: "",  
    label: "",   
  
  });
  const [visiblePoliceStation , setIsVisiblePoliceStation ] = useState(false);
  const [pickedPoliceStation , setIsPickedPoliceStation ] = useState({
    key: "",  
    label: "",   
  
  });
  const [visibleCategory , setIsVisibleCategory ] = useState(false);
  const [pickedCategory , setIsPickedCategory ] = useState({
    key: "",  
    label: "",   
  
  });
  const [visibleType , setIsVisibleType ] = useState(false);
  const [pickedType , setIsPickedType ] = useState({
    key: "",  
    label: "",   
  
  });
  const [visibleCourtName, setIsVisibleCourtName] = useState(false);
  const [pickedCourtName, setIsPickedCourtName] = useState({
    key: "",  
    label: "",   
  
  });
  const [visiblePosition, setIsVisiblePosition] = useState(false);
  const [pickedPosition, setIsPickedPosition] = useState({
    key: "",  
    label: "",   
  
  });
  const [visibleVsPosition, setIsVisibleVsPosition] = useState(false);
  const [pickedVsPosition, setIsPickedVsPosition] = useState({
    key: "",  
    label: "",   
  
  });
  const [visibleLocation , setIsVisibleLocation ] = useState(false);
  const [pickedLocation , setIsPickedLocation ] = useState({
    key: "",  
    label: "",   
  
  });

  // const [visibleLocation , setIsVisibleLocation ] = useState(false);
  // const [pickedLocation , setIsPickedLocation ] = useState({
  //   key: "",  
  //   label: "",   
  
  // });
  const [user, setUser] = useState({
    chamber_id: "",   
    user_id: "",
    assigned_to:"",
    thana_case_no:"",
    arising_out_of:"",
    visible_to:"",
    entry_date:"",
    contract_amount:"",
    case_number:"",
    related_law_and_section:"",

    versus: "",    
    versus1: "",    
    court_id: "", 
    registration_no: "",   
    case_filing_date: "",  
    wakalatnama_entry_no:"",
    wakalatnama_entry_date: "",    
    partiesBehalfOne: "",    
    partiesBehalfTwo: "", 
    arising_out_of_string: "",
    client_id: "",
    client_mobile:"",
    client_email:"",
    practice_area: "",    
    thana_id: "",    
    case_status: "", 
    contract_type: "",    
    is_bulk_insert: "",    
    case_no: "",   
    case_year: "", 
    position: "", 
    position1: "", 
    jurisdiction: "", 
    district_id: "", 
    petition_lawyer:"",
    petition_lawyer_contact_no:"",
    opposing_advocate:"",
    opposing_advocate_contact_no:"",
    description:"",
    file_location:"",
    court_division_id: "", 
    case_type: "", 
    entry_user: "", 
    case_name: "", 
    case_unique_id: "",  
   
  });
  const [caseEntryallData, setCaseEntryallData] = useState({
    
    chamber_id: "",   
    user_id: "",
    assigned_to:"",
    thana_case_no:"",
    arising_out_of:"",
    visible_to:"",
    entry_date:"",
    contract_amount:"",
    case_number:"",
    related_law_and_section:"",

    versus: "",    
    versus1: "",    
    court_id: "", 
    registration_no: "",   
    case_filing_date: "",  
    wakalatnama_entry_no:"",
    wakalatnama_entry_date: "",    
    partiesBehalfOne: "",    
    partiesBehalfTwo: "", 
    arising_out_of_string: "",
    client_id: "",
    client_mobile:"",
    client_email:"",
    practice_area: "",    
    thana_id: "",    
    case_status: "", 
    contract_type: "",    
    is_bulk_insert: "",    
    case_no: "",   
    case_year: "", 
    position: "", 
    position1: "", 
    jurisdiction: "", 
    district_id: "", 
    petition_lawyer:"",
    petition_lawyer_contact_no:"",
    opposing_advocate:"",
    opposing_advocate_contact_no:"",
    description:"",
    file_location:"",
    court_division_id: "", 
    case_type: "", 
    entry_user: "", 
    case_name: "", 
    case_unique_id: "", 
   
  });

  const [courtDivision, setCourtDivision] = useState([]);
  const [Districts, setCourtDistrict] = useState([]);
  const [PoliceStations, setPoliceStation] = useState([]);
  const [Categories, setCourtCategory] = useState([]);
  const [Types, setCourtType] = useState([]);
  const [CourtNames, setCourtName] = useState([]);
  const [fileLocation, setfileLocation] = useState([]);
  const [positions, setPosition] = useState([]);
  const [products , setProducts] = useState([]);

  //const [products, setProducts] = useState(productss);
  const items = [
    //name key is must.It is to show the text in front
    { id: 1, name: 'angellist' },
    { id: 2, name: 'codepen' },
    { id: 3, name: 'envelope' },
    { id: 4, name: 'etsy' },
    { id: 5, name: 'facebook' },
    { id: 6, name: 'foursquare' },
    { id: 7, name: 'github-alt' },
    { id: 8, name: 'github' },
    { id: 9, name: 'gitlab' },
    { id: 10, name: 'instagram' },
  ];
  
  const itemss = [
    //name key is must.It is to show the text in front
    { key: 1, label: 'angellist' },
    { key: 2, label: 'codepen' },
    { key: 3, label: 'envelope' },
    { key: 4, label: 'etsy' },
    { key: 5, label: 'facebook' },
    { key: 6, label: 'foursquare' },
    { key: 7, label: 'github-alt' },
    { key: 8, label: 'github' },
    { key: 9, label: 'gitlab' },
    { key: 10, label: 'instagram' },
  ];
  const options = [
    {
      key: 'kenya',
      label: 'Kenya',
    },
    {
      key: 'uganda',
      label: 'Uganda',
    },
    {
      key: 'libya',
      label: 'Libya',
    },
    {
      key: 'morocco',
      label: 'Morocco',
    },
    {
      key: 'estonia',
      label: 'Estonia',
    },
  ];
  useEffect(() => {
    console.log('fff '+authUser.chamberId); 
    setIsEditing(true);
   // getChamberId().then(chamberId =>fetchData(chamberId) );
   fetchData();
 //  getChamberId().then(chamberId => setChamberId(chamberId));
 LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
   
  }, []);



  const _fetchUserData = async (data) => {


    try {
     
      let url = BASE_URL + "/CaseEntry";
     // let response = await axios.get(url).then((res) => res.data);      
     let response = await  axios({
      method: 'post',
      url: url,
      headers: {
       // 'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization':'Basic YnJva2VyOmJyb2tlcl8xMjM='
      },
      data: data
    }).then((res) => res.data);
      
     // if (response.resp == 1)
       return response;
      //if (response.country) return response.country;
     // console.log(response.user); 
    } catch (err) {
      console.log("Error", err);
    }
  };    


  const _fetchDToC = async (data) => {
    try {
     
      let url = BASE_URL + "/court_division_to_court_no";
     // let response = await axios.get(url).then((res) => res.data);      
     let response = await  axios({
      method: 'post',
      url: url,
      headers: {
       // 'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization':'Basic YnJva2VyOmJyb2tlcl8xMjM='
      },
      data: data
    }).then((res) => res.data);
      
     // if (response.resp == 1)
       return response;
      //if (response.country) return response.country;
     // console.log(response.user); 
    } catch (err) {
      console.log("Error", err);
    }
  };  


  const _fetchDToJ = async (data) => {
    try {
     
      let url = BASE_URL + "/court_division_to_jurisdiction";
     // let response = await axios.get(url).then((res) => res.data);      
     let response = await  axios({
      method: 'post',
      url: url,
      headers: {
       // 'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization':'Basic YnJva2VyOmJyb2tlcl8xMjM='
      },
      data: data
    }).then((res) => res.data);
      
     // if (response.resp == 1)
       return response;
      //if (response.country) return response.country;
     // console.log(response.user); 
    } catch (err) {
      console.log("Error", err);
    }
  };  
 
  const _fetchDToCT = async (data) => {
    try {
     
      let url = BASE_URL + "/court_division_to_caseType";
     // let response = await axios.get(url).then((res) => res.data);      
     let response = await  axios({
      method: 'post',
      url: url,
      headers: {
       // 'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization':'Basic YnJva2VyOmJyb2tlcl8xMjM='
      },
      data: data
    }).then((res) => res.data);
      
     // if (response.resp == 1)
       return response;
      //if (response.country) return response.country;
     // console.log(response.user); 
    } catch (err) {
      console.log("Error", err);
    }
  };  

  const _fetchDisToP = async (data) => {
    try {
     
      let url = BASE_URL + "/District_to_Police_Station";
     // let response = await axios.get(url).then((res) => res.data);      
     let response = await  axios({
      method: 'post',
      url: url,
      headers: {
       // 'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization':'Basic YnJva2VyOmJyb2tlcl8xMjM='
      },
      data: data
    }).then((res) => res.data);
      
     // if (response.resp == 1)
       return response;
      //if (response.country) return response.country;
     // console.log(response.user); 
    } catch (err) {
      console.log("Error", err);
    }
  };  

  const _fetchDisToCNo = async (data) => {
    try {
     
      let url = BASE_URL + "/checkeCaseNumberAvailability";
     // let response = await axios.get(url).then((res) => res.data);      
     let response = await  axios({
      method: 'post',
      url: url,
      headers: {
       // 'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization':'Basic YnJva2VyOmJyb2tlcl8xMjM='
      },
      data: data
    }).then((res) => res.data);
      
     // if (response.resp == 1)
       return response;
      //if (response.country) return response.country;
     // console.log(response.user); 
    } catch (err) {
      console.log("Error", err);
    }
  };  

  const _fetchCatToCT = async (data) => {
    try {
     
      let url = BASE_URL + "/Case_Category_to_Case_Type";
     // let response = await axios.get(url).then((res) => res.data);      
     let response = await  axios({
      method: 'post',
      url: url,
      headers: {
       // 'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization':'Basic YnJva2VyOmJyb2tlcl8xMjM='
      },
      data: data
    }).then((res) => res.data);
      
     // if (response.resp == 1)
       return response;
      //if (response.country) return response.country;
     // console.log(response.user); 
    } catch (err) {
      console.log("Error", err);
    }
  };  


  const _fetchCTToCM = async (data) => {
    try {
     
      let url = BASE_URL + "/case_type_to_court_no";
     // let response = await axios.get(url).then((res) => res.data);      
     let response = await  axios({
      method: 'post',
      url: url,
      headers: {
       // 'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization':'Basic YnJva2VyOmJyb2tlcl8xMjM='
      },
      data: data
    }).then((res) => res.data);
      
     // if (response.resp == 1)
     //console.log(response);
       return response;
      //if (response.country) return response.country;
     // console.log(response.user); 
    } catch (err) {
      console.log("Error", err);
    }
  };  

 const fetchData=()=>{ 
 

 // console.log('hh '+chamber_id);
  const userData = {
    chamber_id:authUser.chamberId,   
  
};
  _fetchUserData(userData)
    .then((data) => {
      if (!data) return;
   //  console.log(data.file_location);
   
      setCourtDivision( data.court_division.map(marker =>{ 
        marker.key = marker.Value
         marker.label = marker.Text 
          return marker
      }));
      setCourtDistrict( data.District.map(marker =>{ 
        marker.key = marker.Value
         marker.label = marker.Text 
          return marker
      }));
      setPoliceStation( data.policeStation.map(marker =>{ 
        marker.key = marker.Value
         marker.label = marker.Text 
          return marker
      }));
      setCourtCategory( data.jurisdiction.map(marker =>{ 
        marker.key = marker.Value
         marker.label = marker.Text 
          return marker
      }));
      setCourtType( data.case_name.map(marker =>{ 
        marker.key = marker.Value
         marker.label = marker.Text 
          return marker
      }));
      setCourtName( data.courts.map(marker =>{ 
        marker.key = marker.Value
         marker.label = marker.Text 
          return marker
      }));

      setProducts( data.chamber_all_user.map(marker =>{ 
       
         marker.isChecked = true 
          return marker
      }));
       setfileLocation( data.file_location.map(marker =>{ 
          marker.key = marker.Value
          marker.label = marker.Text 
          
            return marker
        }));

        setPosition( data.position.map(marker =>{ 
          marker.key = marker.Value
          marker.label = marker.Text 
          
            return marker
        }));
        

    })
    .catch((err) => console.log(err)); 
  

 
 }

 const fetchCourt_divisionOnchange=(c_id)=>{ 
    // console.log('hh '+chamber_id);
   const userData = {
    court_division_id:c_id,      
   
 };
 const userData1 = {
  division_id:c_id,      
 
};
const userData2 = {
  court_distribute_id:c_id,      
 
};
   _fetchDToC(userData)
     .then((data) => {
       if (!data) return;       
       setCourtName( data.map(marker =>{ 
         marker.key = marker.case_court_id
          marker.label = marker.case_court_name 
           return marker
       }));
     })
     .catch((err) => console.log(err));   

     _fetchDToJ(userData1)
     .then((data) => {
       if (!data) return;       
       setCourtCategory( data.map(marker =>{ 
         marker.key = marker.name
          marker.label = marker.name 
           return marker
       }));
     })
     .catch((err) => console.log(err));   

     _fetchDToCT(userData2)
     .then((data) => {
       if (!data) return;       
       setCourtType( data.map(marker =>{ 
         marker.key = marker.id
          marker.label = marker.case_name 
           return marker
       }));
     })
     .catch((err) => console.log(err));   
  }

  const fetchCase_CategoryOnchange=(c_id)=>{ 
      const userData1 = {
        jurisdiction:c_id,   
      };

     _fetchCatToCT(userData1)
     .then((data) => {
       if (!data) return;       
       setCourtType( data.map(marker =>{ 
        marker.key = marker.id
         marker.label = marker.case_name 
          return marker
      }));
     })
     .catch((err) => console.log(err));   

     
  }

  const fetchCase_TypeOnchange=(c_id)=>{ 
    const userData1 = {
      case_type_id:c_id,   
    };

   _fetchCTToCM(userData1)
   .then((data) => {
     if (!data) return;   
    // console.log(data);       
     setCourtName( data.map(marker =>{ 
      marker.key = marker.case_court_id
       marker.label = marker.case_court_name 
        return marker
    }));
   })
   .catch((err) => console.log(err));     
}


  const fetchCourt_districtOnchange=(c_id)=>{ 
    // console.log('hh '+chamber_id);

 const userData1 = {
  district_id:c_id,      
 
};


     _fetchDisToP(userData1)
     .then((data) => {
       if (!data) return;    
     
       setPoliceStation( data.map(marker =>{ 
        marker.key = marker.Value
         marker.label = marker.Text 
          return marker
      }));
     })
     .catch((err) => console.log(err));   

     
  }

  const fetchcase_number_availability=(c_id)=>{ 
    // console.log('hh '+chamber_id);
   const userData = {
    district_id:c_id,  

   }

   _fetchDisToCNo(userData)
     .then((data) => {
       if (!data) return;       
       setCourtName( data.map(marker =>{ 
         marker.key = marker.case_court_id
          marker.label = marker.case_court_name 
           return marker
       }));
     })
     .catch((err) => console.log(err));   

   
  }

  //fetch user data upon screen foucus


  useFocusEffect(
    React.useCallback(() => {
     
   
  //      console.log('test '+data);

   }, [])
  );

  //handle profile update
  const handleProfileUpdate = (values, actions) => {
    setIsSubmitting(true);
   // const { FullName,   email,  address,  mobile,  phone,  altemail,  country_id, division_id,  district_id } = values;
// console.log("rr "+pickedCategory.key);
    const caseData = {
      thana_case_no:"",
      arising_out_of:"",
      visible_to:"",
      entry_date:"",
      contract_amount:"",
      assigned_to:"",
      case_number:"",
      related_law_and_section:"",
      chamber_id: authUser.chamberId,   
      user_id:authUser.userId,
      versus: values.versus,    
      versus1: values.versus1,  
      court_id: pickedCourtName.key, 
      registration_no: values.registration_no,   
      case_filing_date: select_Filing_date? moment(select_Filing_date).format("DD-MM-YYYY"):"",  
      wakalatnama_entry_no:values.wakalatnama_entry_no,
      wakalatnama_entry_date: select_date? moment(select_date).format("DD-MM-YYYY"):"",    
      partiesBehalfOne: current,    
      partiesBehalfTwo: "", 
      arising_out_of_string: values.arising_out_of_string,
      client_id:values.client_id,
      client_mobile:values.client_mobile,
      client_email:values.client_email,
      practice_area: "",    
      thana_id: pickedPoliceStation.key,    
      case_status: "", 
      contract_type: "",    
      is_bulk_insert: "",    
      case_no: values.case_no,   
      case_year: "",  
      position: pickedPosition.key, 
      position1: pickedVsPosition.key, 
      jurisdiction: pickedCategory.key, 
      district_id: pickedDistrict.key, 
      petition_lawyer:values.petition_lawyer,
      petition_lawyer_contact_no:values.petition_lawyer_contact_no,
      opposing_advocate:values.opposing_advocate,
      opposing_advocate_contact_no:values.opposing_advocate_contact_no,
      description:values.description,
      file_location:pickedLocation.key,
      court_division_id:picked.key, 
      case_type: pickedType.key, 
      entry_user: authUser.userId, 
      case_name: "", 
      case_unique_id: "",    
    };
   //console.log(caseData);
    //perform api call to update password
    _saveBasicInfo(caseData)
      .then((user) => {
       console.log(user);
        if (!user) return;
        else{
         // console.log(user);
         // setUser(values);
          alert("Successfully updated");
         // fetchData();
        }
        // updateAuthUserName(`${user.FullName}`).then(() => {
        //   console.log(user);
        //   setUser(values);
        //   alert("Successfully updated");
        // });
      })
      .catch((err) => console.log(err))
      .then(() => setIsSubmitting(false))
      .then(() =>setIsEditing(!isEditing));
  };

  //save user data
  const _saveBasicInfo =  (data) => {
    console.log(data);
  //  return data;
    try {
      let url = apiPathLocal + "/CaseCreate";
     // let response = await axios.post(url, data).then((res) => res.data);
     let formBody = [];
    for (let key in data) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(data[key]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    formBody = formBody.join('&');

     let response =   axios.post(url, formBody)
    //   axios({
    //   method: 'post',      
    //   url: url,
    //   headers: {
    //    // 'Content-Type': 'application/x-www-form-urlencoded',
    //     'Accept': 'application/json',
    //     'Authorization':'Basic YnJva2VyOmJyb2tlcl8xMjM='
    //   },
    //   data: data
    // })
    .then((res) => res.data);
    // console.log(response);
     return response;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    }
  };

  //update gender formik value
  const updateGender = (handleChange, value) => {
    handleChange(value);  };

  const onShow = () => {   setIsVisible(true); };
  const onShowDistrict = () => {   setIsVisibleDistrict(true);  };
  const onShowPoliceStation = () => {   setIsVisiblePoliceStation(true);  };
  const onShowCategory = () => {   setIsVisibleCategory(true);  };
  const onShowType = () => {   setIsVisibleType(true);  };
  const onShowCourtName = () => {   setIsVisibleCourtName(true);  };
  const onShowPosition = () => {   setIsVisiblePosition(true);  };
  const onShowVsPosition = () => {   setIsVisibleVsPosition(true);  };
  const onShowLocation  = () => {   setIsVisibleLocation (true);  }; 
  const onCancel = () => {    setIsVisible(false);   };
  const  onCancelDistrict = () => {    setIsVisibleDistrict(false);   };
  const  onCancelPoliceStation = () => {    setIsVisiblePoliceStation(false);   };
  const onCancelCategory = () => {    setIsVisibleCategory(false);   };
  const  onCancelType  = () => {    setIsVisibleType(false);   };
  const onCancelPosition = () => {    setIsVisiblePosition(false);   };
  const onCancelVsPosition = () => {    setIsVisibleVsPosition(false);   };
  const onCancelCourtName = () => {    setIsVisibleCourtName(false);   };
  const onCancelLocation  = () => {    setIsVisibleLocation (false);   };

  const  onSelect = (handleChange,data) => {
   // console.log(data);
   // const {key,label} = data;
   handleChange(data.label);
  fetchCourt_divisionOnchange(data.key);
      setIsVisible(false);
      if(data.key == 6){setIsHide(true);}
      else {setIsHide(false);}
      setIsPicked({
        key:data.key,
        label:data.label,
      });
      setIsPickedDistrict({
        key:"",
        label:"",
      });
      setIsPickedPoliceStation({
        key:"",
        label:"",
      });
      setIsPickedCategory({
        key:"",
        label:"",
      });
      setIsPickedType ({
        key:"",
        label:"",
      });
      setIsPickedCourtName({
        key:"",
        label:"",
      });
    };
    const  onSelectDistrict = (handleChange,data) => {    
        setIsVisibleDistrict(false);
        handleChange(data.label);
        setIsPickedDistrict({
          key:data.key,
          label:data.label,
        });

      };
      const  onSelectPoliceStation = (data) => {    
        setIsVisiblePoliceStation(false);
        setIsPickedPoliceStation({
          key:data.key,
          label:data.label,
        });
      };
      const onSelectCategory = (handleChange,data) => {     
        setIsVisibleCategory(false);
        handleChange(data.label);
        setIsPickedCategory({
          key:data.key,
          label:data.label,
        });
        fetchCase_CategoryOnchange(data.key);
      };
      const  onSelectType  = (handleChange,data) => {
      //  console.log(data);
       // const {key,label} = data;
       handleChange(data.label);
          setIsVisibleType (false);
          setIsPickedType ({
            key:data.key,
            label:data.label,
          });
          fetchCase_TypeOnchange(data.key);
        };
        const   onSelectPosition = (handleChange,data) => {
        //  console.log(data);
         // const {key,label} = data;
         handleChange(data.label);
            setIsVisiblePosition(false);
            setIsPickedPosition({
              key:data.key,
              label:data.label,
            });
          };
          const   onSelectCourtName = (handleChange,data) => {
        //  console.log(data);
         // const {key,label} = data;
         handleChange(data.label);
            setIsVisibleCourtName(false);
            setIsPickedCourtName({
              key:data.key,
              label:data.label,
            });
          }
          const  onSelectVsPosition = (handleChange,data) => {
           // console.log(data);
           // const {key,label} = data;
           handleChange(data.label);
              setIsVisibleVsPosition(false);
              setIsPickedVsPosition({
                key:data.key,
                label:data.label,
              });
            };

            const doSomething = async (value) => {
              // do something with my select value onChange
              await trigger(['court_division_id']) // Trigger validation on select
            };

            const  onSelectLocation  = (data) => {
             // console.log(data);
             // const {key,label} = data;
             
                setIsVisibleLocation(false);
                setIsPickedLocation({
                  key:data.key,
                  label:data.label,
                });
              };

          //checkbox start
          const handleChange = (id) => {
            let temp = products.map((product) => {
              if (id === product.id) {
                return { ...product, isChecked: !product.isChecked };
              }
              return product;
            });
            setProducts(temp);
          };

          const handleallselect = () => {
            setProducts( products.map(marker =>{ 
       
              marker.isChecked = isallChecked?false:true
               return marker
           }));
           setAllChecked(!isallChecked);
          };
        
          let selected = products.filter((product) => product.isChecked);
        
          const renderFlatList = (renderData) => {
            return (
              <FlatList
                data={renderData}
                keyExtractor={(item, index) => index.toString()}
                renderItem={({ item }) => (
                  <Card style={{ margin: 5 }}>
                    <View style={styles.card}>
                      <View
                        style={{
                          flexDirection: 'row',
                          flex: 1,
                          justifyContent: 'space-between',
                        }}>
                        <Checkbox
                          value={item.isChecked}
                          onValueChange={() => {
                            handleChange(item.id);
                          }}
                        />
                        <Text>{item.name}</Text>
                      </View>
                    </View>
                  </Card>
                )}
              />
            );
          };
          // checkbox end


          //date picker strat
          const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
          const [select_date, setselectdate] = useState(null);
          const [select_Filing_date, setselectdfilinfate] = useState(null);
          
              const showDatePicker = () => {
                setDatePickerVisibility(true);
              };

              const hideDatePicker = () => {
                setDatePickerVisibility(false);
              };

              const handleConfirm = (val) => {
              // console.warn("A date has been picked: ", date);
            //console.log(val);
                setselectdate(  val );
                //console.log(select_date);
                hideDatePicker();
              };

              const handleConfirmFiling = (val) => {
                // console.warn("A date has been picked: ", date);
              //console.log(val);
              setselectdfilinfate(  val );
                  //console.log(select_date);
                  hideDatePicker();
                };
          // date picker end

  return (
    <ContainerFluid>
      <ScrollView >
        <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
          <View style={styles.basicInfoWrapper}>

            {error && <ErrorMessage>{error}</ErrorMessage>}

            <Formik
              enableReinitialize
               initialValues={{ ...user }}
              validationSchema={basicInfoSchema}
              onSubmit={(values, actions) => {
                handleProfileUpdate(values, actions);
              }}
              innerRef={formikElement}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <>
                  <FormGroup>
                    <InputLevel>Courts/Division* :</InputLevel>
                    {isEditing ? (
                     <View>
                     <TouchableOpacity  onPress={onShow}>
                     <BasicFormInput
                      value={picked.label}
                      onTouchStart={onShow}
                     // showSoftInputOnFocus={false}
                      onChangeText={handleChange("court_division_id")}
                      onBlur={handleBlur("court_division_id")}
                      placeholder={"--Select Courts/Division--"}
                      isEditing={isEditing}

                    />
                     </TouchableOpacity>      
                     
                     <ModalFilterPicker
                       visible={visible}
                      // onSelect={onSelect} 
                      onSelect= {(value) => {
                        onSelect(handleChange("court_division_id"), value);
                      }}
                       onCancel={onCancel}
                       options={courtDivision}
                       modal={{transparent: true}} 
                        overlayStyle={ { flex: 1,                       
                        marginTop:80,                     
                        alignItems: 'center',
                        justifyContent: 'center',  }}                   
                     />
                   </View> ):(
                      <Text> </Text>
                    )}
                    {touched.court_division_id && errors.court_division_id ? (
                      <ErrorText>{errors.court_division_id}</ErrorText>
                    ) : null}
                  </FormGroup> 
                      
                  <FormGroup>
                    <InputLevel>District Name* :</InputLevel>
                    {isEditing ? (
                     <View>
                     <TouchableOpacity  onPress={onShowDistrict}>
                     <BasicFormInput
                      value={pickedDistrict.label}
                      onTouchStart={onShowDistrict}
                     // showSoftInputOnFocus={false}
                      onChangeText={handleChange("district_id")}
                      onBlur={handleBlur("district_id")}
                      placeholder={"--Select District Name--"}
                      isEditing={isEditing}

                    />
                     </TouchableOpacity>      
                     
                     <ModalFilterPicker
                       visible={visibleDistrict}
                      // onSelect={onSelectDistrict}
                       onSelect= {(value) => {
                        onSelectDistrict(handleChange("district_id"), value);
                      }}
                       onCancel={onCancelDistrict}
                       options={Districts}
                       modal={{transparent: true}} 
                        overlayStyle={ { flex: 1,                       
                        marginTop:80,                     
                        alignItems: 'center',
                        justifyContent: 'center',  }}                   
                     />
                   </View> ):(
                      <Text> </Text>
                    )}
                    {touched.district_id && errors.district_id ? (
                      <ErrorText>{errors.district_id}</ErrorText>
                    ) : null}
                  </FormGroup>
                  {isHide ? (
                  <FormGroup>
                    <InputLevel>PoliceStation* :</InputLevel>
                    {isEditing ? (
                     <View>
                     <TouchableOpacity  onPress={onShowPoliceStation}>
                     <BasicFormInput
                      value={pickedPoliceStation.label}
                      onTouchStart={onShowPoliceStation}
                     // showSoftInputOnFocus={false}
                      onChangeText={handleChange("thana_id")}
                      onBlur={handleBlur("thana_id")}
                      placeholder={"--Select Police Station Name--"}
                      isEditing={isEditing}
                    />
                     </TouchableOpacity>      
                     
                     <ModalFilterPicker
                       visible={visiblePoliceStation}
                       onSelect={onSelectPoliceStation}
                       onCancel={onCancelPoliceStation}
                       options={PoliceStations}
                       modal={{transparent: true}} 
                        overlayStyle={ { flex: 1,                       
                        marginTop:80,                     
                        alignItems: 'center',
                        justifyContent: 'center',  }}                   
                     />
                   </View> ):(
                      <Text> </Text>
                    )}
                    {touched.thana_id && errors.thana_id ? (
                      <ErrorText>{errors.thana_id}</ErrorText>
                    ) : null}
                  </FormGroup>
                  ):null }
                    <FormGroup>
                    <InputLevel>Case Category* :</InputLevel>
                    {isEditing ? (
                      <>
                    <BasicFormInput
                     value={pickedCategory.label}
                     onTouchStart={onShowCategory}
                      onChangeText={handleChange("jurisdictions")}
                      onBlur={handleBlur("jurisdictions")}
                      placeholder={"--Select Case Category--"}
                      isEditing={isEditing}
                    /> 
                    <ModalFilterPicker
                    visible={visibleCategory}
                   // onSelect={onSelectCategory}
                    onSelect= {(value) => {
                      onSelectCategory(handleChange("jurisdictions"), value);
                    }}
                    onCancel={onCancelCategory}
                    options={Categories}
                    modal={{transparent: true}} 
                     overlayStyle={ { flex: 1,                       
                     marginTop:80,                     
                     alignItems: 'center',
                     justifyContent: 'center',  }}                   
                  /></>
                    ):(
                      <Text> {"+88"+(values?.Mobile?.toString())}</Text>
                    )}
                    {touched.jurisdictions && errors.jurisdictions ? (
                      <ErrorText>{errors.jurisdictions}</ErrorText>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <InputLevel>Case Type* :</InputLevel>
                    {isEditing ? (
                      <>
                    <BasicFormInput
                     value={pickedType.label}
                     onTouchStart={onShowType}
                      onChangeText={handleChange("case_type")}
                      onBlur={handleBlur("case_type")}
                      placeholder={"--Select Case Type--"}
                      isEditing={isEditing}
                    /> 
                    <ModalFilterPicker
                    visible={visibleType}
                   // onSelect={onSelectType}
                   onSelect= {(value) => {
                    onSelectType(handleChange("case_type"), value);
                  }}
                    onCancel={onCancelType}
                    options={Types}
                    modal={{transparent: true}} 
                     overlayStyle={ { flex: 1,                       
                     marginTop:80,                     
                     alignItems: 'center',
                     justifyContent: 'center',}}                   
                  /></>
                    ):(
                      <Text> {"+88"+(values?.Type?.toString())}</Text>
                    )}
                    {touched.case_type && errors.case_type ? (
                      <ErrorText>{errors.case_type}</ErrorText>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <InputLevel>Court Name/No.* :</InputLevel>
                    {isEditing ? (
                      <>
                    <BasicFormInput
                     value={pickedCourtName.label}
                     onTouchStart={onShowCourtName}
                      onChangeText={handleChange("court_id")}
                      onBlur={handleBlur("court_id")}
                      placeholder={"--Select Court No.--"}
                      isEditing={isEditing}
                    /> 
                    <ModalFilterPicker
                    visible={visibleCourtName}
                    //onSelect={onSelectCourtName}
                    onSelect= {(value) => {
                      onSelectCourtName(handleChange("court_id"), value);
                    }}
                    onCancel={onCancelCourtName}
                    options={CourtNames}
                    modal={{transparent: true}} 
                     overlayStyle={ { flex: 1,                       
                     marginTop:80,                     
                     alignItems: 'center',
                     justifyContent: 'center',  }}                   
                  /></>
                    ):(
                      <Text></Text>
                    )}
                    {touched.court_id && errors.court_id ? (
                      <ErrorText>{errors.court_id}</ErrorText>
                    ) : null}
                  </FormGroup>
                 
                  <FormGroup>
                    <InputLevel>Case No.* :</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     // value={values.Designation}
                      onChangeText={handleChange("case_no")}
                      onBlur={handleBlur("case_no")}
                      placeholder={"Case No./Year (Foure Digit)"}
                      isEditing={isEditing}
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.case_no && errors.case_no ? (
                      <ErrorText>{errors.case_no}</ErrorText>
                    ) : null}
                  </FormGroup> 

                  <View style={{backgroundColor:'#F0E68C'}}>
                  <FormGroup>
                    <InputLevel>Name of parties* :</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     // value={values.Designation}
                      onChangeText={handleChange("versus")}
                      onBlur={handleBlur("versus")}
                      placeholder={"Name of parties"}
                      isEditing={isEditing}
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.versus && errors.versus ? (
                      <ErrorText>{errors.versus}</ErrorText>
                    ) : null}
                  </FormGroup> 
                  <FormGroup>
                    <InputLevel>parties Position* :</InputLevel>
                    {isEditing ? (
                      <>
                    <BasicFormInput
                     value={pickedPosition.label}
                     onTouchStart={onShowPosition}
                      onChangeText={handleChange("position")}
                      onBlur={handleBlur("position")}
                      placeholder={"--Select Position--"}
                      isEditing={isEditing}
                    /> 
                    <ModalFilterPicker
                    visible={visiblePosition}
                   // onSelect={onSelectPosition}
                    onSelect= {(value) => {
                      onSelectPosition(handleChange("position"), value);
                    }}
                    onCancel={onCancelPosition}
                    options={positions}
                    modal={{transparent: true}} 
                     overlayStyle={ { flex: 1,                       
                     marginTop:80,                     
                     alignItems: 'center',
                     justifyContent: 'center',}}                   
                  /></>
                    ):(
                      <Text>..</Text>
                    )}
                    {touched.position && errors.position ? (
                      <ErrorText>{errors.position}</ErrorText>
                    ) : null}
                  </FormGroup> 
               
                  <RadioButtonGroup
                    containerStyle={{  marginBottom: 10 ,backgroundColor:'#F0E68C'}}
                    selected={current}
                    onSelected={(value) => setCurrent(value)}
                    radioBackground="green"
                    radioStyle={{marginLeft:"33%",marginBottom:10,marginTop:10}}
                  > 
                  <RadioButtonItem  value="oboradioone" label="On Behalf Of" />
                  
                  <View style={{backgroundColor:'#fff'}}>
                  <Text style={{textAlign:'center'}}>VS</Text>
                  </View>
                  <RadioButtonItem value="oboradiotwo" label="On Behalf Of" />
                  </RadioButtonGroup>

                  <FormGroup>
                    <InputLevel> Name of parties* :</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     // value={values.Designation}
                      onChangeText={handleChange("versus1")}
                      onBlur={handleBlur("versus1")}
                      placeholder={"Name of parties"}
                      isEditing={isEditing}
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.versus1 && errors.versus1 ? (
                      <ErrorText>{errors.versus1}</ErrorText>
                    ) : null}
                  </FormGroup> 

                  <FormGroup>
                    <InputLevel>Parties Position.* :</InputLevel>
                    {isEditing ? (
                      <>
                    <BasicFormInput
                     value={pickedVsPosition.label}
                     onTouchStart={onShowVsPosition}
                      onChangeText={handleChange("position1")}
                      onBlur={handleBlur("position1")}
                      placeholder={"--Select Position--"}
                      isEditing={isEditing}
                    /> 
                    <ModalFilterPicker
                    visible={visibleVsPosition}
                    //onSelect={onSelectVsPosition}
                    onSelect= {(value) => {
                      onSelectVsPosition(handleChange("position1"), value);
                    }}
                  
                    onCancel={onCancelVsPosition}
                    options={positions}
                    modal={{transparent: true}} 
                     overlayStyle={ { flex: 1,                       
                     marginTop:80,                     
                     alignItems: 'center',
                     justifyContent: 'center',  }}                   
                  /></>
                    ):(
                      <Text></Text>
                    )}
                    {touched.position1 && errors.position1 ? (
                      <ErrorText>{errors.position1}</ErrorText>
                    ) : null}
                  </FormGroup>
                  
                
                  </View>
                  

               
                  {/* {isEditing ? (
                  <View style={{ marginTop: 20, paddingHorizontal: 10 }}>
                    <AppBtn
                     title={isShowMore ? 'Less' :  "More"}
                     
                     width={100} 
                      onPress={setIsShowMore(!isShowMore) }
                     // disabled={isSubmitting ? true: false}
                    />
                  </View>
                  ):null}
                   */}
                 
                   <TouchableOpacity  onPress={() => { setIsShowM(!isShowM);  }} 
                    style={{ backgroundColor: isShowM? '#028482':'#5578eb',marginVertical:10, padding:10, width: 70, borderRadius:13 }} >
                    <View><Text style={{textAlign:'center', color:'#fff'}} >{isShowM?'Less': 'More'}</Text></View>
                   </TouchableOpacity>
{ isShowM? ( <>
                  <FormGroup>
                    <InputLevel>Registration No. (Old) :</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     // value={values.Designation}
                      onChangeText={handleChange("registration_no")}
                      onBlur={handleBlur("registration_no")}
                      placeholder={"Designation"}
                      isEditing={isEditing}
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.registration_no && errors.registration_no ? (
                      <ErrorText>{errors.registration_no}</ErrorText>
                    ) : null}
                  </FormGroup> 

                  <FormGroup>
                    <InputLevel>Client Name :</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     // value={values.Designation}
                      onChangeText={handleChange("client_id")}
                      onBlur={handleBlur("client_id")}
                      placeholder={"Client Name"}
                      isEditing={isEditing}
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.client_id && errors.client_id ? (
                      <ErrorText>{errors.client_id}</ErrorText>
                    ) : null}
                  </FormGroup> 
                  <FormGroup>
                    <InputLevel>Mobile :</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     // value={values.Designation}
                      onChangeText={handleChange("client_mobile")}
                      onBlur={handleBlur("client_mobile")}
                      placeholder={"Designation"}
                      isEditing={isEditing}
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.client_mobile && errors.client_mobile ? (
                      <ErrorText>{errors.client_mobile}</ErrorText>
                    ) : null}
                  </FormGroup> 
                  <FormGroup>
                    <InputLevel>& Email :</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     // value={values.Designation}
                      onChangeText={handleChange("client_email")}
                      onBlur={handleBlur("client_email")}
                      placeholder={"Designation"}
                      isEditing={isEditing}
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.client_email && errors.client_email ? (
                      <ErrorText>{errors.client_email}</ErrorText>
                    ) : null}
                  </FormGroup> 
                  <FormGroup>
                    <InputLevel>Arising Out Of :</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     // value={values.Designation}
                      onChangeText={handleChange("arising_out_of_string")}
                      onBlur={handleBlur("arising_out_of_string")}
                      placeholder={"Designation"}
                      isEditing={isEditing}
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.arising_out_of_string && errors.arising_out_of_string ? (
                      <ErrorText>{errors.arising_out_of_string}</ErrorText>
                    ) : null}
                  </FormGroup> 
                  <FormGroup>
                    <InputLevel>Wakalatnama No. :</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     // value={values.Designation}
                      onChangeText={handleChange("wakalatnama_entry_no")}
                      onBlur={handleBlur("wakalatnama_entry_no")}
                      placeholder={"Designation"}
                      isEditing={isEditing}
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.wakalatnama_entry_no && errors.wakalatnama_entry_no ? (
                      <ErrorText>{errors.wakalatnama_entry_no}</ErrorText>
                    ) : null}
                  </FormGroup> 
                  <FormGroup>
                    <InputLevel>Wakalatnama Entry Date :</InputLevel>
                    {isEditing ? (
                    // <BasicFormInput
                    //  // value={values.Designation}
                    //   onChangeText={handleChange("wakalatnama_entry_date")}
                    //   onBlur={handleBlur("wakalatnama_entry_date")}
                    //   placeholder={"Designation"}
                    //   isEditing={isEditing}
                    // /> 
                    <>
                    <BasicFormInput
                    value={select_date !==null ? moment(select_date).format("DD-MM-YYYY") : ""}
                    onChangeText={handleChange("wakalatnama_entry_date")}
                    onBlur={handleBlur("wakalatnama_entry_date")}
                    placeholder={"MM-DD-YYYY"}
                    isEditing={isEditing}
                    onTouchStart={showDatePicker}
                  />
                   
                    <DateTimePickerModal
                        isVisible={isDatePickerVisible}
                        mode="date"
                       // date={ values?.application_deadline? moment(values?.application_deadline).format("DD-MM-YYYY") : ""}
                        onConfirm={handleConfirm}
                        onCancel={hideDatePicker}
                        minimumDate={new Date()}
                       
                       // minimumDate={("YYYY, MM, DD")}
                       // maximumDate={moment('08-06-2022').add(30, 'days').format("DD-MM-YYYY")}
                      />
                    </>
                    
                    
                    ):(
                      <Text> </Text>
                    )}
                    {touched.wakalatnama_entry_date && errors.wakalatnama_entry_date ? (
                      <ErrorText>{errors.wakalatnama_entry_date}</ErrorText>
                    ) : null}
                  </FormGroup> 
                  <FormGroup>
                    <InputLevel>Case Filing Date :</InputLevel>
                    {isEditing ? (
                   <>
                   <BasicFormInput
                   value={select_Filing_date !==null ? moment(select_Filing_date).format("DD-MM-YYYY") : ""}
                   onChangeText={handleChange("case_filing_date")}
                   onBlur={handleBlur("case_filing_date")}
                   placeholder={"MM-DD-YYYY"}
                   isEditing={isEditing}
                   onTouchStart={showDatePicker}
                 />
                  
                   <DateTimePickerModal
                       isVisible={isDatePickerVisible}
                       mode="date"
                      // date={ values?.application_deadline? moment(values?.application_deadline).format("DD-MM-YYYY") : ""}
                       onConfirm={handleConfirmFiling}
                       onCancel={hideDatePicker}
                       minimumDate={new Date()}
                      
                      // minimumDate={("YYYY, MM, DD")}
                      // maximumDate={moment('08-06-2022').add(30, 'days').format("DD-MM-YYYY")}
                     />
                   </> ):(
                      <Text> </Text>
                    )}
                    {touched.case_filing_date && errors.case_filing_date ? (
                      <ErrorText>{errors.case_filing_date}</ErrorText>
                    ) : null}
                  </FormGroup> 
                  <FormGroup>
                    <InputLevel>Petitioner Advocate :</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     // value={values.Designation}
                      onChangeText={handleChange("petition_lawyer")}
                      onBlur={handleBlur("petition_lawyer")}
                      placeholder={"Designation"}
                      isEditing={isEditing}
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.petition_lawyer && errors.petition_lawyer ? (
                      <ErrorText>{errors.petition_lawyer}</ErrorText>
                    ) : null}
                  </FormGroup> 
                  <FormGroup>
                    <InputLevel>& Contact No. :</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     // value={values.Designation}
                      onChangeText={handleChange("petition_lawyer_contact_no")}
                      onBlur={handleBlur("petition_lawyer_contact_no")}
                      placeholder={" Contact No."}
                      isEditing={isEditing}
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.petition_lawyer_contact_no && errors.petition_lawyer_contact_no ? (
                      <ErrorText>{errors.petition_lawyer_contact_no}</ErrorText>
                    ) : null}
                  </FormGroup> 
                  <FormGroup>
                    <InputLevel>Opposite Advocate :</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     // value={values.Designation}
                      onChangeText={handleChange("opposing_advocate")}
                      onBlur={handleBlur("opposing_advocate")}
                      placeholder={"Designation"}
                      isEditing={isEditing}
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.opposing_advocate && errors.opposing_advocate ? (
                      <ErrorText>{errors.opposing_advocate}</ErrorText>
                    ) : null}
                  </FormGroup> 
                  <FormGroup>
                    <InputLevel>& Contact No. :</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     // value={values.Designation}
                      onChangeText={handleChange("opposing_advocate_contact_no")}
                      onBlur={handleBlur("opposing_advocate_contact_no")}
                      placeholder={"Designation"}
                      isEditing={isEditing}
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.opposing_advocate_contact_no && errors.opposing_advocate_contact_no ? (
                      <ErrorText>{errors.opposing_advocate_contact_no}</ErrorText>
                    ) : null}
                  </FormGroup> 
                  <FormGroup>
                    <InputLevel>Short Description :</InputLevel>
                    {isEditing ? (
                    <BasicFormInput
                     // value={values.Designation}
                      onChangeText={handleChange("description")}
                      onBlur={handleBlur("description")}
                      placeholder={"Short Description"}
                      isEditing={isEditing}
                    /> ):(
                      <Text> </Text>
                    )}
                    {touched.description && errors.description ? (
                      <ErrorText>{errors.description}</ErrorText>
                    ) : null}
                  </FormGroup> 
                  <FormGroup>
                    <InputLevel>Assigned To :</InputLevel>
                    {isEditing ? (
                    
                  <View>
                     <Card style={{ margin: 5 }}>
                    <View style={styles.card}>
                      <View
                        style={{
                          flexDirection: 'row',
                          flex: 1,
                          justifyContent: 'space-between',
                        }}>
                        <Checkbox
                        style={styles.checkboxs}
                          value={isallChecked}
                          onValueChange={() => {
                            handleallselect();
                          }}
                        />
                        <Text>Select All</Text>
                      </View>
                    </View>
                  </Card>
                  <View>{renderFlatList(products)}</View>
                  </View>
                    
                   
                   
                    ):(
                      <Text> </Text>
                    )}
                    {touched.Designation && errors.Designation ? (
                      <ErrorText>{errors.Designation}</ErrorText>
                    ) : null}
                  </FormGroup> 



                  <FormGroup>
                    <InputLevel>File Location :</InputLevel>
                    {isEditing ? (
                      <>
                    <BasicFormInput
                     value={pickedLocation.label}
                     onTouchStart={onShowLocation}
                      onChangeText={handleChange("file_location")}
                      onBlur={handleBlur("file_location")}
                      placeholder={"File Location"}
                      isEditing={isEditing}
                    /> 
                    <ModalFilterPicker
                    visible={visibleLocation}
                    onSelect={onSelectLocation}
                    onCancel={onCancelLocation}
                    options={fileLocation}
                    modal={{transparent: true}} 
                     overlayStyle={ { flex: 1,                       
                     marginTop:80,                     
                     alignItems: 'center',
                     justifyContent: 'center',  }}                   
                  /></>
                    ):(
                      <Text></Text>
                    )}
                    {touched.file_location && errors.file_location ? (
                      <ErrorText>{errors.file_location}</ErrorText>
                    ) : null}
                  </FormGroup>
                </>
):null}



                 {/* submit button  */}
                 
                  {isEditing ? (
                  <TouchableOpacity   onPress={() => { handleSubmit; Keyboard.dismiss() }}>
                  <View style={{ marginTop: 20, paddingHorizontal: 10 }}>
                    <AppBtn
                      title="Submit"
                      onPress={handleSubmit}
                      disabled={isSubmitting ? true: false}
                    />
                  </View>
                  </TouchableOpacity>
                  ):null }
                
                </>
              )}
            </Formik>
          </View>
        </TouchableWithoutFeedback>
      </ScrollView>
    </ContainerFluid>
  );
}

export default NewCaseEntry;
async function getChamberId() {
  try {
    const jsonValue = await AsyncStorage.getItem("chamber_id")
    if(jsonValue !== null){
      //console.log("chamber_id: "+JSON.parse(jsonValue));
      return JSON.parse(jsonValue); 
    }
  } catch(err) {
    console.error(err);
  }
};

const styles = StyleSheet.create({
  checkboxContainer: {
    flexDirection: "row",
    marginBottom: 20,
  },
  card: {
    padding: 3,
    margin: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  text: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  checkbox: {
    alignSelf: "center",
  },
  checkboxs: {
   width:25,
   height:25
  },
  label: {
    margin: 8,
  },

  basicInfoWrapper: {
    flex: 1,
    paddingHorizontal: 30,
    marginTop: 20,
    marginBottom: 30,
    justifyContent: "center",
  },

  pickerWrapper: {
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 4,
  },
  picker: {
    height: 45,
    color: "#333",
  },


});
