// Import React and Component
import React, {useState, createRef, useEffect, useRef} from 'react';
import {
  TouchableWithoutFeedback,
  StyleSheet,
  TextInput,
  View,
  Text,
  ScrollView,
  Image,
  Keyboard,
 // TouchableOpacity,
  KeyboardAvoidingView,
  Dimensions,
  Linking,
  Button,
  SafeAreaView,
  ActivityIndicator,
  Animated,
  FlatList,
  LogBox,
  Pressable,
  Alert
} from 'react-native';

import {
  responsiveHeight,
  responsiveWidth,
  responsiveFontSize
} from "react-native-responsive-dimensions";


import { BASE_URL } from '../Components/BaseUrl';
import { apiPathLocal } from "../utils/constants/Consts";
import Loader from '../Components/Loader';
import DiaryBox from "../shared/updateDiary";
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { TouchableOpacity } from "react-native-gesture-handler";
import SelectDropdown from 'react-native-select-dropdown';
import { useFocusEffect,useIsFocused, } from "@react-navigation/native";

import Modal from "react-native-modal";
import AppBtn from "../shared/appBtn";
import SearchInput, { createFilter } from 'react-native-search-filter';
import HTML from "react-native-render-html";
import {decode} from 'html-entities';
//import { ReactNativeZoomableView } from '@openspacelabs/react-native-zoomable-view';
import { Formik } from "formik";
import * as yup from "yup";
import FormGroup from "../shared/formGroup";
import InputLevel from "../shared/inputLevel";
import BasicFormInput from "../shared/basicFormInput";
import ErrorText from "../shared/errorText";
import ContainerFluid from "../shared/containerFluid";
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import moment from "moment";

const FullJudgment = ({route, navigation }) => {
  //const { itemId } = route.params;
  const isFocused = useIsFocused();
  const [mailloading, setMailLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(true); 
  const [nullbody, setNullbody] = useState(true);
  const [caseNumber, setCaseNumber] = useState('');
  const [asWord, setAssociatesWord] = useState('');
  const [statuses, setStatuses] = useState(0);
  const [chamber_id, setChamber_id] = useState('');
  const [user_id, setUser_id] = useState('');
  const [endLoader, setEndLoader] = useState(1);
  const [errortext, setErrortext] = useState('');
  const [Sotrtotal_case, setTotal_case] = useState([]);
  const [judgeName, setJudgeName] = useState([]);
  const [fullJudgement, setTotalJudgement] = useState([]);
  const [DivisionList, setTotalDivision] = useState([]);
  const [CaseNmaeList, setTotalCaseName] = useState([]);
 // const [isModalVisible, setModalVisible] = useState(false);
  const [focusLength, setFocusLength] = useState(true);
  const [statusCname, setStatusCname] = useState(0);

  const [total_case, setFilteredDataSource] = useState([]);
  const formikElement = createRef(null); 
 // State to store count value
 const [count, setCount] = useState(0);
 const KEYS_TO_FILTERS = ['as_name', 'associates_id','as_contact_no','as_present_address'];


 const CaseStatus = ["All", "Pending", "Disposed"];

 const judgmentarray = (value,key) => {

  var jvalue = value.toString().replace(/[,\s]{2,}/,"").replace(/,+(?=,|$)/g, '').replace(/^,*/,"").replace(/,*$/,"");
  
  if(jvalue ===", "){
    return null;
     }
     else{
      return ("<p>"+jvalue+"</p>");
     }

  
 // return ("<p>"+jvalue.replace(/,+(?=,|$)/g, '') + "</p>"); 

};
const [topsCrollShow, setTopsCrollShow] = useState(false);
const [ref, setRef] = useState(null);
const scrollHandler = () => {
  //console.log(dataSourceCords.length, scrollToIndex);
  //if (dataSourceCords.length > scrollToIndex) {
    setTopsCrollShow(true);
    ref.scrollTo({
      x: 0,
      y: 1150,
      animated: true,
    });
  // } else {
  //   alert('Out of Max Index');
  // }
};
const scrollToTop = () => {
  //console.log(dataSourceCords.length, scrollToIndex);
  //if (dataSourceCords.length > scrollToIndex) {
  // setTopsCrollShow(false);
    ref.scrollTo({
      x: 0,
      y: 0,
      animated: true,
    });
  // } else {
  //   alert('Out of Max Index');
  // }
};




 const customJ = (obj) => {
  let  newObj = {};

  var judgmentD = obj.judgment_date.toString().split(' ');
  var judg_title= "";
  var judge_date = "";
 if (judgmentD.length >0){
  judg_title= judgmentD[0];
  judge_date = judgmentD[1];
  var date_parse = judge_date.toString().split('-');
            var day = date_parse[0];
            var month = date_parse[1];
            var year = date_parse[2];
            var final_datef = month + "-" + day + "-" + year;

            final_datef = moment(judge_date.toString(), 'DD-MM-YYYY').format("MMMM DD, yyyy");
            if (judg_title == "Judgment")
            {
                judg_title = "Judgement Date : ";
            }
            else if (judg_title == "Order")
            {
                judg_title = "Order Date : ";
            }
            else if (judg_title == "Hearing")
            {
                judg_title = "Hearing Date : ";
            }
            judge_date = judg_title + " " + final_datef;

 }

    newObj.division_name = obj.division_name;
    newObj.caseno = obj.caseno;
    newObj.jud_no = obj.jud_no;
    newObj.judgment_date = judge_date;
    newObj.jud_year = obj.jud_year;
    newObj.parties = obj.parties;
    newObj.jurisdiction= obj.jurisdiction;
    newObj.case_source= obj.case_source;
    newObj.lawyer= obj.lawyer;
    
    
   // newObj.full_judgment = obj.full_judgment;
   var judgment = obj.full_judgment.toString();
  var Clearnewline = str => str.split(/\r?\n/).filter(line => line.trim() !== '').join('\n');
    
  var full_judgment=Clearnewline(judgment);
  full_judgment = full_judgment.replace(/\n/g, "</p><p>");
  //full_judgment = full_judgment.replace(/\n/g, "\n\n");
  //full_judgment = full_judgment.replace(/\n/g, "<br>");
   
 // full_judgment = full_judgment.replace(/<(?!br\s*\/?)[^>]+>/g, '');
  
   //var judgarr = full_judgment.split('<br>');
  // var judgarr = full_judgment.split(/\r?\n/);
  //let judgarrs = judgarr.map(judgmentarray);
 // console.log(judgarrs);
   newObj.full_judgment = full_judgment;   
    
    return newObj;
};


 useFocusEffect(
  React.useCallback(() => {
  
    //async fetch user data
    setTotalJudgement([]);
    setFocusLength(false);
    setStatuses(0);
    setAssociatesWord('');
    setCaseNumber('');
    handleSubmitPress(route.params?.itemId);
  
  }, [route])
);

   useEffect(() => {
    window.history.pushState(null, null, document.URL);
    getChamberId().then(chamberId => setChamber_id(chamberId));
     handleSubmitPress(route.params?.itemId)
  }, []);





  const handleSubmitPress = (data) => {

    
    setErrortext('');
    
    /*
    if (!caseNumber) {
      alert('Please fill Case Number');
      return;
    }
    if (!case_year) {
      alert('Please fill Case Year');
      return;
    }*/

    let statusesVal = "";
    if(statuses === undefined || statuses === "")
    {
      statusesVal = 0;
    }
    else
    {
      statusesVal = statuses;
    }

    setLoading(true);
    setNullbody(true);


    //console.log("======="+case_year);

    let dataToSend = { judid: data};
    let formBody = [];
    for (let key in dataToSend) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(dataToSend[key]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    formBody = formBody.join('&');

  // console.log(formBody);     

      try {

        let url = BASE_URL + "/CopyJudgment";
 
         axios.post(url,formBody).then(response => {
             //Hide Loader
            setLoading(false);
          // console.log(response.data.result.resultt);               
           // let newArr = response.data.result.resultt.map(customJ);
           let newArr = response.data.result.pdfGenerate.map(customJ);
           const output = Object.assign({}, ...newArr)
           let JudgeName = response.data.result.justiceName;
           // console.log(newArr); 
           setJudgeName(JudgeName);
          // console.log(JudgeName); 
           setTotalJudgement(output);
          //  setFilteredDataSource(response.data);
          //  setTotalDivision(response.data.result.court_division_list);
        //    setTotalCaseName(response.data.result.case_name_list);
            setNullbody(false);
            setEndLoader(1);
            setFocusLength(true);
            setLoading(false);
            //console.log(fullJudgement);

         }).catch((error) => {
          //Hide Loader
        
          console.error(error);
        });
        
       } 
       catch (err) 
       {
         console.error(err);
       }

  };
  const zoomAnimatedValue = React.useRef(new Animated.Value(1)).current;
  const scale = Animated.divide(1, zoomAnimatedValue);

  const getChamberId = async () => {
    try {
      const value = await AsyncStorage.getItem('chamber_id');
      const user_id = await AsyncStorage.getItem('user_id');
      if (value !== null) {
        //console.log(value);
        setChamber_id(value);
        setUser_id(user_id);
        return value;
      //  chamber_id = value
      }
    } catch (e) {
      // error reading value
    }
  };

  const incrementCount = () => {
    // Update state with incremented value
    setCount(count + 1);
  };

  const onEndReached = () => {
    //console.log('end reached');
    setEndLoader(0);
  }
  const toggleModal = () => {
    createPdf();
    //setModalVisible(!modalVisible);
  };
  const [modalVisible, setModalVisible] = useState(false);

    const [modalEntry, setModalEntry] = useState();

  const ListFooterComponent = (
    <View style={styles.listFooter}>
      <ActivityIndicator animating={true} size="large" color="#00ff00" />
    </View>
  )

  const searchFilterFunction = (text) => {
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource
      // Update FilteredDataSource
      const filteredEmails = Sotrtotal_case.filter(createFilter(text, KEYS_TO_FILTERS, [{caseSensitive:false, fuzzy:true}]))

    

      setFilteredDataSource(filteredEmails);
    //  setSearch(text);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setFilteredDataSource(Sotrtotal_case);
     // setSearch(text);
    }
  };
// const judge =  judgeName?.map(function (value, index) { return ( <View style={{flexDirection:'row'}} key={index}>
//   value.justice_type === 1 ? <><Text style={[styles.judgeText,{fontSize:14}]}>{value.name.toString()}</Text><Text>Chief Justice</Text></>:<Text style={[styles.judgeText,{fontSize:14}]}>{value.name.toString()}</Text>
//   </View> )});
   
const _fetchUserData = async (judgementDtata) => {

  
  try {
  
        let dataToSend =judgementDtata;
        let formBody = [];
        for (let key in dataToSend) {
          let encodedKey = encodeURIComponent(key);
          let encodedValue = encodeURIComponent(dataToSend[key]);
          formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');
        let url = BASE_URL + "/generatePdfForSendingMail?";
       // let response = await axios.get(url).then((res) => res.data);      
       let response = await   axios.post(url, formBody).then((res) => res.data);        
       // if (response.resp == 1)
         return response;
        //if (response.country) return response.country;
       // console.log(response.user); 
      } catch (err) {
        console.log("Error", err);
      }
};   

const createPdf=()=>{ 
 

  // console.log('hh '+chamber_id);
  const judgementDtata = {        
     
      Id:route.params?.itemId,
      ChamberId:chamber_id,    
     
    };
    console.log(judgementDtata);
    setMailLoading(true);
   _fetchUserData(judgementDtata)
     .then((data) => {
      setMailLoading(false);
       if (!data) return;
     
    if(data.status ==="1"){
     
     // setModalVisible(false);
     Alert.alert( "Your Dayly Limit",
     "Your Dayly Limit Over");
     // alert("Your Dayly Limit Over");
    }
    else if(data.msg ==="1"){
     
     // setModalVisible(false);
     // alert("Your Monthly Limit Over");
      Alert.alert( "Your Monthly Limit",
      "Your Monthly Limit Over");
    }
   else {  setModalVisible(true);}
   // console.log(data.msg);
   //setModalVisible(!modalVisible);
     })
     .catch((err) => console.log(err)); 
  
  }

  const _sendMail = async (caseData) => {

     try {
       
        let dataToSend =caseData;
        let formBody = [];
        for (let key in dataToSend) {
          let encodedKey = encodeURIComponent(key);
          let encodedValue = encodeURIComponent(dataToSend[key]);
          formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');
        let url = BASE_URL + "/sendMail?";
       // let response = await axios.get(url).then((res) => res.data);      
       let response = await   axios.post(url, formBody).then((res) => res.data);        
       // if (response.resp == 1)
         return response;
        //if (response.country) return response.country;
       // console.log(response.user); 
      } catch (err) {
        console.log("Error", err);
      }
    
  };   
  
  const sendMail=(values, actions)=>{ 
   
  
    // console.log('hh '+chamber_id);
    setMailLoading(true);
    const caseData = {        
     
      ChamberId:chamber_id,
      userLoginID:user_id,
      email:values.email,
      description:values.description,
      jujmentid:route.params?.itemId,
     
    };
    //console.log(caseData);
    _sendMail(caseData)
       .then((data) => {
        setMailLoading(false);
        setModalVisible(false);
        Alert.alert('Share Pdf Judgement','Successfully sent mail.')
         if (!data) return;
      //  console.log(data.file_location);
     // console.log(data.court_division_name);
     Alert.alert('Share Pdf Judgement', data.msg)
       })
       .catch((err) => console.log(err)); 
    
    }

const list = () => {
  return judgeName.map((element,index) => {
    return (
      <View key={index.toString()} style={styles.judgeTop }>
       { element.justice_type === 1 ? <Text key={element.key} style={{fontSize:14, textAlign:'center', width:'100%' }}>{element.name}, Chief Justice</Text>:null } 
       { element.justice_type === 2 ? <Text key={element.key} style={{fontSize:14, textAlign:'center', width:'100%' }}>{element.name}</Text>:null } 
      </View>
    );
  });
};


  return (
    
    <View style={styles.container}>
        {mailloading == true &&
                      <SafeAreaView style={styles.main_loader}>
                        <View style={{ flex: 1, backgroundColor:'#dcf3f2',  justifyContent: "center", alignItems: "center", paddingTop:200, paddingBottom:600, height: responsiveHeight(100),  }}>
                          <Image
                              source={require('../loader/loop-loading.gif')}
                              style={{
                                width: '120%',
                                height: 150,
                                resizeMode: 'contain',
                                margin: 0,
                              }}
                            />
                        </View>
                      </SafeAreaView>
                    }
          <Modal isVisible={modalVisible}  
            onSwipeComplete={() => setModalVisible(false)}
            animationType="slide"
            transparent={true}
            //onBackdropPress={() => setModalVisible(false)}
            swipeDirection={['left', 'right','down','up']}>
            <View style={{flexDirection:'column',height:350}}>
        <View  style={{ flex:1, flexDirection:'row',alignItems:'center', backgroundColor:'#5cb85c',padding:10,borderTopLeftRadius:13,borderTopRightRadius:13}} >
          <Text style={{fontSize:22, color:'#fff', marginLeft:30, fontWeight:'bold', marginRight:30}}>Share Pdf Judgement</Text><Pressable  onPress={() => setModalVisible(false)}>
               <FontAwesome     name="times"  size={36} color='#FF0000'   ></FontAwesome></Pressable>
        </View>

              <View style={{flex:6,alignItems:'center', backgroundColor:'#f3dede',padding:10,borderBottomLeftRadius:13,borderBottomRightRadius:13}}>          
              {mailloading == true?
                      <View style={styles.main_loaderemail}>
                        <View style={{ flex: 1, backgroundColor:'#dcf3f2', padding:130, justifyContent: "center", alignItems: "center", height:350, width:'100%' }}>
                          <Image
                              source={require('../loader/loop-loading.gif')}
                              style={{
                                width: '120%',
                                height: 150,
                                resizeMode: 'contain',
                                margin: 0,
                              }}
                            />
                        </View>
                      </View>:<TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
                    <Formik
                      enableReinitialize
                      initialValues={{ email:"",description:"" }}
                    //  validationSchema={basicInfoSchema}
                      onSubmit={(values, actions) => {
                        sendMail(values, actions);
                      }}
                      innerRef={formikElement}
                    >
                      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                        <>
                          <View style={{marginBottom:20}}></View>  
                          <FormGroup>
                          <View style={{flexDirection:'column'}}>
                            <Text>Email:</Text>
                          
                            <TextInput
                            // value={values.Designation}                        
                              onChangeText={handleChange("email")}
                              onBlur={handleBlur("email")}
                              placeholder={"Email"}
                             style={styles.input}
                            /></View>
                            {touched.email && errors.email ? (
                              <ErrorText>{errors.email}</ErrorText>
                            ) : null}
                          </FormGroup> 
                          <View style={{marginBottom:10}}></View>     
                          <FormGroup>
                            <View style={{flexDirection:'column'}}>
                          <Text>Description:</Text>
                          
                            <TextInput
                            // value={values.Designation}
                              onChangeText={handleChange("description")}
                              onBlur={handleBlur("description")}
                              placeholder={"Description"}
                              style={styles.input}
                              numberOfLines={4}
                            /></View>
                            {touched.description && errors.description ? (
                              <ErrorText>{errors.description}</ErrorText>
                            ) : null}
                          </FormGroup>
                          <TouchableOpacity   onPress={() => { handleSubmit; Keyboard.dismiss() }}>
                          <View style={{ marginTop: 20, paddingHorizontal: 10 }}>
                            <AppBtn
                              title="Send Mail"
                              onPress={handleSubmit}
                             // disabled={isSubmitting ? true: false}
                            />
                          </View>
                          </TouchableOpacity>                       
                        
                        </>
                      )}
                    </Formik>
                    </TouchableWithoutFeedback>
                     }
              </View>
           
              </View>

             </Modal>


      <View style={{ marginTop:0, flexDirection: "row", flexWrap: "wrap", marginLeft: 0}}>
     

      

      </View> 
 
      <SafeAreaView style={styles.container}>
                  
                  {/* {loading == true &&
                    <View style={{ flex: 1, justifyContent: "center", alignItems: "center", marginTop:-300 }}>
                      <ActivityIndicator animating size="large" color="#00ff00" />
                    </View>
                  } */}
          
                  {loading == true &&
                      <SafeAreaView style={styles.main_loader}>
                        <View style={{ flex: 1, backgroundColor:'#dcf3f2',  justifyContent: "center", alignItems: "center", paddingTop:200, paddingBottom:400, height: '100%',  }}>
                          <Image
                              source={require('../loader/loop-loading.gif')}
                              style={{
                                width: '120%',
                                height: 150,
                                resizeMode: 'contain',
                                margin: 0,
                              }}
                            />
                        </View>
                      </SafeAreaView>
                    }
                 
          
                    {nullbody  == false &&       
                  
          
                       <View style={  (!endLoader &&  fullJudgement)? styles.main_containers : styles.main_container }  >
                         
                       {fullJudgement &&   
                      
                       <View style={styles.main_body}>
          
                      
                        
                         {/* <Text style={styles.TotalCaseFound}>Total Example Case Found: {total_case.length}</Text> */}
          {/* <ScrollView onScrollBeginDrag={() => setTopsCrollShow(true) } ref={(ref) => {
            setRef(ref);
          }} > */}
          <ScrollView contentContainerStyle={{ flex: 1 }}>
                          {/* <FlatList  data={fullJudgement}
                                keyExtractor={(item, index) => index.toString()}
                                showsVerticalScrollIndicator={false}
                                bounces={false}
                                numColumns={1}  renderItem={({ item, index }) => ( */}
                              <View  style={{width:responsiveWidth(98.5), height:responsiveHeight(80), }}>                         
          
         
                                  <View style={styles.hddata} >
                                    <View style={{alignContent:'center', justifyContent:'center',alignItems:'center', marginBottom:10}}>
                                    <AppBtn
                                        title="Share Pdf Judgement"
                                        onPress={toggleModal}
                                       // disabled={isSubmitting ? true: false}
                                       BGcolor='#000'
                                       width={responsiveWidth(65)}
                                      />
                                    </View>
                                
                                    <View style={styles.judgeTop}>
                                     <View style={{width:'100%'}}>
                                    <Text style={[styles.judgeText,{fontSize:20, lineHeight: 21}]}>{fullJudgement.division_name}</Text>
                                    </View>
                                    {fullJudgement.jurisdiction?<View style={{width:'100%'}}><Text style={[styles.judgeText,{fontSize:16,lineHeight: 17}]}>{fullJudgement.jurisdiction}</Text></View> :null}
                                   <View style={{width:'100%'}}>
                                    <Text style={[styles.judgeText,{fontSize:16, lineHeight: 17, }]}></Text>
                                    </View> 
                                    {list()} 
                                    <View style={{width:'100%'}}> 
                                    <Text style={[styles.judgeText,{fontSize:16, lineHeight: 17}]}></Text> 
                                    </View><View style={{width:'100%'}}>
                                    <Text style={[styles.judgeText,{fontSize:20, lineHeight: 21}]}>{fullJudgement.caseno}</Text>
                                    </View>
                                  {fullJudgement.case_source?<View style={{width:'100%'}}><Text style={[styles.judgeText,{fontSize:14}]}>{fullJudgement.case_source}</Text></View> :null}
                                  <View style={{width:'100%'}}>
                                    <Text style={[styles.judgeText,{fontSize:16, lineHeight: 17}]}></Text>
                                    </View><View style={{width:'100%'}}>
                                    <Text style={[styles.judgeText,{fontSize:16, lineHeight: 17}]}>{fullJudgement.parties}</Text>
                                    </View><View style={{width:'100%'}}>
                                    <Text style={[styles.judgeText,{fontSize:16, lineHeight: 17}]}></Text>
                                    </View>
                                    <View style={{width:'100%'}}>
                                    <Text style={[styles.judgeText,{fontSize:19 }]}>{fullJudgement.judgment_date}</Text>
                                    </View>
                                    <View style={{width:'100%'}}>
                                    <Text style={[styles.judgeText,{fontSize:16, lineHeight: 17}]}></Text>
                                    </View><View style={{width:'100%'}}>
                                    <Text style={[styles.judgeTextL,{fontSize:16, lineHeight: 17}]}>{fullJudgement.lawyer}</Text>
                                    </View><View style={{width:'100%'}}>
                                    <Text style={[styles.judgeText,{fontSize:16, lineHeight: 17}]}></Text>
                                    </View><View style={{width:'100%'}}>
                                    <Text style={[styles.judgeText,{fontSize:20, lineHeight: 21}]}>Judgement</Text>
                                    </View>
                                </View>
                                    <View style={{ color:'#000', width:'99%', flexDirection: "row", padding: 0, marginBottom:20 }}>
                                     {/* <Text style={tagsStyles.p}>{fullJudgement.full_judgment?.toString().replace(/<p[^>]*>/g, "").replace(/<\/p[^>]*>/g, "\n\n")}</Text>  */}
                                     <HTML
                                   source = {{
                                    html: `<p>${decode(fullJudgement.full_judgment?.toString())}</p>`
                                      } }
                                      tagsStyles={tagsStyles}
                                      contentWidth={responsiveWidth(93)}
                                    />   
                                    </View >
          
                                   <Text style={{textAlign:'justify', fontSize:14, color:'#ac5006'}}>* N.B. - Some errors may be noticed in the uploaded judgments. The judgments are continuously being edited/updated. If you have found any mistake in this judgment, please inform us. Thank you.</Text>
                                 
                                </View>
                                
                                </View>
                               {/* )}
          
          
                                removeClippedSubviews={true}
                                initialNumToRender={1}
                                maxToRenderPerBatch={1}
                                onEndReachedThreshold={0.1}
                                onEndReached={onEndReached}
                               // refreshing={refreshing}
                                updateCellsBatchingPeriod={1}
                               // ListFooterComponent={() => (endLoader && fullJudgement.length > 0) ? ListFooterComponent :null}
                                windowSize={10}
                              />   */}
          </ScrollView>
                      </View>
                    
                       }
          
          
                                  { !endLoader &&  fullJudgement && (() => {
                                      return ( 
                                    <View style={styles.main_body_two}> 
                                        <Text style={{textAlign:"center", fontSize: 20}}>
                                            End.
                                        </Text>     
                                      </View>   
                                                      
                                  )})()
                                  }
                              { fullJudgement === null && (() => {
                                        return ( 
                                          <View style={styles.main_body_three}> 
                                          <Text style={{textAlign:"center", color:"#f00", fontWeight:"bold", fontSize: 20}}>
                                          Judgement not found.
                                          </Text>     
                                        </View>
                                                        
                                    )})()
                                    }
                    </View>
          
          
                    }
          
                  </SafeAreaView> 
                  {topsCrollShow? <View  style={styles.btnClickContain}><TouchableOpacity
                      onPress={scrollToTop}
                     >
                      
                          <FontAwesome     name="arrow-up"  size={30} color='#FFf'   ></FontAwesome>
                       
                        
                      
                    </TouchableOpacity></View>:null }
      </View>
    );
};
 
export default FullJudgment;


// async function getChamberId() {
//   try {
//     const jsonValue = await AsyncStorage.getItem("chamber_id")
//     if(jsonValue !== null){
//       //console.log("chamber_id: "+JSON.parse(jsonValue));
//       return JSON.parse(jsonValue); 
//     }
//   } catch(err) {
//     console.error(err);
//   }
// };

const tagsStyles = StyleSheet.create( {

  h1: {
    color: '#000',
    
    marginBottom: 13,
    backgroundColor: '#ff0'
  },
  img: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20
  },
  // p: {
  //   textAlign: "justify",
  //   fontSize: 15, color:'#000'
  //  // width:responsiveWidth(92),
  // },
  p: {
    textAlign: "left",
    fontSize: 16, color:'#000',
   width:responsiveWidth(92),
   lineHeight: 20,
 
 
  },
  ul: { fontSize: 12, color:'#333111', textAlign:"justify" },                              
  li:{textAlign:"justify"}
});
const styles = StyleSheet.create({
  btnClickContain: {
    //flex: 1,
    //position: 'absolute',
    width: 50,
    height: 50,
    alignSelf:'flex-end',
    alignItems: 'center',
    justifyContent: 'center',
    right: 10,
    bottom: 10,
    marginTop: -50,
    borderRadius: 10,
   // marginRight:100,
  // marginBottom: 100,
   backgroundColor:'#05eff7',
   opacity: 0.57,
    zIndex:99999999,
  },
judgeTop:{
width:'98%',
  alignItems:"center",
 textAlign: "center",
 //flexWrap: 'wrap',

},
judgeTextL:{

  color:'#000',
  textAlign: "left",
  includeFontPadding: false,
  flexShrink:1,
  textDecorationStyle: "solid",

},

judgeText:{

  color:'#000',
  textAlign: "center",
  textDecorationStyle: "solid",
  flexShrink:1,
  //includeFontPadding: false

},
   // Use
   viewButton: {
    backgroundColor: '#0040ff',
   width:80,
    borderRadius:13,
    padding:2,
    alignItems:"center",
    marginLeft:50,
   },
   materButton: {
    backgroundColor: '#00bc77',
   width:150,
    borderRadius:13,
    padding:2,
    alignItems:"center",
    marginLeft:50,
   },
   viewButtonText: {
    color: '#fff',
    fontSize: 18,
    textTransform: "capitalize",
    textAlign: "center",
   },
   dropdown4BtnStyle: {
    width: 140,
    height: 32,
    backgroundColor: '#307ecc',
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#f00',
  },

  dropdown4BtnTxtStyle: {color: '#fff', textAlign: 'center'},
  dropdown4DropdownStyle: {backgroundColor: '#f00'},
  dropdown4RowStyle: {backgroundColor: '#307ecc', borderBottomColor: '#dadae8'},
  dropdown4RowTxtStyle: {color: '#white', textAlign: 'left'},


  SectionStyle: {
    flexDirection: 'row',
    height: 32,
    margin: 2,
    width:85,
  },

  SectionStyleYear: {
    flexDirection: 'row',
    height: 32,
    margin: 2,
    width:140,
  },

  SectionStyleStatus: {
    flexDirection: 'row',
    height: 32,
    margin: 1,
    width:150,
  },

  inputStyle: {
    flex: 1,
    backgroundColor: '#307ecc',
   
    color: 'white',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: '#f00',
    fontWeight:'bold',
    textAlign: 'center',
  },
  buttonStyleAdd: {
    backgroundColor: '#2E8B57',
    borderWidth: 0,
    color: '#2E8B57',
    borderColor: '#2E8B57',
    height: 35,
    alignItems: 'center',
    borderRadius: 30,
    width:60,
  },
  buttonStyle: {
    backgroundColor: '#f00',
    borderWidth: 0,
    color: '#f00',
    borderColor: '#f00',
    height: 35,
    alignItems: 'center',
    borderRadius: 30,
    width:70,
  },


  buttonStyleSearch: {
    backgroundColor: '#f00',
    borderWidth: 0,
    color: '#f00',
    borderColor: '#f00',
    height: 35,
    alignItems: 'center',
    borderRadius: 30,
    width:80,
  },

  buttonTextStyle: {
   // color: '#FFFFFF',
    paddingVertical: 6,
    fontSize: 16,
  },

  registerTextStyle: {
   // color: '#FFFFFF',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'center',
    padding: 10,
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },
  titleText:{
    fontSize: 30,
    alignItems: 'center',
    justifyContent: 'center',
   // color : "#fff",
  },
  
  form: {
    backgroundColor: "#ccc",
    flex: 1,
    alignItems: "center", // ignore this - we'll come back to it
    justifyContent: "center", // ignore this - we'll come back to it
    flexDirection: "row",
  },

  listFooter: {
    paddingVertical: 1,
    marginTop:2,
    marginBottom:30,

    
    // borderTopWidth: 1,
    // borderColor: "#CED0CE"
  },


  container: {
    marginTop:0,
    flex: 1,
    alignItems: "center",
    // justifyContent: "center",
    //backgroundColor: "#0373BB",
    backgroundColor: "#fff",
  },
  indicator: {
    backgroundColor: 'black',
    borderRadius: 3,
    width: 6,
  },
  details: {
    padding: 5,
    borderWidth: .5,
    borderColor: "red",
    borderRadius: 6,
    // width: 300,
    marginTop:10,
    marginLeft:20
  },

  main_container:{
    borderRadius: 4,
    width:responsiveWidth(100),
    // borderWidth: 4,
    borderLeftWidth:4,
    borderRightWidth:4,
    borderBottomWidth:4,
    borderColor: "#ccc",
    height:'100%',

  },

  main_containers:{
    borderRadius: 4,
    width:responsiveWidth(100),
    // borderWidth: 4,
    borderLeftWidth:4,
    borderRightWidth:4,
    borderBottomWidth:4,
    borderColor: "#ccc",
    height:'100%',
    paddingBottom:67,
    color:"#fff",
  },

  main_loaderemail:{
  width:'100%',
    backgroundColor: "#dcf3f2",
    zIndex:99999999
  },
  main_loader:{
    width:responsiveWidth(100),
    backgroundColor: "#dcf3f2",
  },

  main_body: {
    padding: 5,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 6,
    marginBottom:0,
    backgroundColor: "#FFF",
    width:responsiveWidth(98),
  },


  main_body_two: {
    padding: 7,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 6,
    marginTop:3,
    marginBottom:0,
    backgroundColor: "#FFF",
    width:responsiveWidth(98),
  },

  TotalCaseFound:{
    fontSize: 14,
    textAlign:'center',
    fontWeight:'bold',
    width:responsiveWidth(100),
  },

  textTile:{
    fontSize: 14,
    alignItems: "center",
    justifyContent: "center",
    width:responsiveWidth(28),
    color:"#000" 
  },

  CaseTypeNoTile:{
    fontSize: 14,
    alignItems: "center",
    justifyContent: "center",
    width:99,
  },

  textTilecln:{
    fontSize: 16,
    alignItems: "center",
    justifyContent: "center",
    width:8,
    color:"#000"
  },

  textDescription:{
    paddingTop:3,
    fontSize: 13,
    width:responsiveWidth(60),
    color:"#000"
  },
  textDescriptionfull:{
    paddingTop:3,
    fontSize: 13,
    textAlign:"justify",
    width:responsiveWidth(91),
    color:"#000"
  },

  textParties:{
    fontSize: 8,
    paddingTop:8,
  },


   titleText: {
    fontSize: 30,
    alignItems: "center",
    justifyContent: "center",
  },

  LawyerInfo: {
    padding: 5,
    borderWidth: 1,
    borderColor: "white",
    borderRadius: 6,
  },
  LawyerInfoText: {
    fontSize: 15,
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },

  button: {
    alignItems: "center",
    backgroundColor: "#419641",
    width: 120,
    height: 36,
    padding: 3,
    borderWidth: 1,
    borderColor: "white",
    borderRadius: 6,
    marginTop: 10,
    marginLeft:10
  },

  buttonRefresh: {
    alignItems: "center",
    backgroundColor: "#FFFFBD",
    width: 75,
    height: 36,
    paddingTop: 5,
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 3,
    borderWidth: 1,
    borderColor: "red",
    color:"#000",
    borderRadius: 6,
    marginTop: 10,
    marginLeft:10,
  },

  buttonNexDate: {
    alignItems: "center",
    backgroundColor: "#FFFFBD",
    width: 85,
    height: 36,
    paddingTop: 5,
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 3,
    borderWidth: 1,
    borderColor: "red",
    color:"#000",
    borderRadius: 6,
    marginTop: 10,
    marginLeft:10
  },

  buttonTextRefresh: {
    fontSize: 16,
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },

  buttonText: {
    fontSize: 20,
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },
  input: {
    width: 290,
    fontSize: 18,
   // height: 36,
    paddingLeft: 10,
    borderWidth: 1,
    marginTop: 0,
    borderColor: "#aaa",
    backgroundColor:'#fff',
    color: "#000000",
    marginVertical: 0,
    borderRadius: 2,
    marginLeft:10,
  },

  item: {
    backgroundColor: "#f9c2ff",
    padding: 20,
    marginVertical: 8,
  },
  header: {
    fontSize: 32,
    backgroundColor: "#fff",
  },
  title: {
    fontSize: 24,
  },

  SectionHeaderStyle: {
    backgroundColor: "#CDDC89",
    fontSize: 20,
    padding: 5,
    color: "#fff",
  },

  SectionListItemStyle: {
    fontSize: 15,
    padding: 15,
    color: "#000",
    backgroundColor: "#F5F5F5",
  },

  hddata: {
    padding: 5,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 6,
    marginTop:5,
    marginBottom:0,
   // backgroundColor: "#f9f9f9",
    color:"#000",
  },
  main_body_two: {
    padding: 7,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 6,
    marginTop:3,
    marginBottom:0,
    backgroundColor: "#FFF",
    width:responsiveWidth(98),
  },
  main_body_three: {
    padding: 7,
    borderWidth: 1,
    borderColor: "red",
    borderRadius: 6,
    marginTop:3,
    marginBottom:20,
    backgroundColor: "#FFF",
    width:responsiveWidth(98),
  },


  hddataText: {
    fontSize: 20,
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },

  hddataResult: {
    padding: 5,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 6,
    marginTop:5,
    marginLeft:7,
    marginRight:7,
    backgroundColor: "#FFFFBD",
  },

  totalfound: {
    fontSize: 14,
    alignItems: "center",
    color: "#fff",
  },



  noItems: {
    fontSize: 24,
    alignItems: "center",
    justifyContent: "center",
    color: "#FFF",
    paddingLeft:90,
    width:300,
    paddingTop:20,
    paddingBottom:20,

    textShadowColor: 'rgba(0, 0, 0,0.9)',
    textShadowOffset: {width: -1, height: 2},
    textShadowRadius: 15
  },

  errorColor: {
    color:'coral',
  },

  isDraft: {
    fontSize: 20,
    alignItems: "center",
    justifyContent: "center",
    //: "#fff",
    marginLeft:10,
  },

  CaseResultDate: {
    padding: 5,
    borderWidth: 1,
    borderColor: "white",
    borderRadius: 6,
    marginLeft:5,
    marginRight:6,
    backgroundColor: "#fff",
    color: "#000",
    alignItems: "center",
    justifyContent: "center",
  },

  get_date: {
    fontSize: 17,
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },

  last_datetime: {
    fontSize: 14,
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },

  fadingContainer: {
    backgroundColor: "blueviolet",
    borderRadius:4,
    margin: 20,
  },

  fadingText: {
    fontSize: 16,
    textAlign: "center",
   // color : "#fff",
    paddingVertical: 5,
    paddingHorizontal: 25,
  },

});