// Import React and Component
import React, {useState, createRef, useEffect, useRef}  from 'react';
import {View, Image, Text,StyleSheet, SafeAreaView,FlatList, ActivityIndicator,LogBox,Button, Linking, } from 'react-native';
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import {
  responsiveHeight,
  responsiveWidth,
  responsiveFontSize
} from "react-native-responsive-dimensions";
import Icon from "../shared/icon";
import { BASE_URL } from '../Components/BaseUrl';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from "@react-navigation/native";
import moment from "moment";
import{ ImagesAssets } from '../utils/constants/Consts';
 
const LcmsScreen = ({route, navigation}) => {

  const [toDaysCaseInfo, setToDaysCaseInfo] = useState(false);
  const [previousCaseInfo, setPreviousCaseInfo] = useState(false);
  const [toCaseDiaryInfo, setToCaseDiaryInfo] = useState(false);
  const [toDateOverCaseInfo, setToDateOverCaseInfo] = useState(false);
  const [todaysAppointment, setTodaysAppointment] = useState(false);
  const [toDaysTask, setToDaysTask] = useState(false);
  const [chamber_id, setChamberId] = useState('');
  const [password, setPassword] = useState('');
 
  
  const [focusLength, setFocusLength] = useState(true);
  const [CaseDiary, setCaseDiary] = useState([]);
  const [TodayCase, setTodayCase] = useState([]);
  const [PreviousCase, setPreviousCase] = useState([]);
  const [Task, setTask] = useState([]);
  const [Appointment, setAppointment] = useState([]);
  const [DateOverCase, setDateOverCase] = useState([]);
  const [refreshing, setRefreshing] = useState(true); 
  const [loading, setLoading] = useState(false);
  const [nullbody, setNullbody] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const [nullbody1, setNullbody1] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [nullbody2, setNullbody2] = useState(true);
  const [loading3, setLoading3] = useState(false);
  const [nullbody3, setNullbody3] = useState(true);
  const [loading4, setLoading4] = useState(false);
  const [nullbody4, setNullbody4] = useState(true);
  const [loading5, setLoading5] = useState(false);
  const [nullbody5, setNullbody5] = useState(true);

  const [endLoader, setEndLoader] = useState(1);
  const [endLoader1, setEndLoader1] = useState(1);
  const [endLoader2, setEndLoader2] = useState(1);
  const [endLoader3, setEndLoader3] = useState(1);
  const [endLoader4, setEndLoader4] = useState(1);
  const [endLoader5, setEndLoader5] = useState(1);
  const [userAccessStatus, setUserAccessStatus] = useState([]);

  const onEndReached = () => {
    //console.log('end reached');
    setEndLoader(0);
  }
  const onEndReached1 = () => {
    //console.log('end reached');
    setEndLoader1(0);
  }
  const onEndReached2 = () => {
    //console.log('end reached');
    setEndLoader2(0);
  }
  const onEndReached3 = () => {
    //console.log('end reached');
    setEndLoader3(0);
  }
  const onEndReached4 = () => {
    //console.log('end reached');
    setEndLoader4(0);
  }
  const onEndReached5 = () => {
    //console.log('end reached');
    setEndLoader5(0);
  }
  useEffect(() => {
    getUserPassword().then(pass => {setPassword(pass);   });
    getChamberId().then(chamberId => {setChamberId(chamberId);
      fetchAppsUserAccessStatus(chamberId)
      //async fetch user data
     .then(res => {
       //console.log(res);
       setUserAccessStatus(res);
     })
     .catch(err => console.log(err));
    });
   // console.log("Test  "+chamber_id);

   

    LogBox.ignoreLogs(['VirtualizedLists should never be nested']);



  }, []);

  const fetchAppsUserAccessStatus  = async (chamberId) => {
    try {
      //let url = `https://reactnative.bdlawservice.com/public/api/appsUserAccessStatus?username=`+chamber_id;
      let url = `https://reactnative.bdlawservice.com/public/api/appsUserAccessStatus?username=105`;
      //console.log(url);
      let response = await axios.post(url).then(res => res.data);

      return response;
      //if (response.country) return response.country;
    } catch (err) {
      console.log('Error', err);
    }
  };


  
  const bdlawAppsPress = () => {

    if(userAccessStatus.data.status==1 && userAccessStatus.data.bdlawservice_allow==1)
    {
      // const url = 'app://bdlawApps';
      // Linking.openURL(url)
      //   .catch(err => {
      //     Linking.openURL('https://play.google.com/store/apps/details?id=com.sel.bdlawApps');
      //     //navigation.navigate("BdlawScreen")
      // })
      let  dd = btoa(chamber_id+"&pass="+password);
      var urls = "https://app.bdlawservice.com/?token="+dd;
     
         
      // let url='https://new.bdlawreference.com/Common/dokarindexlawbddibinak';
       var win= window.open(urls);
    }
    else
    {
      alert("Your Account is blocked, Please contact this phone number : 01771335577")
    }

  }

  const  judgeCourtPress = () => {

    if(userAccessStatus.data.status==1 && userAccessStatus.data.judgecourt_allow==1)
    {
      // const url = 'app://judgeCourt';
      // Linking.openURL(url)
      //   .catch(err => {
      //     Linking.openURL('https://play.google.com/store/apps/details?id=com.sel.JudgeCourt.com');
      // })
      let  dd = btoa(chamber_id+"&pass="+password);
      var urls = "https://app.bdjudgecourt.com/?token="+dd;
     
         
      // let url='https://new.bdlawreference.com/Common/dokarindexlawbddibinak';
       var win= window.open(urls);
    }
    else
    {
      alert("Your Account is blocked, Please contact this phone number : 01771335577")
    }

  }


  const onExpand = (types_id) => {
    //console.log('end reached');
   
   // console.log("Test  "+chamber_id);

  //console.log("======="+case_year);

  let dataToSend = { chamber_id: chamber_id};
  let formBody = [];
  for (let key in dataToSend) {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(dataToSend[key]);
    formBody.push(encodedKey + '=' + encodedValue);
  }
  formBody = formBody.join('&');


  console.log(formBody);     

    try {
      let url='';
      if( types_id === 2 )
      {
        setLoading2(true);
        setNullbody2(true);
         url = BASE_URL + "/DashboardDiary";
   
      }
      else if( types_id === 3)
      {
        setLoading3(true);
  setNullbody3(true);
        url = BASE_URL + "/DashboardDateover";       
   
      }
      else if( types_id === 0)
      {
        setLoading(true);
  setNullbody(true);
        url = BASE_URL + "/DashboardTresult";       
   
      }
      else if( types_id === 1)
      {
        setLoading1(true);
  setNullbody1(true);
        url = BASE_URL + "/DashboardPresult";       
   
      }
      else if( types_id === 4)
      {
        setLoading4(true);
  setNullbody4(true);
        url = BASE_URL + "/DashboardAppointment";       
   
      }
      else if( types_id === 5)
      {
        setLoading5(true);
  setNullbody5(true);
        url = BASE_URL + "/DashboardTask";       
   
      }
     // let url = BASE_URL + "/Dashboard";

       axios.post(url, formBody).then(response => {
           //Hide Loader
        
         // console.log(response.data[2]);               
         if( types_id === 2 )
         {
          setCaseDiary(response.data);
          setLoading2(false);
          setNullbody2(false);
         
      
         }
        
        else if( types_id === 3)
        {
          setDateOverCase(response.data);  
          setLoading3(false);
          setNullbody3(false);
            
     
        }
        else if( types_id === 0)
        {
          setTodayCase(response.data);  
          setLoading(false);
          setNullbody(false);
            
     
        }
         else if( types_id === 4)
        {
          setAppointment(response.data);   
          setLoading4(false);
          setNullbody4(false);
           
     
        }
        else if( types_id === 5)
        {
          setTask(response.data);     
          setLoading5(false);
          setNullbody5(false);
        }
        else if( types_id === 1)
        {
          setPreviousCase(response.data);
          setLoading1(false);
          setNullbody1(false);
              
     
        }
          
          setEndLoader(1);
          setFocusLength(true);  


       }).catch((error) => {
        //Hide Loader
        setLoading(false);
        console.error(error);
      });
      
     } 
     catch (err) 
     {
       console.error(err);
     }

  }

  // useFocusEffect(
  //   React.useCallback(() => {
  //     //async fetch user data
  //     getChamberId().then(chamberId => setChamber_id(chamberId));
  
  //   }, [])
  // );
  const EmptyListMessage = ({ item }) => {
    return (
      // Flat List Item
      <Text style={styles.emptyListStyle} >
        No Data Found
      </Text>
    );
  };
  const ListFooterComponent = (
    <View style={styles.listFooter}>
      <ActivityIndicator animating={true} size="large" color="#00ff00" />
    </View>
  )

  function Accordian({ children }) {
  
    const backgroundColor = '#fff';
    return (
      <View style={{ ...styles.accordian, backgroundColor }}>{children}</View>
    );
  }
  
  function AccordianHeader({ children }) {
    return <View style={styles.accordianHeader}>{children}</View>;
  }
  
  function AccordianContent({ children }) {
    return <View style={styles.accordianContent}>{children}</View>;
  }
  function Mainloader({ children }) {
    return  <SafeAreaView style={styles.main_loader}>
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center", marginTop:20 }}>
      <Image
          source={require('../loader/loop-loading.gif')}
          style={{
            width: '30%',
            height: 70,
            resizeMode: 'contain',
            margin: 0,
          }}
        />{children}
    </View>
  </SafeAreaView>;
  }


  return (
    
    <SafeAreaView style={{flex: 1}}>

      <ScrollView>
      <View style={{flex: 1, padding: 5}}>
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          
        </View>
      </View>
   
     <View style={styles.buttonMiddle}> 
     <View style={{ backgroundColor:'#1075c7' , borderRadius: 23, height:280, marginBottom:20}}>
        <TouchableOpacity
          style={styles.customBtnBGtop}
          onPress={()=>{bdlawAppsPress()}}
        ><Image style={styles.imgBannertop} source={ImagesAssets.logo1} />
          <Text style={styles.customBtnTexttop}>BD Law Service For {"\n"}Supreme Court Cause List Search</Text><Text style={[styles.customBtnTexttop,{textDecorationLine:'underline' }]}>Version-1</Text>
        </TouchableOpacity>
        </View>
     
        <View style={{flex: 1, width:'100%', marginBottom:3,  borderWidth: 1,
        borderColor:'black',}} />
        <View style={{flex: 1, width:'100%', marginBottom:20,  borderWidth: 1,
        borderColor:'black',}} />
     
        <TouchableOpacity
          style={[styles.customBtnBG,{backgroundColor:'#3342ce'}]}
         // onPress={judgeCourtPress}
          onPress={()=>{judgeCourtPress()}}
        ><Image style={styles.imgBanner} source={ImagesAssets.logo3} />
          <Text style={styles.customBtnText}>BD Law Service For{"\n"} Judge Court Case Search & Diary</Text><Text style={[styles.customBtnText,{textDecorationLine:'underline' }]}>Trial Version</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.customBtnBG,{backgroundColor:'#1a6656'}]}
          //onPress={bdlawAppsPress}
          onPress={ ()=>{ navigation.navigate("HomeScreen")}}
        ><Image style={styles.imgBanner} source={ImagesAssets.logo2} />
          <Text style={styles.customBtnText}>BD Law Reference (BLR){"\n"}For Judgment / Reference Search </Text><Text style={[styles.customBtnText,{textDecorationLine:'underline' }]}>Trial Version</Text>
        </TouchableOpacity>
        </View>
 
</ScrollView>

</SafeAreaView>
  );
};
 
const styles = StyleSheet.create({
imgBannertop:{
width:80,
height:80,
justifyContent:'center',
alignSelf:'center',
borderRadius:15 
  },
  imgBanner:{
    width:50,
    height:50,
    justifyContent:'center',
    alignSelf:'center'
      },
  buttonMiddle: {
    textAlign: 'center',
    verticalAlign:'middle',
    justifyContent:'center',
    alignItems:'center',
    marginTop:0,
    width:'100%',
    alignContent:'center'
  },
  customBtnTexttop: {
    fontSize: 20,
    fontWeight: 'bold',
    color: "#fff",
    textAlign: 'center',
},
  customBtnText: {
    fontSize: 18,
    fontWeight: '400',
    color: "#fff",
    textAlign: 'center',
},
customBtnBGtop: {
  //backgroundColor: "#1E6738",
  paddingHorizontal: 10,
  paddingVertical: 5,
  marginBottom:50,
  borderRadius: 10,
  justifyContent:'center',
  width:350,
  //flexDirection:'row',
  
  verticalAlign:'middle',
  alignSelf:'center'
  
  },
/* Here style the background of your button */
customBtnBG: {
//backgroundColor: "#3342ce",
paddingHorizontal: 10,
paddingVertical: 5,
marginBottom:20,
borderRadius: 10,
justifyContent:'center',
width:300,
shadowColor: 'rgba(0, 0, 0, 0.1)',
shadowOpacity: 0.8,
elevation: 6,
shadowRadius: 15 ,
shadowOffset : { width: 1, height: 13},
//flexDirection:'row',

verticalAlign:'middle',
alignSelf:'center'

},
  buttonTextStyle: {
    color: '#FFFFFF',
    // left: 20,
    paddingVertical: 5,
    fontSize: 16,
    textAlign: 'center',
    verticalAlign:'middle',
    marginTop:50,

  },

  devided: {
    marginTop:20,
    textAlign: 'center',
  },
  accordian: {
    borderColor: "#999",
    borderWidth: 1,
    borderBottomWidth: 1,
    paddingVertical:5,
    marginLeft:4,
    width:responsiveWidth(98),
    borderRadius:7,
  },
  accordianHeader: {
    paddingHorizontal: 20,
    paddingVertical: 5,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor:'#ccc',
    borderWidth: 1,
    borderRadius:5,
    marginHorizontal:5

  },
  accordianContent: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderColor: "#ccc",
    borderWidth: 0,
    borderTopWidth: 1,
    borderBottomWidth: 1,
   // width:responsiveWidth(88),
  },
  main_body: {
   // padding: 2,
   // borderWidth: 1,
   // borderColor: "black",
   // borderRadius: 6,
   // marginBottom:20,
  //  backgroundColor: "#b5e4ff",
    width:responsiveWidth(95),
  },
  hddata: {
    padding: 2,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 6,
    marginTop:5,
    marginRight:6,
    marginBottom:0,
    backgroundColor: "#C1EfFf",
  },
  TotalCaseFound:{
    fontSize: 14,
    textAlign:'center',
    fontWeight:'bold',
    width:responsiveWidth(100),
  },
  textTile:{
    fontSize: 14,
    alignItems: "center",
    justifyContent: "center",
    width:responsiveWidth(28),
  },
  textTilecln:{
    fontSize: 16,
    alignItems: "center",
    justifyContent: "center",
    width:8,
  },

  textDescription:{
    paddingTop:3,
    fontSize: 13,
    width:responsiveWidth(60),
  },
  main_loader:{
    width:responsiveWidth(100),
  },

  listFooter: {
    paddingVertical: 1,
    marginTop:2,
    marginBottom:30,

    
    // borderTopWidth: 1,
    // borderColor: "#CED0CE"
  },
  emptyListStyle: {
    padding: 10,
    fontSize: 18,
    textAlign: 'center',
  },
});

export default LcmsScreen;

async function getChamberId() {
  try {
    const jsonValue = await AsyncStorage.getItem("chamber_id")
    if(jsonValue !== null){
      //console.log("chamber_id: "+JSON.parse(jsonValue));
      return JSON.parse(jsonValue); 
    }
  } catch(err) {
    console.error(err);
  }
};

async function getUserPassword() {
  try {
    const jsonValue = await AsyncStorage.getItem("user_password")
    if(jsonValue !== null){
      //console.log("chamber_id: "+JSON.parse(jsonValue));
      return JSON.parse(jsonValue); 
    }
  } catch(err) {
    console.error(err);
  }
};