
import 'react-native-gesture-handler';
import AppLoading from 'expo-app-loading';
// Import React and Component
import React, { useCallback, useContext, useState, useEffect}  from 'react';
import { Redirect,Linking,Platform, Alert, Button, Text, TouchableOpacity, TextInput, View, StyleSheet,Image,AppState } from 'react-native';
import Navigation from "./Screen/Components/Navigator";
// Import Navigators from React Navigation
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import * as Font from "expo-font";
// Import Screens
import * as SplashScreen from 'expo-splash-screen';
import LoginScreen from './Screen/LoginScreen';
import HomeScreen from './Screen/DrawerScreens/HomeScreen';
import LcmsScreen from './Screen/DrawerScreens/LcmsScreen';
import RegisterScreen from './Screen/RegisterScreen';
import  DrawerNavigationRoutes from './Screen/DrawerNavigationRoutes';

import AuthContextProvider from "./Screen/contexts/AuthContext";

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import TabBar from 'react-native-custom-navigation-tabs';
import Icon from 'react-native-vector-icons/FontAwesome';
import { useFonts } from 'expo-font';
//import VersionCheck from 'react-native-version-check-expo';
// import NavigationDrawerHeader from './Screen/Components/NavigationDrawerHeader';
// import CustomSidebarMenu from './Screen/Components/CustomSidebarMenu';
// import TotalCaseScreen from './Screen/DrawerScreens/TotalCaseScreen';
SplashScreen.preventAutoHideAsync();

const Tab = createMaterialBottomTabNavigator();

// const getFonts = () =>
//   Font.loadAsync({
//     "open-sans-light": require("./assets/fonts/OpenSans-Light.ttf"),
//     "open-sans-light-italic": require("./assets/fonts/OpenSans-LightItalic.ttf"),
//     "open-sans-regular": require("./assets/fonts/OpenSans-Regular.ttf"),
//     "open-sans-semi-bold": require("./assets/fonts/OpenSans-SemiBold.ttf"),
//     "open-sans-semi-bold-italic": require("./assets/fonts/OpenSans-SemiBoldItalic.ttf"),
//     "open-sans-bold": require("./assets/fonts/OpenSans-Bold.ttf"),
//     "open-sans-bold-italic": require("./assets/fonts/OpenSans-BoldItalic.ttf"),
//   });
 

  

 
const Stack = createStackNavigator();
 
const Auth = () => {
  // Stack Navigator for Login and Sign up Screen
  return (
    <Stack.Navigator initialRouteName="LoginScreen">
      <Stack.Screen
        name="LoginScreen"
        component={LoginScreen}
        options={{headerShown: false}}  // Hide Header
        // options={{
        //   title: 'LCMSBD.COM', //Set Header Title
        //   headerTitleAlign: 'center',
        //   headerStyle: {
        //     backgroundColor: '#FFF', //Set Header color
        //   },
        //   headerTintColor: '#f00', //Set Header text color
        //   headerTitleStyle: {
        //     fontWeight: 'bold', //Set Header text style
        //   },
        // }}
      />
      <Stack.Screen
        name="RegisterScreen"
        component={RegisterScreen}
        options={{
          title: 'Register', //Set Header Title
          headerStyle: {
            backgroundColor: '#307ecc', //Set Header color
          },
          headerTintColor: '#fff', //Set Header text color
          headerTitleStyle: {
            fontWeight: 'bold', //Set Header text style
          },
        }}
      />
    </Stack.Navigator>
  );
};


 
const App = () => {
  //const [fontsLoaded, setFontsLoaded] = useState(false);
  const [fontsLoaded] = useFonts({
    "open-sans-light": require("./assets/fonts/OpenSans-Light.ttf"),
      "open-sans-light-italic": require("./assets/fonts/OpenSans-LightItalic.ttf"),
      "open-sans-regular": require("./assets/fonts/OpenSans-Regular.ttf"),
      "open-sans-semi-bold": require("./assets/fonts/OpenSans-SemiBold.ttf"),
      "open-sans-semi-bold-italic": require("./assets/fonts/OpenSans-SemiBoldItalic.ttf"),
      "open-sans-bold": require("./assets/fonts/OpenSans-Bold.ttf"),
      "open-sans-bold-italic": require("./assets/fonts/OpenSans-BoldItalic.ttf"),
  });
  
  useEffect(() => {
    async function prepare() {
      await SplashScreen.preventAutoHideAsync();
    }
    prepare();
   
      // if(Platform.OS === 'android' ){
      // VersionCheck.getLatestVersion({
      //   provider: 'playStore'  // for Android
      // });
     
    // checkUpdateNeeded();
    //}
    }, []);
    // const checkUpdateNeeded= async () => {
    //   try {
    //     let updateNeeded = await VersionCheck.needUpdate();
    //     //console.log('robeen');
    //     console.log(updateNeeded);
    //    // alert(updateNeeded);
    //     if (updateNeeded.isNeeded) {
    //       Linking.openURL(updateNeeded.storeUrl);
    //         // Alert.alert(
    //         //        'Please Update The New Version',
    //         //        'BD Law Service মোবাইল App টি আপডেট করে নিন।', 
    //         //        [{
    //         //            text: 'Update',
    //         //            onPress: ()=> {
    //         //             BackHandler.exitApp();
    //         //             Linking.openURL(updateNeeded.storeUrl);
    //         //            },
                       
    //         //        }, 
    //         //       ], 
    //         //       {
    //         //            cancelable: false
    //         //        }
    //         //     );
    //     }
    
    //   } catch (error) {
    //     return error;
    //   }
    // };  
  

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  
  }, [fontsLoaded]);
  if (!fontsLoaded) {
    return null;
  }
  // if (fontsLoaded) {
      return (
  <AuthContextProvider>  
    <NavigationContainer
     onReady={onLayoutRootView}
     >
    
      <Navigation />
      
    </NavigationContainer>
    </AuthContextProvider>
     );
    // } else {
    //   return (
    //     <AppLoading startAsync={getFonts} onFinish={() => setFontsLoaded(true)} onError={console.warn} />
    //   );
    // }

 
};
 
export default App;
